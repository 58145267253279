export default {
    data() {
        return {
            currentFile: null,
        };
    },
    watch: {
        'viewModel.uploadErrors': {
            deep: true,
            handler(errors) {
                if (!errors.length) {
                    return;
                }
                errors.forEach((error) => {
                    this.$emit('errorMessage', error);
                });
                this.removeFileFromDropzone();
            },
        },
    },
    methods: {
        async fileAdded(file) {
            this.currentFile = file;
            await this.viewModel.fileAdded(file);
        },
        removeAllCreatives() {
            this.viewModel.removeAllCreatives();
        },
        removeCreative(creative) {
            this.viewModel.removeCreative(creative);
            if (!this.viewModel.hasCreatives()) {
                this.removeFileFromDropzone();
            }
            this.$refs.form.validate();
        },
        removeFileFromDropzone() {
            this.$refs.uploadDropzone.$refs.uploadDropzone.$refs.vueDropzone.removeFile(this.currentFile);
        },
    },
};
