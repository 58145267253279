import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { ICampaign, ILineItem } from 'api-contracts';
import { API_URL, PROGRAMMATIC_CAMPAIGNS_API_URL } from '@/config';
import URLPathBuilder from '@/services/URLPathBuilder';

export default class ProgrammaticCampaignsService {
    private readonly creativeManagementService: RequestService;

    private readonly programmaticCampaignsService: RequestService;

    constructor(creativeManagementService: RequestService, programmaticCampaignsService: RequestService) {
        this.creativeManagementService = creativeManagementService;
        this.programmaticCampaignsService = programmaticCampaignsService;
    }

    async listCampaigns({ status, businessEntityId }): Promise<ICampaign[]> {
        const options: IRequestOptions = {
            domain: 'programmatic-campaigns',
            resource: 'campaign',
            action: ACTION_NAME.READ,
        };
        const urlPathBuilder = new URLPathBuilder('/campaigns');
        urlPathBuilder.setConditionalParam('status', () => status, Boolean(status));
        urlPathBuilder.setConditionalParam('business-entity-id', () => businessEntityId, Boolean(businessEntityId));
        return this.creativeManagementService.get(urlPathBuilder.build(), options);
    }

    async listLineItems({ status, campaignId }): Promise<ILineItem[]> {
        const options: IRequestOptions = {
            domain: 'programmatic-campaigns',
            resource: 'line-item',
            action: ACTION_NAME.READ,
        };
        const urlPathBuilder = new URLPathBuilder('/line-items');
        urlPathBuilder.setConditionalParam('status', () => status, Boolean(status));
        urlPathBuilder.setConditionalParam('campaignId', () => campaignId, Boolean(campaignId));
        return this.creativeManagementService.get(urlPathBuilder.build(), options);
    }

    async assignCreativesToLineItem({ lineItemId, creativeDspIds }) {
        const options: IRequestOptions = {
            domain: 'programmatic-campaigns',
            resource: 'line-item',
            action: ACTION_NAME.CREATE,
        };
        const urlPathBuilder = new URLPathBuilder('/line-items');
        urlPathBuilder.appendPath(`/${lineItemId}/creatives`);
        return this.programmaticCampaignsService.post(urlPathBuilder.build(), creativeDspIds, options);
    }
}

export const programmaticCampaignsService = new ProgrammaticCampaignsService(
    new RequestService({ baseUrl: API_URL }),
    new RequestService({ baseUrl: PROGRAMMATIC_CAMPAIGNS_API_URL }),
);
