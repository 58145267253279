<template>
    <div>
        <SrCheckbox v-model="applyExpiration" class="apply-expiration" label="Apply Expiration" />
        <div v-if="applyExpiration">
            <FutureDateTimeSelector
                v-model="runtime.startDate"
                class="start-date"
                :rules="startDateRules"
                :target-time-zone="runtime.timezone"
                default-time="00:00:00"
                label="Start"
            />
            <FutureDateTimeSelector
                v-model="runtime.endDate"
                class="end-date"
                :rules="endDateRules"
                :min="minEndDate"
                :target-time-zone="runtime.timezone"
                default-time="23:59:00"
                label="End"
            />
            <SrSelect v-model="runtime.timezone" :items="timezones" class="time-zone" label="Timezone" required searchable />
        </div>
    </div>
</template>

<script>
import { SrCheckbox, SrSelect } from '@ads/design-system';
import Vue from 'vue';
import { timezoneService } from '@/services/timezone/TimezoneService';
import { emptyRuntime } from '@/components/domain/creatives/types';
import FutureDateTimeSelector from '@/components/shared/FutureDateTimeSelector/FutureDateTimeSelector';
import { isRequired } from '@/shared/validation';
import { isDateBefore, areSecondsAndMillisecondsZero } from '@/shared/validation/date-time';

export default {
    name: 'CreativeRuntime',
    components: {
        SrCheckbox,
        SrSelect,
        FutureDateTimeSelector,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            timezones: [],
            timezoneService,
            hasRuntime: false,
            runtime: this.value,
        };
    },
    computed: {
        minEndDate() {
            if (!this.runtime.startDate) {
                return undefined;
            }
            return new Date(this.runtime.startDate);
        },
        applyExpiration: {
            get() {
                return Boolean(this.runtime.startDate) || this.hasRuntime;
            },
            set(value) {
                if (!value) {
                    Vue.set(this, 'runtime', { ...emptyRuntime });
                }
                this.hasRuntime = value;
            },
        },
        startDateRules() {
            return [
                isRequired(this.runtime.startDate, 'Start date'),
                isDateBefore(this.runtime.startDate, this.runtime.endDate, 'Start date should be before end date'),
                areSecondsAndMillisecondsZero(this.runtime.startDate, 'Start date should have seconds set to zero'),
            ];
        },
        endDateRules() {
            return [
                isRequired(this.runtime.endDate, 'End date'),
                isDateBefore(this.runtime.startDate, this.runtime.endDate, 'End date should be after start date'),
                areSecondsAndMillisecondsZero(this.runtime.endDate, 'End date should have seconds set to zero'),
            ];
        },
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                this.runtime = value;
            },
        },
        runtime: {
            deep: true,
            handler(value) {
                if (!value.startDate || !value.endDate || !value.timezone) {
                    this.$emit('input', null);
                    return;
                }
                this.$emit('input', value);
            },
        },
    },
    async created() {
        this.loading = true;
        this.timezones = await this.getTimezones();
        this.loading = false;
    },
    methods: {
        getTimezones() {
            return this.timezoneService.listTimezones();
        },
    },
};
</script>
