<template>
    <SrURLInput :value="modelValue" :rules="rules" label="VAST URL" required secure-url @input="emitInput"></SrURLInput>
</template>

<script>
import { SrURLInput } from '@ads/design-system';

export default {
    name: 'VastUrlInput',
    components: {
        SrURLInput,
    },
    model: {
        prop: 'modelValue',
        event: 'input',
    },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            rules: [(value) => value.length <= 2048 || 'Maximum allowed length is 2048 characters.'],
        };
    },
    methods: {
        emitInput(value) {
            this.$emit('input', value);
        },
    },
};
</script>
