<template>
    <div class="side-navigation" data-testid="SideNavigation__side-navigation">
        <SrNavigationBar :items="navItems" />
    </div>
</template>

<script>
import { SrNavigationBar } from '@ads/design-system';

export default {
    name: 'SideNavigation',
    components: { SrNavigationBar },
    data() {
        return {
            navItems: [
                { title: 'Image/Image with tracking', icon: 'image-icon', routeName: 'image-creative-overview' },
                { title: 'HTML', icon: 'code-2', routeName: 'html-creative-overview' },
                { title: 'HTML5', icon: 'code-2', routeName: 'html5-creative-overview' },
                { title: 'Audio', icon: 'play', routeName: 'audio-creative-overview' },
                { title: 'Video', icon: 'video-icon', routeName: 'video-creative-overview' },
                { title: 'Native', icon: 'native', routeName: 'native-creative-overview' },
            ],
        };
    },
};
</script>
