import IEmptyItemCreator from '@/components/shared/IEmptyItemCreator';
import { OptionalListItem } from '@/components/shared/OptionalListItem';

export default class OptionalListViewModel {
    private readonly emptyItemCreator: IEmptyItemCreator<OptionalListItem>;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private enabled = false;

    private items: OptionalListItem[] = [];

    constructor(emptyItemCreator: IEmptyItemCreator<OptionalListItem>) {
        this.emptyItemCreator = emptyItemCreator;
    }

    public getValue(): OptionalListItem[] | null {
        return this.enabled ? this.items : null;
    }

    public setValue(value: OptionalListItem[] | null): void {
        this.enabled = value != null;
        if (this.enabled) {
            this.items = this.createShallowCopyOf(value);
        } else {
            this.items = [];
        }
    }

    public isEnabled(): boolean {
        return this.enabled;
    }

    public setEnabled(enabled: boolean): void {
        this.enabled = enabled;
        if (this.enabled) {
            if (this.items.length === 0) {
                this.addEmptyItem();
            }
        } else {
            this.items = [];
        }
    }

    public getItems(): OptionalListItem[] {
        return this.items;
    }

    public removeItemAtIndex(index: number): void {
        const newItems = this.createShallowCopyOf(this.items);
        newItems.splice(index, 1);
        this.items = newItems;
        if (!this.items.length) {
            this.addEmptyItem();
        }
    }

    public updateItemAtIndex(item: OptionalListItem, index: number): void {
        const newItems = this.createShallowCopyOf(this.items);
        newItems.splice(index, 1, item);
        this.items = newItems;
    }

    public addEmptyItem(): void {
        const newItems = this.createShallowCopyOf(this.items);
        newItems.push(this.emptyItemCreator());
        this.items = newItems;
    }

    public removeItemsNotIn(values: OptionalListItem[]): void {
        if (!this.enabled) {
            return;
        }

        const filteredItems = this.getItemsIn(values);
        if (filteredItems.length === 0) {
            this.items = [this.emptyItemCreator()];
            return;
        }
        this.items = filteredItems;
    }

    private createShallowCopyOf(items: OptionalListItem[]): OptionalListItem[] {
        return items.slice();
    }

    private getItemsIn(values: OptionalListItem[]): OptionalListItem[] {
        return this.items.filter((item) => values.includes(item));
    }
}
