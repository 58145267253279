import { IAudioCreative } from 'api-contracts';

const getEmptyAudioCreativeData = (): IAudioCreative => ({
    id: null,
    dspId: null,
    name: '',
    advertiserUrl: '',
    customImpressionTrackers: null,
    businessEntity: { id: null },
    runtime: null,
    hasClickTracking: true,
    isServedAgainstGoogle: true,
    categories: [
        {
            id: 2,
            name: 'Card Games',
            code: 'IAB9-7',
            parent: {
                id: 1,
                name: 'Hobbies & Interests',
                code: 'IAB9',
            },
        },
    ],
    declarableAttributes: [
        {
            id: 1,
            name: 'CreativeType: Image/Rich Media',
        },
    ],
    createdAt: undefined,
    updatedAt: undefined,
    isVastUrlParsed: true,
    vastUrl: undefined,
    vastMimeTypes: [],
    vastBitrate: null,
    vastDuration: null,
    vastVersion: null,
    vastApiFrameworks: null,
    vastTrackingEventTypes: null,
});

export { getEmptyAudioCreativeData };
