import { Replacement } from '@/components/domain/creatives/html/string-replacement/Replacement';

export default class StringReplacementExecutor {
    public executeReplacements(value: string, replacements: Replacement[]): string {
        let stringValue = String(value);
        replacements.forEach((replacement) => {
            stringValue = stringValue.replaceAll(replacement.origin, replacement.replacement);
        });

        return stringValue;
    }
}
