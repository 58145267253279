<template>
    <Form
        v-model="form"
        :after-route="afterRoute"
        :component-error="componentError"
        :components="components"
        :is-component-loading.sync="isComponentLoading"
        :loading-progress="loadingProgress"
        :navigation-steps.sync="navigationSteps"
        :show-back-button="false"
        close-text="Upload & Close"
        continue-text="Upload & Assign"
        :close-callback="closeCallback"
        v-bind="$props"
        @close="close"
        @submit="submit"
    >
        <template #1>
            <HtmlCreativeBulkUpload
                v-show="form.step === 1"
                ref="bulk-creative-upload"
                v-model="creatives"
                :selected-business-entity.sync="businessEntity"
                :form.sync="form"
                :is-loading.sync="isComponentLoading.isCreativeUploadLoading"
                :is-valid.sync="navigationSteps[0].canContinue"
                @errorMessage="setComponentError"
            />
        </template>
        <template #2>
            <CreativeBulkAssign
                v-show="form.step === 2"
                ref="bulk-creative-assign"
                v-model="uploadedCreatives"
                :form.sync="form"
                :is-loading.sync="isComponentLoading.isCreativeAssignLoading"
                :is-valid.sync="navigationSteps[1].canContinue"
                :business-entity="businessEntity"
                :creative-service="creativeService"
                @close="close"
                @closeDone="closeDone"
                @errorMessage="setComponentError"
            />
        </template>
    </Form>
</template>

<script>
import { CREATIVE_SERVICE_DOMAIN } from '@/components/domain/creatives/types';
import Form from '@/components/domain/creatives/Form';
import HtmlCreativeBulkUpload from '@/components/domain/creatives/html/bulk/HtmlCreativeBulkUpload';
import { creativeService } from '@/services/CreativeService';
import BulkFormTemplate from '@/components/domain/creatives/shared/bulk/BulkFormTemplate';
import CreativeBulkAssign from '@/components/domain/creatives/shared/bulk/assignment/CreativeBulkAssign';

export default {
    name: 'BulkForm',
    components: {
        HtmlCreativeBulkUpload,
        CreativeBulkAssign,
        Form,
    },
    extends: BulkFormTemplate,
    data() {
        return {
            creativeService: creativeService(CREATIVE_SERVICE_DOMAIN.HTML),
            navigationSteps: [
                {
                    name: 'Html Creatives Upload',
                    canContinue: true,
                    canClose: true,
                    headline: 'Html Creatives Upload',
                    continue: this.uploadAndAssign,
                    close: this.uploadAndClose,
                },
                {
                    name: 'Html Creatives - Assign to Line Items',
                    canContinue: false,
                    headline: 'Html Creatives - Assign to Line Items',
                },
            ],
        };
    },
};
</script>
