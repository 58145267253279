import { INativeCreative } from 'api-contracts';

const getEmptyNativeCreativeData: () => INativeCreative = () => ({
    id: null,
    dspId: null,
    name: '',
    businessEntity: { id: null },
    runtime: null,
    hasClickTracking: true,
    isServedAgainstGoogle: true,
    categories: [
        {
            id: 2,
            name: 'Card Games',
            code: 'IAB9-7',
            parent: {
                id: 1,
                name: 'Hobbies & Interests',
                code: 'IAB9',
            },
        },
    ],
    declarableAttributes: [
        {
            id: 1,
            name: 'CreativeType: Image/Rich Media',
        },
    ],
    createdAt: undefined,
    updatedAt: undefined,
    clickUrl: '',
    advertiserUrl: '',
    impressionTrackingUrl: null,
    title: '',
    description: null,
    sponsorship: null,
    iconImage: null,
    mainImages: [],
});

export { getEmptyNativeCreativeData };
