<template>
    <VContainer class="pa-0 mb-6">
        <SrHeadline class="mb-6" level="3" weight="bold">Vast Tracker</SrHeadline>
        <SrTooltip top>
            <template #activator="{ on }">
                <div v-on="!hasBusinessEntity ? on : null">
                    <SrCheckbox
                        label="Track"
                        :input-value="isEnabled()"
                        :disabled="!hasBusinessEntity"
                        @change="onIsTrackingChange"
                    />
                </div>
            </template>
            <div class="brand-blue--text">Please select advertiser first to set up Vast tracking!</div>
        </SrTooltip>
        <VProgressLinear v-if="isLoading" indeterminate class="mt-2 mb-2" />
        <SrEditableList
            v-else
            v-slot="{ item, index }"
            :items="viewModel.getItems()"
            item-name="Vast Tracker"
            :disabled="!isEnabled()"
            :max-items="maxTrackersToAdd"
            @addItem="addTracker"
            @removeItem="removeTracker"
        >
            <VastEventTypeSelect
                :model-value="item"
                :vast-event-types="[item, ...selectableVastEventTypes]"
                :disabled="!isEnabled()"
                required
                @update:modelValue="updateTracker($event, index)"
            />
        </SrEditableList>
    </VContainer>
</template>

<script>
import OptionalListViewModel from '@/components/shared/OptionalListViewModel';
import VastEventTypeSelect from '@/components/domain/creatives/vast-tracking/VastEventTypeSelect';
import { vastEventTypeService } from '@/services/VastEventTypeService';
import { SrCheckbox, SrEditableList, SrHeadline, SrTooltip } from '@ads/design-system';

export default {
    name: 'VastEventTypeList',
    components: {
        SrHeadline,
        SrCheckbox,
        SrEditableList,
        SrTooltip,
        VastEventTypeSelect,
    },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },
    props: {
        modelValue: {
            type: Array,
            default: null,
        },
        businessEntity: {
            type: Object,
            required: false,
        },
    },
    data() {
        const viewModel = new OptionalListViewModel(() => null);
        viewModel.setValue(this.modelValue);
        return {
            viewModel,
            vastEventTypeService,
            vastEventTypes: [],
            isLoading: false,
        };
    },
    computed: {
        hasBusinessEntity() {
            return this.businessEntity?.id != null && this.businessEntity?.name != null;
        },
        selectableVastEventTypes() {
            const selectedVastEventTypes = this.viewModel.getItems();
            return this.getVastEventTypesNotIn(selectedVastEventTypes);
        },
        maxTrackersToAdd() {
            return this.vastEventTypes.length;
        },
    },
    watch: {
        modelValue: {
            handler(value) {
                this.viewModel.setValue(value);
            },
        },
        businessEntity: {
            async handler() {
                await this.fetchVastEventTypes();
                this.viewModel.removeItemsNotIn(this.vastEventTypes);
            },
            deep: true,
        },
    },
    created() {
        this.fetchVastEventTypes();
    },
    methods: {
        isEnabled() {
            return this.viewModel.isEnabled();
        },
        onIsTrackingChange(isTracking) {
            this.viewModel.setEnabled(isTracking);
            this.emitModelValue();
        },
        addTracker() {
            this.viewModel.addEmptyItem();
            this.emitModelValue();
        },
        removeTracker(index) {
            this.viewModel.removeItemAtIndex(index);
            this.emitModelValue();
        },
        updateTracker(value, index) {
            this.viewModel.updateItemAtIndex(value, index);
            this.emitModelValue();
        },
        emitModelValue() {
            const value = this.viewModel.getValue();
            this.$emit('update:modelValue', value);
        },
        emitErrorMessage(message) {
            this.$emit('errorMessage', message);
        },
        async fetchVastEventTypes() {
            if (!this.hasBusinessEntity) {
                return;
            }

            try {
                this.isLoading = true;
                this.vastEventTypes = await this.vastEventTypeService.listForBusinessEntity(this.businessEntity.id);
            } catch (e) {
                this.emitErrorMessage(`Could not get Vast tracker event types for advertiser ${this.businessEntity.name}.`);
            } finally {
                this.isLoading = false;
            }
        },
        getVastEventTypesNotIn(values) {
            return this.vastEventTypes.filter((vastEventType) => !values.includes(vastEventType));
        },
    },
};
</script>
