// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAYAAACddGYaAAAAGUlEQVQI12MwuCXy3+CWyH8GBgYGJgYkAABZbAQ9ELXurwAAAABJRU5ErkJggg==", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAYAAACddGYaAAAAGUlEQVQI12M4Kyb2/6yY2H8GBgYGJgYkAABURgPz6Ks7wQAAAABJRU5ErkJggg==", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".creative-management-mf .CodeMirror-merge-r-chunk-end{border-bottom:1px solid #7c99d9}.creative-management-mf .CodeMirror-merge-r-chunk-start{border-top:1px solid #7c99d9}.creative-management-mf .CodeMirror-merge-r-deleted{background-color:#aeffac;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.creative-management-mf .CodeMirror-merge-r-inserted{background-color:#ffacac;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.creative-management-mf .CodeMirror-merge-r-chunk{background:#e1effc}.creative-management-mf .merge-view-container{margin:0!important}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
