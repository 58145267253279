<template>
    <div>
        <SrHeadline class="mb-6" level="3" weight="bold"> Skippable </SrHeadline>
        <SrCheckbox v-model="isSkippable" label="Creative can be skipped" />
    </div>
</template>

<script>
import { SrCheckbox, SrHeadline } from '@ads/design-system';

export default {
    name: 'VideoSkippable',
    components: {
        SrHeadline,
        SrCheckbox,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isSkippable: this.value,
        };
    },
    watch: {
        value(value) {
            this.isSkippable = value;
        },
        isSkippable(value) {
            this.$emit('input', value);
        },
    },
};
</script>

<style scoped></style>
