<template>
    <VAlert v-if="show" dense outlined type="error">
        {{ message }}
    </VAlert>
</template>
<script>
export default {
    name: 'ErrorBox',
    props: {
        message: {
            type: String,
            default: null,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
