<template>
    <div>
        <VRow v-observe-visibility="visibilityChanged" class="merge-view-container">
            <VCol>
                <SrHeadline class="mb-6 mt-4" level="3" weight="bold"> Insert HTML Code{{ readonly }} </SrHeadline>
            </VCol>
            <VCol class="text-right">
                <SrHeadline class="mb-6 mt-4" level="3" weight="bold"> Generated HTML Code </SrHeadline>
                <span>
                    Replacements:
                    <v-chip class="replacement-count mb-2" color="primary" dark>
                        {{ count }}
                    </v-chip>
                </span>
            </VCol>
        </VRow>
        <VInput v-model="template" :rules="rules.template" class="hidden-input" type="hidden" />
        <div v-if="Boolean(errorMessage)" class="merge-view-error-message">
            {{ errorMessage }}
        </div>
        <codemirror
            v-if="options"
            ref="codemirror"
            v-model="template"
            :merge="true"
            :options="cmOptions"
            :class="codemirrorClass"
        />
    </div>
</template>

<script>
import { codemirror } from 'vue-codemirror';
import DiffMatchPatch from 'diff-match-patch';
import 'dedent';
import 'codemirror/mode/css/css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/addon/merge/merge';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/merge/merge.css';
import { SrHeadline } from '@ads/design-system';
import StringReplacementCounter from '@/components/domain/creatives/html/string-replacement/StringReplacementCounter';
import StringReplacementExecutor from '@/components/domain/creatives/html/string-replacement/StringReplacementExecutor';
import './mergeView.scss';
import mergeViewRules from '@/components/domain/creatives/html/HtmlCodeDiff/mergeViewRules';

window.diff_match_patch = DiffMatchPatch;
window.DIFF_DELETE = -1;
window.DIFF_INSERT = 1;
window.DIFF_EQUAL = 0;

export default {
    name: 'MergeView',
    components: {
        codemirror,
        SrHeadline,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        replacements: {
            type: Array,
            required: true,
            default: () => [],
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        rules: {
            type: Object,
            default: () => mergeViewRules,
        },
    },
    data() {
        return {
            touched: false,
            template: this.value,
            cmOptions: {
                value: '',
                origRight: '',
                connect: 'align',
                revertButtons: false,
                collapseIdentical: false,
                mode: 'htmlmixed',
                lineWrapping: true,
                lineNumbers: true,
                highlightDifferences: true,
                ...this.options,
            },
            replacementCounter: new StringReplacementCounter(),
            replacementExecutor: new StringReplacementExecutor(),
        };
    },
    computed: {
        count() {
            return this.replacementCounter.count(this.value, this.replacements);
        },
        readonly() {
            if (this.cmOptions.readOnly) {
                return ' (readonly)';
            }
            return '';
        },
        errorMessage() {
            const hasNoValidationRules = !Array.isArray(this.rules?.template);
            const isNotTouched = !this.touched;
            if (hasNoValidationRules || isNotTouched) {
                return '';
            }
            const validationErrors = this.rules.template.map((rule) => rule(this.template)).filter((isValid) => isValid !== true);
            return validationErrors[0] || '';
        },
        codemirrorClass() {
            return this.errorMessage ? 'merge-view-red-border' : '';
        },
    },
    watch: {
        template(value) {
            this.$emit('input', value);
            this.updateDifference();
            this.touched = true;
        },
        replacements: {
            deep: true,
            handler() {
                this.updateDifference();
            },
        },
        options(options) {
            this.cmOptions.value = options.value;
            this.cmOptions.origRight = options.origRight;
        },
    },
    methods: {
        visibilityChanged(visible) {
            if (visible) {
                this.$refs.codemirror.codemirror.editor().refresh();
                this.$refs.codemirror.codemirror.rightOriginal().refresh();
            }
        },
        updateDifference() {
            this.$refs.codemirror.codemirror
                .rightOriginal()
                .setValue(this.replacementExecutor.executeReplacements(this.template, this.replacements));
        },
    },
};
</script>

<style lang="scss" scoped>
.merge-view-error-message {
    color: #ff0000;
    margin: 0 0 15px 0;
    display: flex;
}

.merge-view-red-border {
    border: 1px solid #ff0000;
    padding-bottom: 1px;
}
</style>
