import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { ICategory, IDeclarableAttribute } from 'api-contracts';
import { API_URL } from '@/config';
import URLPathBuilder from '@/services/URLPathBuilder';

export default class CategorizationService {
    private readonly requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async listCategories(): Promise<[ICategory]> {
        const options: IRequestOptions = {
            domain: 'creative-management',
            resource: 'creative',
            action: ACTION_NAME.READ,
        };
        const urlPathBuilder = new URLPathBuilder('/categories');
        return this.requestService.get(urlPathBuilder.build(), options);
    }

    async listDeclarableAttributes(): Promise<[IDeclarableAttribute]> {
        const options: IRequestOptions = {
            domain: 'creative-management',
            resource: 'creative',
            action: ACTION_NAME.READ,
        };
        const urlPathBuilder = new URLPathBuilder('/declarable-attributes');
        return this.requestService.get(urlPathBuilder.build(), options);
    }
}

export const categorizationService = new CategorizationService(new RequestService({ baseUrl: API_URL }));
