<script>
import FormTemplate from '@/components/domain/creatives/FormTemplate';
import { dynamicCreativeErrorRuleFactory } from '@/components/domain/creatives/shared/DynamicCreativeErrorRuleFactory';

export default {
    name: 'BulkFormTemplate',
    extends: FormTemplate,
    data() {
        return {
            closeCallback: async () => {
                this.$emit('close');
                await this.$router.push({ name: this.afterRoute });
            },
            creatives: [],
            uploadedCreatives: [],
            businessEntity: {},
            errorRuleFactory: dynamicCreativeErrorRuleFactory,
            batchProgress: null,
            isComponentLoading: {
                isCreativeUploadLoading: false,
                isCreativeAssignLoading: false,
            },
        };
    },
    computed: {
        components() {
            if (!this.isMounted) {
                return [];
            }
            return [this.$refs['bulk-creative-upload'], this.$refs['bulk-creative-assign']];
        },
        creativesToUpload() {
            return this.creatives.filter((creative) => {
                const isCreativeUploaded = this.uploadedCreatives.some(
                    (uploadedCreative) => uploadedCreative.name === creative.name,
                );
                return !isCreativeUploaded;
            });
        },
        loadingProgress() {
            if (!this.batchProgress) {
                return null;
            }
            return this.batchProgress.current / this.batchProgress.total;
        },
    },
    methods: {
        async uploadAndAssign() {
            return this.upload();
        },
        async uploadAndClose() {
            const result = await this.upload();

            this.$emit('close');
            await this.$router.push({ name: this.afterRoute });

            return result;
        },
        async upload() {
            const bulkRequest = await this.request('bulkCreate', this.creativesToUpload);
            bulkRequest.onProgressUpdate((progress) => {
                this.batchProgress = progress;
            });
            let uploadedCreatives = [];
            try {
                uploadedCreatives = await bulkRequest.allDone();
                this.addUploadedCreatives(uploadedCreatives);
            } catch (error) {
                this.setComponentRequestErrorMessage(error);
                throw error;
            } finally {
                this.batchProgress = null;
            }

            if (this.creativesToUpload.length > 0) {
                const creativeNames = this.creativesToUpload.map((creative) => creative.name);
                const errorMessage = `Creatives \n${creativeNames.join('\n')}\n failed to upload. Please try again`;
                this.setComponentError(errorMessage);
                throw new Error(errorMessage);
            }
        },
        addUploadedCreatives(creatives) {
            const uploadedCreativeNames = this.uploadedCreatives.map((creative) => creative.name);
            creatives.forEach((creative) => {
                if (!uploadedCreativeNames.includes(creative.name)) {
                    this.uploadedCreatives.push(creative);
                }
            });
        },
        async submit(done = () => null) {
            this.setComponentError(null);
            await this.$refs['bulk-creative-assign'].triggerSubmit(done);
        },
        async close() {
            if (this.form.step === 2) {
                this.$refs['bulk-creative-assign'].triggerNoAssignmentModal(this.closeCallback);
                return;
            }
            await this.closeCallback();
        },
        async closeDone() {
            await this.closeCallback();
        },
    },
};
</script>
