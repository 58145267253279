export default abstract class ErrorRuleFactory {
    protected abstract mappings;

    create(e, customData: unknown) {
        const errorRules = {};
        let error;
        try {
            error = JSON.parse(e.response.message);
        } catch (err) {
            // ignore
        }
        if (error) {
            Object.keys(error).forEach((errorKey) => {
                const mappedKey = this.mappings[errorKey] || errorKey;
                if (this[mappedKey]) {
                    errorRules[mappedKey] = this[mappedKey](error[errorKey], customData);
                }
            });
        }
        return errorRules;
    }
}
