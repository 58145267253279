<template>
    <SrSelect
        :value="value"
        :items="mimeTypes"
        :loading="loading"
        :rules="rules"
        item-text="name"
        item-value="mimeType"
        multiple
        return-object
        label="Mime Types"
        searchable
        required
        @change="onValueChange"
    />
</template>

<script>
import { SrSelect } from '@ads/design-system';

export default {
    name: 'VastMimeTypeSelect',
    components: {
        SrSelect,
    },
    props: {
        value: {
            type: Array,
            required: false,
        },
        mimeTypes: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            required: false,
        },
    },
    computed: {
        rules() {
            if (this.errorMessage == null) {
                return [];
            }
            return [this.errorMessage];
        },
    },
    methods: {
        onValueChange(newValue) {
            this.$emit('input', newValue);
        },
    },
};
</script>
