import { IDimensions } from 'api-contracts';

export default class DimensionsTypeFactory {
    public static isValidDimensions(dimensionsString: string): boolean {
        return /^[1-9][0-9]*x[1-9][0-9]*$/.test(dimensionsString);
    }

    public static toString(dimensions: IDimensions): string {
        if (!dimensions || isNaN(dimensions.width) || isNaN(dimensions.height)) {
            return '';
        }
        return `${dimensions.width}x${dimensions.height}`;
    }

    public static fromString(dimensionString: string): IDimensions | null {
        if (!dimensionString) {
            return null;
        }

        if (!DimensionsTypeFactory.isValidDimensions(dimensionString)) {
            return null;
        }

        const dimensionParts = dimensionString.split('x');

        return {
            width: Number(dimensionParts[0]),
            height: Number(dimensionParts[1]),
        };
    }
}
