import ImageCreativeOverview from '@/components/domain/creatives/image/Overview.vue';
import ImageCreativeCreate from '@/components/domain/creatives/image/Create.vue';
import ImageCreativeEdit from '@/components/domain/creatives/image/Edit.vue';
import ImageCreativeBulkForm from '@/components/domain/creatives/image/bulk/BulkForm.vue';

import { ACTION_TYPES } from '@/components/domain/creatives/types';

export default [
    {
        name: 'image-creative-overview',
        path: '/image-creative',
        component: ImageCreativeOverview,
    },
    {
        name: 'image-creative-create',
        path: '/image-creative/create',
        component: ImageCreativeCreate,
    },
    {
        name: 'image-creative-bulk-create',
        path: '/image-creative/bulk-create',
        component: ImageCreativeBulkForm,
        props: () => ({
            afterRoute: 'image-creative-overview',
            submitAction: ACTION_TYPES.CREATE,
        }),
    },
    {
        name: 'image-creative-edit',
        path: '/image-creative/:id',
        component: ImageCreativeEdit,
        props: (route) => ({
            id: route.params.id,
        }),
    },
];
