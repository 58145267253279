import { ACTION_NAME } from '@ads/iam-library';
import { API_URL } from '@/config';
import { convertMBToMiB } from '@/utils/conversions';

// For full options refer to https://github.com/dropzone/dropzone/blob/main/src/options.js
type DropzoneOptions = {
    url: string;
    method: 'post';
    headers: {
        Accept: 'multipart/form-data';
        'Cache-Control': null;
        'X-Requested-With': null;
        Authorization: string;
    };
    createImageThumbnails: boolean;
    maxFiles: number;
    disablePreviews: boolean;
    addRemoveLinks: boolean;
    parallelUploads: number;
    acceptedFiles: string;
    uploadMultiple: boolean;
    autoQueue: boolean;
    autoProcessQueue: boolean;
    maxFilesize?: number;
};

export default class UploadOptions {
    static createDropzoneOptions(
        path: string,
        options: {
            acceptedFiles: string;
        } & Partial<DropzoneOptions>,
    ): DropzoneOptions {
        const maxFileSizeInMB = 0.256;
        const maxFileSizeInMiB = convertMBToMiB(maxFileSizeInMB);
        return {
            url: `${API_URL}${path}`,
            method: 'post',
            headers: {
                Accept: 'multipart/form-data',
                'Cache-Control': null,
                'X-Requested-With': null,
                Authorization: `Bearer ${localStorage.getItem('token')} creative-management:creative:${ACTION_NAME.CREATE}`,
            },
            createImageThumbnails: true,
            maxFiles: 1,
            maxFilesize: maxFileSizeInMiB,
            disablePreviews: false,
            addRemoveLinks: true,
            parallelUploads: 1,
            autoQueue: true,
            autoProcessQueue: true,
            uploadMultiple: false,
            ...options,
        };
    }

    static createDropzoneOptionsForSingleImage(): DropzoneOptions {
        const path = '/upload-image';
        const options = {
            acceptedFiles: 'image/gif,image/jpeg,image/png',
        };
        return UploadOptions.createDropzoneOptions(path, options);
    }

    static createDropzoneOptionsForMultipleImages(): DropzoneOptions {
        const path = '/bulk-upload-image';
        const options = {
            acceptedFiles: 'image/gif,image/jpeg,image/png',
            maxFiles: 50,
            parallelUploads: 10,
            uploadMultiple: true,
        };

        return UploadOptions.createDropzoneOptions(path, options);
    }

    static createDropzoneOptionsForSingleDOOHImage(): DropzoneOptions {
        const path = '/bulk-upload-image';
        const maxFileSizeInMB = 4.5;
        const maxFileSizeInMiB = convertMBToMiB(maxFileSizeInMB);
        const options = {
            acceptedFiles: 'image/gif,image/jpeg,image/png',
            maxFilesize: maxFileSizeInMiB,
        };

        return UploadOptions.createDropzoneOptions(path, options);
    }

    static createDropzoneOptionsForMultipleDOOHImages(): DropzoneOptions {
        const path = '/bulk-upload-image';
        const maxFileSizeInMB = 4.5;
        const maxFileSizeInMiB = convertMBToMiB(maxFileSizeInMB);
        const options = {
            acceptedFiles: 'image/gif,image/jpeg,image/png',
            maxFilesize: maxFileSizeInMiB,
            maxFiles: 50,
            uploadMultiple: true,
        };

        return UploadOptions.createDropzoneOptions(path, options);
    }

    static createDropzoneOptionsForMainImageAssets(): DropzoneOptions {
        return UploadOptions.createDropzoneOptionsForMultipleImages();
    }

    static createDropzoneOptionsForIconAsset(): DropzoneOptions {
        const path = '/bulk-upload-image';
        const options = {
            acceptedFiles: 'image/gif,image/jpeg,image/png',
            uploadMultiple: true,
        };

        return UploadOptions.createDropzoneOptions(path, options);
    }

    static createDropzoneOptionsForSingleHtml5(): DropzoneOptions {
        const path = '/upload-html5';
        const options = {
            acceptedFiles: 'application/zip, application/x-zip-compressed, application/zip-compressed',
        };
        return UploadOptions.createDropzoneOptions(path, options);
    }

    static createDropzoneOptionsForMultipleHtml5(): DropzoneOptions {
        const path = '/bulk-upload-html5';
        const options = {
            acceptedFiles: 'application/zip, application/x-zip-compressed, application/zip-compressed',
            maxFiles: 50,
            parallelUploads: 10,
            uploadMultiple: true,
        };

        return UploadOptions.createDropzoneOptions(path, options);
    }

    static createDropzoneOptionsForSingleDOOHHtml5(): DropzoneOptions {
        const path = '/bulk-upload-html5';
        const maxFileSizeInMB = 4.5;
        const maxFileSizeInMiB = convertMBToMiB(maxFileSizeInMB);
        const options = {
            acceptedFiles: 'application/zip, application/x-zip-compressed, application/zip-compressed',
            maxFilesize: maxFileSizeInMiB,
        };

        return UploadOptions.createDropzoneOptions(path, options);
    }

    static createDropzoneOptionsForMultipleDOOHHtml5s(): DropzoneOptions {
        const path = '/bulk-upload-html5';
        const maxFileSizeInMB = 4.5;
        const maxFileSizeInMiB = convertMBToMiB(maxFileSizeInMB);
        const options = {
            acceptedFiles: 'application/zip, application/x-zip-compressed, application/zip-compressed',
            maxFilesize: maxFileSizeInMiB,
            maxFiles: 50,
            uploadMultiple: true,
        };

        return UploadOptions.createDropzoneOptions(path, options);
    }
}
