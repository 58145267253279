import CreativeService from '@/services/CreativeService';

export default class PreviewService {
    private readonly creativeService: CreativeService;

    constructor(creativeService: CreativeService) {
        this.creativeService = creativeService;
    }

    async openPreviewWindowForCreativeById(dspId: number): Promise<void> {
        let content: string;
        try {
            content = await this.creativeService.getPreview(dspId);
        } catch (e) {
            console.error(e);
            content = 'This Creative can not be displayed!';
        }

        const preview = window.open('about:blank', '_blank');
        // While the usage of document.write is discouraged: https://developer.mozilla.org/en-US/docs/Web/API/Document/write
        // otherwise some creative previews will not work properly.
        // the previous method of creating a blob and using URL.createObjectURL(blob) to open the preview broke some forms of creatives from adform.
        // the precise reason is unknown, but if you want to change how previews are displayed test them against creatives listed in the ticket of this change.
        const document = preview.document;
        document.open();
        document.write(content);
        document.close();
    }
}
