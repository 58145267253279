<template>
    <VForm ref="form" v-model="formIsValid" :disabled="form.disabled" class="bulk-upload">
        <VRow>
            <VCol lg="5" md="6" sm="12" xlg="4">
                <VInput
                    v-model="viewModel.creatives"
                    :rules="viewModel.getInputRules().creatives"
                    class="hidden-input"
                    type="hidden"
                />
                <VInput
                    :value="viewModel.hasDuplicatedCreativeNames()"
                    :rules="viewModel.getInputRules().duplicateName"
                    class="hidden-input"
                    type="hidden"
                />
                <SrHeadline class="mb-6" level="3" weight="bold"> Select Advertiser </SrHeadline>
                <SrSelect
                    v-model="viewModel.businessEntity"
                    :items="businessEntities"
                    :rules="viewModel.getInputRules().businessEntity"
                    item-text="name"
                    item-value="id"
                    label="Advertiser"
                    required
                    return-object
                    searchable
                    sort-order="ASC"
                />
            </VCol>
            <VCol lg="5" md="6" sm="12" xlg="4">
                <SrURLInput
                    v-model="viewModel.advertiserUrl"
                    :rules="viewModel.getInputRules().url"
                    class="advertiser-url mt-11"
                    label="Advertiser URL"
                    maxlength="2048"
                    secure-url
                    required
                />
            </VCol>
        </VRow>
        <VRow>
            <VCol lg="5" md="6" sm="12" xlg="4">
                <ClickUrl v-model="viewModel.clickUrl" />
            </VCol>
            <VCol lg="5" md="6" sm="12" xlg="4">
                <SrCheckbox v-model="viewModel.shouldDisableTemplateNaming" label="Disable Template Naming" />
            </VCol>
        </VRow>
        <VRow v-if="viewModel.hasInitialProperties()">
            <VCol>
                <BulkCreativeDropzone
                    ref="uploadDropzone"
                    v-model="viewModel.creatives"
                    :business-entity="viewModel.businessEntity"
                    :is-loading.sync="isDropzoneLoading"
                    :dropzone-options="dropzoneOptions"
                    :name-template-options="viewModel.getTemplateNamingOptions()"
                    @errorMessage="errorMessage"
                    @uploadedFile="uploadedFile"
                />
            </VCol>
        </VRow>
        <div class="dooh-note">
            <SrCheckbox v-model="viewModel.isDooh" label="Enable DOOH creatives" data-test-id="is-dooh"></SrCheckbox>
            <SrInfoBox class="ml-2">
                <div style="max-width: 250px">
                    Allows uploading files up to 4.5MB in size. Ideal for Digital Out-of-Home (DOOH) advertising, but not advised
                    for standard display ads due to size restrictions by ad networks.
                </div>
            </SrInfoBox>
        </div>
        <VRow v-if="viewModel.hasInitialProperties()">
            <VCol>
                <Html5BulkCreativeTable
                    v-model="viewModel.creatives"
                    :headers="viewModel.getTableHeaders()"
                    :advertiser-name="viewModel.businessEntity.name"
                    :disable-template-naming="viewModel.shouldDisableTemplateNaming"
                    :is-loading="isDropzoneLoading"
                    @deleteItem="removeCreative"
                    @editItem="editItem"
                    @errorMessage="errorMessage"
                />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrCheckbox, SrHeadline, SrInfoBox, SrSelect, SrURLInput } from '@ads/design-system';
import { format } from 'date-fns';
import UploadOptions from '@/services/upload/UploadOptions';
import BulkCreativeDropzone from '@/components/domain/creatives/shared/bulk/bulk-creative-dropzone/BulkCreativeDropzone';
import Html5BulkCreativeTable from '@/components/domain/creatives/html5/bulk/Html5BulkCreativeTable';
import formatCreativeName from '@/components/domain/creatives/shared/bulk/formatCreativeName';
import Html5CreativeBulkUploadViewModel from '@/components/domain/creatives/html5/bulk/Html5CreativeBulkUploadViewModel';
import CreativeBulkUploadMixin from '@/components/domain/creatives/shared/bulk/CreativeBulkUploadMixin';
import ClickUrl from '@/components/domain/creatives/shared/basics/ClickUrl';

export default {
    name: 'Html5CreativeBulkUpload',
    components: {
        SrInfoBox,
        ClickUrl,
        Html5BulkCreativeTable,
        SrHeadline,
        SrSelect,
        SrURLInput,
        BulkCreativeDropzone,
        SrCheckbox,
    },
    mixins: [CreativeBulkUploadMixin],
    data() {
        return {
            viewModel: new Html5CreativeBulkUploadViewModel(),
            dropzoneOptionsHtml5: UploadOptions.createDropzoneOptionsForMultipleHtml5(),
            dropzoneOptionsDOOHHtml5: UploadOptions.createDropzoneOptionsForMultipleDOOHHtml5s(),
            isDropzoneLoading: false,
        };
    },
    computed: {
        dropzoneOptions() {
            return this.viewModel.isDooh ? this.dropzoneOptionsDOOHHtml5 : this.dropzoneOptionsHtml5;
        },
    },
    watch: {
        'viewModel.isDooh': function () {
            this.$refs.uploadDropzone.updateOptions(this.dropzoneOptions);
        },
    },
    methods: {
        uploadedFile(creative, fileResponse) {
            const creativeSize = creative.originName.toString().match(/\d+x\d+/g);
            if (creativeSize) {
                this.updateDimensions(creative, creativeSize[0]);
            }
            creative.advertiserUrl = this.viewModel.advertiserUrl;
            creative.clickUrl = this.viewModel.clickUrl;
            creative.zipFileUrl = fileResponse.zipFileUrl;
            creative.isDooh = this.viewModel.isDooh ?? false;
        },
        updateDimensions(creative, creativeSize) {
            const [width, height] = creativeSize.split('x');
            creative.dimensions = { width: Number(width), height: Number(height) };
            creative.name = formatCreativeName(
                {
                    filename: creative.originName,
                    advertiserName: this.viewModel.businessEntity.name,
                    date: format(new Date(), 'yyMMdd'),
                    dimensions: creative.dimensions,
                },
                '${date}_${advertiserName}_${filename}_${dimensions.width}x${dimensions.height}',
                this.viewModel.shouldDisableTemplateNaming,
            );
        },
        removeCreative(creative) {
            this.viewModel.removeCreative(creative);
            this.removeFileFromDropzone(creative);
            this.$refs.form.validate();
        },
        removeFileFromDropzone(creative) {
            this.$refs.uploadDropzone.$refs.uploadDropzone.removeFile(creative);
        },
    },
};
</script>

<style lang="scss">
.bulk-upload {
    .dz-details {
        text-align: center !important;
    }

    .dz-filename {
        display: block !important;
        color: #3367d6 !important;
    }
}
</style>
