// prevents injecting css from vuetify-loader
module.exports = {
    match(originalTag, { kebabTag, camelTag }) {
        if (kebabTag.startsWith('v-')) {
            return [camelTag, `import ${camelTag} from 'vuetify/es5/components/${camelTag}/${camelTag}.js'`];
        }
        return [];
    },
    transforms: {
        async import({ tag }) {
            if (tag === 'vuetify') {
                return [`import 'vuetify/dist/vuetify.min.css'`, `import Vuetify from 'vuetify/es5/framework'`];
            }
            return [];
        },
    },
};
