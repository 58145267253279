<template>
    <VRow no-gutters>
        <VCol cols="6">
            <SrHeadline weight="bold" data-testid="ListHeader__headline">
                {{ headlineName }}
            </SrHeadline>
        </VCol>

        <VCol cols="6" class="d-flex justify-end actions">
            <slot name="actions" />
        </VCol>

        <VCol cols="12">
            <slot name="filter" />
        </VCol>

        <VCol cols="4" class="pr-2">
            <slot name="quicksearch" />
        </VCol>
    </VRow>
</template>

<script>
import { SrHeadline } from '@ads/design-system';

export default {
    components: { SrHeadline },
    props: {
        headlineName: {
            type: String,
            required: true,
        },
    },
};
</script>
