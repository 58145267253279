<template>
    <VForm ref="form" v-model="formIsValid" :disabled="form.disabled">
        <VRow>
            <VCol xlg="4" lg="5" md="6" sm="12">
                <SrHeadline level="3" weight="bold" class="mb-6"> URL Assets </SrHeadline>
                <ClickUrl v-model="formData.clickUrl" />
                <SrURLInput
                    v-model="formData.advertiserUrl"
                    :rules="rules.url"
                    class="advertiser-url"
                    label="Advertiser URL"
                    maxlength="2048"
                    secure-url
                    required
                />
                <SrURLInput
                    ref="secureUrl"
                    v-model="formData.secureUrl"
                    :rules="rules.secureUrl"
                    class="secure-url"
                    :loading="loading"
                    :disabled="loading"
                    label="Secure Image URL"
                    maxlength="2048"
                    secure-url
                    required
                />
                <VTextarea
                    v-model="formData.impressionTrackerHtml"
                    outlined
                    class="impression-tracker-html"
                    label="Impression Tracker Secure"
                />
            </VCol>
            <VCol xlg="4" lg="5" md="6" sm="12" offset-lg="1">
                <SrHeadline level="3" weight="bold" class="mb-6"> Upload File </SrHeadline>
                <Dimensions v-model="formData.dimensions" :rules="imageDimensionsRules" disabled />
                <SrDropzoneUpload
                    id="image-upload-dropzone"
                    ref="uploadDropzone"
                    :dropzone-options="dropzoneOptions"
                    @vdropzone-complete="uploadCompleted"
                    @vdropzone-file-added="fileAdded"
                    @uploadSuccess="uploadSuccess"
                    @vdropzone-removed-file="clearSecureUrl"
                    @vdropzone-error="uploadError"
                />
                <div class="dooh-note">
                    <SrCheckbox
                        v-model="formData.isDooh"
                        :disabled="isEdit"
                        label="Enable DOOH creatives (disables tracking)"
                        data-test-id="is-dooh"
                    ></SrCheckbox>
                    <SrInfoBox class="ml-2" main>
                        <div style="max-width: 250px">
                            Allows uploading files up to 4.5MB in size. Ideal for Digital Out-of-Home (DOOH) advertising, but not
                            for standard display ads due to loss of tracking and size restrictions by ad networks.
                        </div>
                    </SrInfoBox>
                </div>
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrDropzoneUpload, SrHeadline, SrURLInput, SrCheckbox, SrInfoBox } from '@ads/design-system';
import deepmerge from 'deepmerge';
import FormBasics from '@/components/domain/creatives/FormBasics';
import UploadOptions from '@/services/upload/UploadOptions';
import assetRules from '@/components/domain/creatives/image/assetRules';
import Dimensions from '@/components/domain/creatives/shared/dimensions/Dimensions';
import dimensionsRules from '@/components/domain/creatives/shared/dimensionsRules';
import ClickUrl from '@/components/domain/creatives/shared/basics/ClickUrl';
import getDropzoneFileSize from '@/components/domain/creatives/shared/getDropzoneFileSize';
import DropzoneFileList from '@/components/domain/creatives/shared/bulk/bulk-creative-dropzone/DropzoneFileList';
import { convertMiBToMB } from '@/utils/conversions';
import { updateDropzoneOptions } from '@/components/domain/creatives/shared/updateDropzoneOptions';

export default {
    name: 'ImageAssets',
    components: {
        SrInfoBox,
        ClickUrl,
        SrDropzoneUpload,
        SrHeadline,
        SrURLInput,
        Dimensions,
        SrCheckbox,
    },
    mixins: [FormBasics],
    props: {
        dynamicRules: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            lastUploadedImageUrl: '',
            imageDimensionsRules: dimensionsRules,
            dropzoneOptionsImage: UploadOptions.createDropzoneOptionsForSingleImage(),
            dropzoneOptionsDOOH: UploadOptions.createDropzoneOptionsForSingleDOOHImage(),
            dropzone: null,
        };
    },
    computed: {
        rules() {
            return deepmerge(assetRules, this.dynamicRules);
        },
        dropzoneOptions() {
            return this.formData.isDooh ? this.dropzoneOptionsDOOH : this.dropzoneOptionsImage;
        },
        isEdit() {
            return this.formData.id != null;
        },
    },
    watch: {
        'creative.secureUrl': function (value, oldValue) {
            if (oldValue !== '') {
                this.clearUploadedImage();
            }
        },
        'formData.isDooh': function () {
            updateDropzoneOptions(this.dropzoneOptions, this.$refs.uploadDropzone.$refs.vueDropzone);
        },
    },
    mounted() {
        this.dropzone = this.$refs.uploadDropzone.$refs.vueDropzone;
    },
    methods: {
        uploadSuccess(file, response) {
            this.lastUploadedImageUrl = response.secureUrl;
            this.formData.secureUrl = response.secureUrl;
            this.formData.fileSize = getDropzoneFileSize(file.dropzoneFile.dataURL);
            this.$set(this.formData, 'dimensions', response.dimensions);
            file.name = response.secureUrl.substring(response.secureUrl.lastIndexOf('/') + 1);
            this.$emit('errorMessage', null);
        },
        clearSecureUrl() {
            if (this.$refs.uploadDropzone.getListSize() === 0) {
                this.lastUploadedImageUrl = '';
                this.formData.secureUrl = '';
                this.formData.dimensions = null;
                this.formData.fileSize = null;
            }
        },
        fileAdded() {
            this.loading = true;
        },
        uploadCompleted() {
            this.loading = false;
        },
        uploadedImageChanged() {
            return this.lastUploadedImageUrl !== this.formData.secureUrl;
        },
        clearUploadedImage() {
            if (!this.uploadedImageChanged()) {
                return;
            }
            this.$emit('errorMessage', null);
            this.lastUploadedImageUrl = '';
            this.dropzone.removeAllFiles();
            this.formData.dimensions = null;
            this.formData.fileSize = null;
        },
        uploadError(file, msg) {
            if (msg.message) {
                this.$emit('errorMessage', `"${file.upload.filename}":\n${msg.message}`);
                return;
            }
            const isFileSizeExceededError = typeof msg === 'string' && msg.includes('File is too big');
            if (isFileSizeExceededError) {
                this.emitErrorForTooLargeFiles([file]);
                return;
            }
            this.$emit('errorMessage', msg);
        },
        emitErrorForTooLargeFiles(fileList) {
            const files = new DropzoneFileList(fileList);
            const maxAllowedFileSizeInMB = this.getMaxAllowedFileSizeInMB();
            const errorMessage = files.generateErrorMessageForTooLargeFiles(maxAllowedFileSizeInMB);
            if (errorMessage.length > 0) {
                this.$emit('errorMessage', errorMessage);
            }
        },
        getMaxAllowedFileSizeInMB() {
            const maxAllowedFileSizeInMiB = this.dropzoneOptions?.maxFilesize;
            return convertMiBToMB(maxAllowedFileSizeInMiB);
        },
    },
};
</script>

<style lang="scss">
@import 'assets';

.dooh-note {
    display: flex;
    align-items: center;
}
</style>
