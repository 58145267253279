import { INativeCreative, IBusinessEntity, IImageUploadResponse } from 'api-contracts';
import IImageAsset from 'api-contracts/native-creative/IImageAsset';
import { DropzoneFileWithBulkAttributes } from '@/components/domain/creatives/shared/bulk/bulk-creative-dropzone/BulkCreativeDropzoneViewModel';

export default class NativeAssetsViewModel {
    private creative: INativeCreative;

    public isIconUploadLoading: boolean;

    public isMainImagesUploadLoading: boolean;

    constructor(creative: INativeCreative) {
        this.creative = creative;
        this.isIconUploadLoading = false;
        this.isMainImagesUploadLoading = false;
    }

    public reinitialize(creative: INativeCreative): void {
        this.creative = creative;
    }

    public shouldRenderForm(): boolean {
        const maybeBusinessEntity = this.creative.businessEntity as IBusinessEntity;
        return Boolean(maybeBusinessEntity && maybeBusinessEntity.name && maybeBusinessEntity.id);
    }

    public handleMainImageFileUpload(_file: DropzoneFileWithBulkAttributes, response: IImageUploadResponse): void {
        this.creative.mainImages.push(this.transformToImageAsset(response));
    }

    public handleIconFileUpload(_file: DropzoneFileWithBulkAttributes, response: IImageUploadResponse): void {
        this.creative.iconImage = this.transformToImageAsset(response);
    }

    private transformToImageAsset(response: IImageUploadResponse): IImageAsset {
        return { dimensions: response.dimensions, secureUrl: response.secureUrl };
    }

    public removeMainImageAt(number: number): void {
        this.creative.mainImages.splice(number, 1);
    }

    public removeIconImage(): void {
        this.creative.iconImage = null;
    }

    public handleIconImageUploadLoading(value: boolean): void {
        this.isIconUploadLoading = value;
    }

    public handleMainImagesUploadLoading(value: boolean): void {
        this.isMainImagesUploadLoading = value;
    }

    public get isLoading(): boolean {
        return this.isIconUploadLoading || this.isMainImagesUploadLoading;
    }

    // Get ters & Setters

    get advertiserUrl(): string {
        return this.creative.advertiserUrl;
    }

    set advertiserUrl(value: string) {
        this.creative.advertiserUrl = value;
    }

    get clickUrl(): string {
        return this.creative.clickUrl;
    }

    set clickUrl(value: string) {
        this.creative.clickUrl = value;
    }

    get impressionTrackingUrl(): string {
        return this.creative.impressionTrackingUrl;
    }

    set impressionTrackingUrl(value: string) {
        this.creative.impressionTrackingUrl = this.makeNullIfIsEmpty(value);
    }

    get mainImages(): INativeCreative['mainImages'] {
        return this.creative.mainImages;
    }

    get iconImage(): INativeCreative['iconImage'] {
        return this.creative.iconImage;
    }

    get businessEntity(): INativeCreative['businessEntity'] {
        return this.creative.businessEntity;
    }

    get title(): INativeCreative['title'] {
        return this.creative.title;
    }

    set title(value: INativeCreative['title']) {
        this.creative.title = value;
    }

    get description(): INativeCreative['description'] {
        return this.creative.description;
    }

    set description(value: INativeCreative['description']) {
        this.creative.description = this.makeNullIfIsEmpty(value);
    }

    get sponsorship(): INativeCreative['sponsorship'] {
        return this.creative.sponsorship;
    }

    set sponsorship(value: INativeCreative['sponsorship']) {
        this.creative.sponsorship = this.makeNullIfIsEmpty(value);
    }

    private makeNullIfIsEmpty(value: string): string | null {
        if (!value) {
            return null;
        }
        return value;
    }
}
