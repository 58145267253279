import AudioCreativeCreate from '@/components/domain/creatives/audio/AudioCreativeCreate.vue';
import AudioCreativeEdit from '@/components/domain/creatives/audio/AudioCreativeEdit.vue';
import AudioCreativeOverview from '@/components/domain/creatives/audio/overview/AudioCreativeOverview.vue';

export default [
    {
        name: 'audio-creative-overview',
        path: '/audio-creative',
        component: AudioCreativeOverview,
    },
    {
        name: 'audio-creative-create',
        path: '/audio-creative/create',
        component: AudioCreativeCreate,
    },
    {
        name: 'audio-creative-edit',
        path: '/audio-creative/:id',
        component: AudioCreativeEdit,
        props: (route) => ({
            id: route.params.id,
        }),
    },
];
