import Html5CreativeOverview from '@/components/domain/creatives/html5/Overview.vue';
import Html5CreativeCreate from '@/components/domain/creatives/html5/Create.vue';
import Html5CreativeEdit from '@/components/domain/creatives/html5/Edit.vue';
import Html5CreativeBulkForm from '@/components/domain/creatives/html5/bulk/BulkForm.vue';

import { ACTION_TYPES } from '@/components/domain/creatives/types';

export default [
    {
        name: 'html5-creative-overview',
        path: '/html5-creative',
        component: Html5CreativeOverview,
    },
    {
        name: 'html5-creative-create',
        path: '/html5-creative/create',
        component: Html5CreativeCreate,
    },
    {
        name: 'html5-creative-bulk-create',
        path: '/html5-creative/bulk-create',
        component: Html5CreativeBulkForm,
        props: () => ({
            afterRoute: 'html5-creative-overview',
            submitAction: ACTION_TYPES.CREATE,
        }),
    },
    {
        name: 'html5-creative-edit',
        path: '/html5-creative/:id',
        component: Html5CreativeEdit,
        props: (route) => ({
            id: route.params.id,
        }),
    },
];
