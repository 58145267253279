<template>
    <VCol cols="12">
        <VDivider class="mb-6" />
    </VCol>
</template>

<script>
export default {
    name: 'Divider',
};
</script>
