import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { IDimensions } from 'api-contracts';
import { API_URL } from '@/config';

export default class DimensionsService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'creative-management',
        resource: 'creative',
    };

    private readonly domainPath: string;

    constructor(requestService: RequestService, domainPath?: string) {
        this.requestService = requestService;
        this.domainPath = domainPath;
    }

    async listDimensions(): Promise<IDimensions[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get('/dimensions', options);
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }
}

export const dimensionsService = (domainPath?: string) =>
    new DimensionsService(new RequestService({ baseUrl: API_URL }), domainPath);
