import ExcelCreativeExtractor from '@/shared/excel/ExcelCreativeExtractor';
import IRawVideoExcelRow from '@/components/domain/creatives/video/bulk/IRawVideoExcelRow';
import IBulkVideoCreativeRow from '@/components/domain/creatives/video/bulk/IBulkVideoCreativeRow';
import VideoCreativeImportTransformer from '@/components/domain/creatives/video/bulk/VideoCreativeImportTransformer';

export default function createVideoExcelCreativeExtractor(): ExcelCreativeExtractor<IRawVideoExcelRow, IBulkVideoCreativeRow> {
    const videoCreativeImportTransformer = new VideoCreativeImportTransformer();
    return ExcelCreativeExtractor.create<IRawVideoExcelRow, IBulkVideoCreativeRow>(videoCreativeImportTransformer, [
        'name',
        'vastUrl',
        'skippable',
        'expirationStart',
        'expirationEnd',
        'expirationTimezone',
    ]);
}
