<script>
import FormTemplate from '@/components/domain/creatives/FormTemplate';
import { dynamicCreativeErrorRuleFactory } from '@/components/domain/creatives/shared/DynamicCreativeErrorRuleFactory';

export default {
    name: 'CreativeFormTemplate',
    extends: FormTemplate,
    props: {
        value: {
            type: Object,
            required: true,
        },
        isEditForm: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            creative: this.value,
            errorRuleFactory: dynamicCreativeErrorRuleFactory,
        };
    },
    computed: {
        components() {
            if (!this.isMounted) {
                return [];
            }
            return [this.$refs['basic-creative'], this.$refs['asset-creative'], this.$refs['categorization-creative']];
        },
    },
    watch: {
        value(value) {
            this.creative = value;
        },
    },
    methods: {
        submit(done = () => null) {
            this.request(this.submitAction, this.creative)
                .then(() => {
                    this.$emit('close');
                    this.$router.push({ name: this.afterRoute });
                })
                .catch(() => {
                    // ignore
                })
                .finally(() => {
                    done();
                });
        },
    },
};
</script>
