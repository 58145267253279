import HtmlCreativeOverview from '@/components/domain/creatives/html/Overview.vue';
import HtmlCreativeCreate from '@/components/domain/creatives/html/Create.vue';
import HtmlCreativeEdit from '@/components/domain/creatives/html/Edit.vue';
import HtmlCreativeBulkForm from '@/components/domain/creatives/html/bulk/BulkForm.vue';

import { ACTION_TYPES } from '@/components/domain/creatives/types';

export default [
    {
        name: 'html-creative-overview',
        path: '/html-creative',
        component: HtmlCreativeOverview,
    },
    {
        name: 'html-creative-create',
        path: '/html-creative/create',
        component: HtmlCreativeCreate,
    },
    {
        name: 'html-creative-bulk-create',
        path: '/html-creative/bulk-create',
        component: HtmlCreativeBulkForm,
        props: () => ({
            afterRoute: 'html-creative-overview',
            submitAction: ACTION_TYPES.CREATE,
        }),
    },
    {
        name: 'html-creative-edit',
        path: '/html-creative/:id',
        component: HtmlCreativeEdit,
        props: (route) => ({
            id: route.params.id,
        }),
    },
];
