<template>
    <SrDropzoneUpload
        id="bulk-upload-dropzone"
        ref="uploadDropzone"
        :disable-file-list="true"
        :dropzone-file-list.sync="viewModel.files"
        :dropzone-options="dropzoneOptions"
        @uploadSuccess="uploadSuccess"
        @vdropzone-removed-file="fileRemoved"
        @vdropzone-complete-multiple="canceled"
        @vdropzone-files-added="filesAdded"
        @vdropzone-file-added="fileAdded"
        @vdropzone-canceled="canceled"
        @vdropzone-error="uploadError"
    />
</template>

<script>
import { SrDropzoneUpload } from '@ads/design-system';
import DropzoneFileList from '@/components/domain/creatives/shared/bulk/bulk-creative-dropzone/DropzoneFileList';
import { convertMiBToMB } from '@/utils/conversions';
import { updateDropzoneOptions } from '@/components/domain/creatives/shared/updateDropzoneOptions';
import BulkCreativeDropzoneViewModel from './BulkCreativeDropzoneViewModel';

export default {
    name: 'BulkCreativeDropzone',
    components: {
        SrDropzoneUpload,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        businessEntity: {
            validator: (value) => {
                const keys = Object.keys(value);
                return keys.includes('id') && keys.includes('name');
            },
            required: true,
        },
        dropzoneOptions: {
            type: Object,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        nameTemplateOptions: {
            type: Object,
            default: () => null,
        },
        removeFileAfterUpload: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            lastResponseErrors: [],
            viewModel: new BulkCreativeDropzoneViewModel(this.value),
        };
    },
    computed: {
        files() {
            return this.viewModel.files;
        },
    },
    watch: {
        'viewModel.files': {
            deep: true,
            handler(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        uploadSuccess(srDropzoneFile, multiResponse) {
            const validationError = this.viewModel.validateFile(srDropzoneFile, multiResponse);
            if (validationError) {
                this.removeFileFromDropzone(srDropzoneFile);
                this.emitValidationError(validationError);
                return;
            }
            const fileResponse = this.viewModel.getResponseForFile(srDropzoneFile, multiResponse);
            this.updateFileWithResponseAttributes(srDropzoneFile, fileResponse);
            this.$emit('uploadedFile', srDropzoneFile, fileResponse);
            if (this.removeFileAfterUpload) {
                this.removeFileFromDropzone(srDropzoneFile);
            }
        },
        canceled() {
            this.$emit('canceled');
            this.$emit('update:isLoading', false);
        },
        fileAdded(file) {
            this.$emit('fileAdded', file);
        },
        filesAdded(fileList) {
            this.$emit('update:isLoading', true);
            this.$emit('errorMessage', null);
            this.emitErrorForTooLargeFiles(fileList);
        },
        uploadError(file, errorOrMessage) {
            const errorMessage = errorOrMessage?.message || errorOrMessage;
            const isFileSizeExceededError = errorMessage.includes('File is too big');
            if (isFileSizeExceededError) {
                this.emitErrorForTooLargeFiles([file]);
            } else {
                this.emitErrorMessage(`"${file.name}":\n${errorMessage}`);
            }
            this.$emit('update:isLoading', false);
        },
        emitErrorForTooLargeFiles(fileList) {
            const files = new DropzoneFileList(fileList);
            const maxAllowedFileSizeInMB = this.getMaxAllowedFileSizeInMB();
            const errorMessage = files.generateErrorMessageForTooLargeFiles(maxAllowedFileSizeInMB);
            if (errorMessage.length > 0) {
                this.emitErrorMessage(errorMessage);
                this.$emit('update:isLoading', false);
            }
        },
        getMaxAllowedFileSizeInMB() {
            const maxAllowedFileSizeInMiB = this.dropzoneOptions?.maxFilesize;
            return convertMiBToMB(maxAllowedFileSizeInMiB);
        },

        fileRemoved() {
            this.$emit('fileRemoved');
        },
        emitValidationError(validationError) {
            this.emitErrorMessage(`"${validationError.filename}":\n${validationError.message}`);
        },
        removeFileFromDropzone(srDropzoneFile) {
            this.$refs.uploadDropzone.removeFile(srDropzoneFile);
        },
        updateFileWithResponseAttributes(srDropzoneFile, fileResponse) {
            this.viewModel.updateFile(srDropzoneFile, this.businessEntity.id, fileResponse);
            if (this.nameTemplateOptions) {
                srDropzoneFile.name = this.viewModel.createTemplatedName(this.nameTemplateOptions, fileResponse);
            }
        },
        updateOptions(options) {
            updateDropzoneOptions(options, this.$refs.uploadDropzone.$refs.vueDropzone);
        },
        triggerUpload() {
            this.$refs.uploadDropzone.triggerUpload();
        },
        emitErrorMessage(errorMessage) {
            this.$emit('errorMessage', errorMessage);
        },
    },
};
</script>

<style lang="scss">
#bulk-upload-dropzone {
    flex-direction: row;
    flex-wrap: wrap;

    .sr-dropzone-upload .dropzone-container {
        max-height: 300px;
        overflow-y: auto;
    }

    .dz-preview {
        max-width: 250px;
    }
}
</style>
