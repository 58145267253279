import URLPathBuilder from '@/services/URLPathBuilder';
import { IMimeType, IVastApiFramework } from 'api-contracts';
import { API_URL } from '@/config';
import { ACTION_NAME, RequestService } from '@ads/iam-library';

export default class MediaAssetService {
    private readonly requestOptions = {
        domain: 'creative-management',
        resource: 'creative',
        action: ACTION_NAME.READ,
    };

    private readonly requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    public async listAudioMimeTypes(): Promise<IMimeType[]> {
        return this.listMimeTypes('audio');
    }

    public async listVideoMimeTypes(): Promise<IMimeType[]> {
        return this.listMimeTypes();
    }

    private async listMimeTypes(type?: 'audio'): Promise<IMimeType[]> {
        const urlPathBuilder = new URLPathBuilder(`/mime-types`);
        if (type) {
            urlPathBuilder.setParam('type', type);
        }
        return this.requestService.get(urlPathBuilder.build(), this.requestOptions);
    }

    async listVastApiFrameworks(): Promise<IVastApiFramework[]> {
        const urlPathBuilder = new URLPathBuilder('/vast-api-frameworks');
        return this.requestService.get(urlPathBuilder.build(), this.requestOptions);
    }
}

export const mediaAssetService = new MediaAssetService(new RequestService({ baseUrl: API_URL }));
