export enum TABLE_ACTION_TYPES {
    EDIT = 'Edit',
    HISTORY = 'History',
    DELETE = 'Delete',
    ARCHIVE = 'ARCHIVE',
    DUPLICATE = 'DUPLICATE',
}

export enum STATUS {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    ARCHIVE = 'Archive',
    SCHEDULED = 'Scheduled',
}

export enum OPERATOR_TYPES {
    ALPHABETIC = 'Alphabetic',
    NUMERIC = 'Numeric',
    BOOLEAN = 'Boolean',
}

export enum SEARCH_VALUE_TYPES {
    TEXT = 'Text',
    SELECT = 'Select',
}
