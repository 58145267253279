<template>
    <div>
        <BulkCreativeTable
            v-model="creatives"
            :advertiser-name="advertiserName"
            :headers="headers"
            :is-loading="isLoading"
            :selected.sync="selected"
            :sort-by="['dimensions']"
            :sort-desc="[false]"
            template="${date}_${advertiserName}_${filename}_${dimensions.width}x${dimensions.height}"
            v-bind="$attrs"
            @bulkEdit="bulkEdit"
            @edit="edit"
            v-on="$listeners"
        >
            <template #name="{ item }">
                <div :title="item.name" class="truncate-creative-name">
                    {{ item.name }}
                </div>
            </template>
        </BulkCreativeTable>
        <EditImageCreativeModal
            ref="edit"
            :advertise-name="advertiserName"
            :index="editIndex"
            :is-open="showEditModal"
            :item="editItem"
            :modal-error-message="modalErrorMessage"
            @close="closeEditModal"
            @done="editModalChanged"
        />
        <BulkEditImageCreativeModal
            ref="bulkEdit"
            :is-open="showBulkEditModal"
            :selected="selected"
            :modal-error-message="modalErrorMessage"
            @close="closeBulkEditModal"
            @done="bulkEditModalChanged"
        />
    </div>
</template>

<script>
import EditImageCreativeModal from '@/components/domain/creatives/image/bulk/EditImageCreativeModal';
import BulkEditImageCreativeModal from '@/components/domain/creatives/image/bulk/BulkEditImageCreativeModal';
import BulkCreativeTable from '@/components/domain/creatives/shared/bulk/BulkCreativeTable';
import BulkCreativeTableTemplate from '@/components/domain/creatives/shared/bulk/BulkCreativeTableTemplate';

export default {
    name: 'ImageBulkCreativeTable',
    components: {
        EditImageCreativeModal,
        BulkEditImageCreativeModal,
        BulkCreativeTable,
    },
    mixins: [BulkCreativeTableTemplate],
};
</script>
