import NativeCreativeOverview from '@/components/domain/creatives/native/overview/NativeCreativeOverview.vue';
import NativeCreativeCreate from '@/components/domain/creatives/native/NativeCreativeCreate.vue';
import NativeCreativeEdit from '@/components/domain/creatives/native/NativeCreativeEdit.vue';

export default [
    {
        name: 'native-creative-overview',
        path: '/native-creative',
        component: NativeCreativeOverview,
    },
    {
        name: 'native-creative-create',
        path: '/native-creative/create',
        component: NativeCreativeCreate,
    },
    {
        name: 'native-creative-edit',
        path: '/native-creative/:id',
        component: NativeCreativeEdit,
        props: (route) => ({
            id: route.params.id,
        }),
    },
];
