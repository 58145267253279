<template>
    <VForm ref="form" v-model="formIsValid" :disabled="form.disabled">
        <VRow>
            <VCol lg="12" md="12" sm="12" xlg="12">
                <VRow>
                    <VCol lg="5" md="6" sm="12" xlg="4">
                        <SrHeadline class="mb-6" level="3" weight="bold"> URL Assets </SrHeadline>
                        <SrURLInput
                            v-model="formData.advertiserUrl"
                            class="url-input"
                            label="Advertiser URL"
                            secure-url
                            required
                        />
                        <Dimensions ref="dimensionsRef" v-model="selectedDimensions" required />
                        <SelectableMacros @currentReplacements="replacements" />
                    </VCol>
                </VRow>
                <VRow>
                    <VCol lg="12" md="12" sm="12" xlg="8">
                        <HtmlCodeDiff
                            v-model="generatedCode"
                            :replacements="currentReplacements"
                            :template="template"
                            mode="MergeView"
                        />
                    </VCol>
                </VRow>
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrHeadline, SrURLInput } from '@ads/design-system';
import FormBasics from '@/components/domain/creatives/FormBasics';
import assetsCreativeRules from '@/components/domain/creatives/html/assetsCreativeRules';
import Dimensions from '@/components/domain/creatives/shared/dimensions/Dimensions';
import SelectableMacros from '@/components/domain/creatives/html/SelectableMacros';
import HtmlCodeDiff from '@/components/domain/creatives/html/HtmlCodeDiff/HtmlCodeDiff';

export default {
    name: 'HtmlAssets',
    components: {
        SelectableMacros,
        Dimensions,
        SrHeadline,
        SrURLInput,
        HtmlCodeDiff,
    },
    mixins: [FormBasics],
    data() {
        return {
            generatedCode: '',
            rules: assetsCreativeRules,
            currentReplacements: [],
            template: '',
        };
    },
    computed: {
        selectedDimensions: {
            get() {
                if (!this.formData.dimensions) {
                    return {};
                }

                return this.formData.dimensions;
            },
            set(dimensions) {
                this.$set(this.formData, 'dimensions', dimensions);
            },
        },
    },
    watch: {
        generatedCode(code) {
            this.formData.template = code;
        },
        'formData.id': {
            deep: true,
            async handler() {
                this.template = this.formData.template;
            },
        },
    },
    methods: {
        replacements(replacements) {
            this.currentReplacements = replacements;
        },
    },
};
</script>

<style lang="scss" scoped>
.generated-html-code {
    margin-top: 373px;
}
</style>
