<template>
    <VForm ref="form" v-model="formIsValid" :disabled="form.disabled">
        <VRow v-if="viewModel.shouldRenderForm()" no-gutters>
            <VCol cols="6">
                <VRow no-gutters>
                    <VCol cols="12">
                        <SrHeadline level="3" weight="bold" class="mb-6"> URL Assets </SrHeadline>
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="10">
                        <SrURLInput v-model="viewModel.advertiserUrl" label="Advertiser URL" required secure-url />
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="10">
                        <ClickUrl v-model="viewModel.clickUrl" />
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="10">
                        <SrURLInput
                            v-model="viewModel.impressionTrackingUrl"
                            label="Impression Tracker URL"
                            :required="false"
                            secure-url
                        />
                    </VCol>
                </VRow>
            </VCol>
            <VCol cols="6">
                <VRow no-gutters>
                    <VCol cols="12">
                        <SrHeadline level="3" weight="bold" class="mb-6"> Main Images Assets </SrHeadline>
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="12">
                        <BulkCreativeDropzone
                            ref="mainImagesDropzone"
                            :value="[]"
                            :business-entity="viewModel.businessEntity"
                            :dropzone-options="mainImagesDropzoneOptions"
                            :remove-file-after-upload="true"
                            @errorMessage="handleErrorMessage"
                            @uploadedFile="handleMainImageUpload"
                            @update:isLoading="handleMainImageUploadLoading"
                        />
                    </VCol>
                </VRow>
                <ImageAssetsPreview :image-assets="viewModel.mainImages" :on-remove="onMainImageRemove" />
                <VRow>
                    <VCol cols="12">
                        <SrHeadline level="3" weight="bold" class="mb-6"> Information Assets </SrHeadline>
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="10">
                        <SrInput v-model="viewModel.title" label="Title" required />
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="10">
                        <SrInput v-model="viewModel.description" label="Description" :required="false" />
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="10">
                        <SrInput v-model="viewModel.sponsorship" label="Sponsorship" :required="false" />
                    </VCol>
                </VRow>

                <VRow>
                    <VCol cols="12">
                        <SrHeadline level="3" class="mb-6"> Icon Asset </SrHeadline>
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="12">
                        <SrButton
                            v-if="!viewModel.iconImage"
                            :loading="viewModel.isIconUploadLoading"
                            type="secondary"
                            @click="triggerIconImageDropzoneUpload"
                        >
                            Upload Image
                        </SrButton>
                        <ImageAssetsPreview v-else :image-assets="[viewModel.iconImage]" :on-remove="onIconImageRemove" />
                        <BulkCreativeDropzone
                            ref="iconImageDropzone"
                            style="display: none"
                            :value="[]"
                            :business-entity="viewModel.businessEntity"
                            :dropzone-options="iconImageDropzoneOptions"
                            :remove-file-after-upload="true"
                            @errorMessage="handleErrorMessage"
                            @uploadedFile="handleIconImageUpload"
                            @update:isLoading="handleIconImageUploadLoading"
                        />
                    </VCol>
                </VRow>
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrHeadline, SrURLInput, SrInput, SrButton } from '@ads/design-system';
import FormBasics from '@/components/domain/creatives/FormBasics';
import BulkCreativeDropzone from '@/components/domain/creatives/shared/bulk/bulk-creative-dropzone/BulkCreativeDropzone';
import UploadOptions from '@/services/upload/UploadOptions';
import ImageAssetsPreview from '@/components/domain/creatives/native/assets/ImageAssetsPreview';
import nativeCreativeAssetsRules from '@/components/domain/creatives/native/assets/nativeCreativeAssetsRules';
import ClickUrl from '@/components/domain/creatives/shared/basics/ClickUrl';
import NativeAssetsViewModel from './NativeCreativeAssetsViewModel';

export default {
    name: 'NativeAssets',
    components: {
        ClickUrl,
        SrHeadline,
        SrURLInput,
        BulkCreativeDropzone,
        SrInput,
        ImageAssetsPreview,
        SrButton,
    },
    mixins: [FormBasics],
    data() {
        return {
            viewModel: new NativeAssetsViewModel(this.value),
            mainImagesDropzoneOptions: UploadOptions.createDropzoneOptionsForMainImageAssets(),
            iconImageDropzoneOptions: UploadOptions.createDropzoneOptionsForIconAsset(),
            rules: nativeCreativeAssetsRules,
        };
    },
    watch: {
        value(newValue) {
            this.viewModel.reinitialize(newValue);
        },
        'viewModel.isLoading': {
            deep: true,
            handler(isLoading) {
                this.isLoading = isLoading;
            },
        },
    },
    methods: {
        handleErrorMessage(message) {
            this.$emit('errorMessage', message);
        },
        handleMainImageUpload(srDropzoneFile, response) {
            this.viewModel.handleMainImageFileUpload(srDropzoneFile, response);
        },
        handleIconImageUpload(srDropzoneFile, response) {
            this.viewModel.handleIconFileUpload(srDropzoneFile, response);
        },
        handleMainImageUploadLoading(value) {
            this.viewModel.handleMainImagesUploadLoading(value);
        },
        handleIconImageUploadLoading(value) {
            this.viewModel.handleIconImageUploadLoading(value);
        },
        onMainImageRemove(index) {
            this.viewModel.removeMainImageAt(index);
        },
        onIconImageRemove() {
            this.viewModel.removeIconImage();
        },
        triggerIconImageDropzoneUpload() {
            this.$refs.iconImageDropzone.triggerUpload();
        },
    },
};
</script>
