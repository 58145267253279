<template>
    <BulkEditCreativeModal v-bind="$attrs" v-on="$listeners">
        <template #form-content="{ creative, rules, description, runtime }">
            <VCardText>{{ description }}</VCardText>
            <VCardText>
                <SrURLInput
                    v-model="creative.advertiserUrl"
                    :rules="rules.url"
                    class="advertiser-url"
                    label="Advertiser URL"
                    maxlength="2048"
                    required
                    secure-url
                />
                <CreativeRuntime :value="runtime" @input="(runtime) => changeRuntime(creative, runtime)" />
            </VCardText>
        </template>
    </BulkEditCreativeModal>
</template>

<script>
import { SrURLInput } from '@ads/design-system';
import CreativeRuntime from '@/components/domain/creatives/shared/basics/CreativeRuntime';
import BulkEditCreativeModal from '@/components/domain/creatives/shared/bulk/BulkEditCreativeModal';

export default {
    name: 'BulkEditHtmlCreativeModal',
    components: {
        BulkEditCreativeModal,
        CreativeRuntime,
        SrURLInput,
    },
    methods: {
        changeRuntime(creative, runtime) {
            creative.runtime = runtime;
        },
    },
};
</script>
