import { ImagePaginationFilterValue } from 'api-contracts';

import { SelectSortOrder } from '@ads/design-system/src/components/SrAdvancedFilter/types';
import { Predicate } from '@ads/predicate-model';

import store from '@/store';
import { BusinessEntity } from '@/services/iam/types';
import { OPERATOR_TYPES, SEARCH_VALUE_TYPES } from '@/components/shared/table/types';

interface ISelectableFilter<T> {
    name: string;
    filterCriteria: T;
    operatorType: OPERATOR_TYPES;
    searchValueType: SEARCH_VALUE_TYPES;
    selectValues?: unknown;
    selectSearchable?: boolean;
    selectSortOrder?: SelectSortOrder;
    toStringConfig?: unknown;
}

export function getPropertiesToFilterBy(businessEntities: BusinessEntity[]): ISelectableFilter<ImagePaginationFilterValue>[] {
    const selectableBusinessEntities = businessEntities.map((businessEntity: BusinessEntity) => ({
        value: businessEntity.id,
        label: businessEntity.name,
    }));

    function advertiserPredicateToString(predicate: Predicate) {
        if (!predicate) {
            return '';
        }
        const advertiser = selectableBusinessEntities.find(
            (currentBusinessEntity: { value: number; label: string }) => currentBusinessEntity.value === predicate.parameterValue,
        );
        if (advertiser) {
            return advertiser.label;
        }
        return predicate.parameterValue;
    }

    return [
        {
            name: 'DSP ID',
            filterCriteria: 'dspId',
            operatorType: OPERATOR_TYPES.NUMERIC,
            searchValueType: SEARCH_VALUE_TYPES.TEXT,
        },
        {
            name: 'Name',
            filterCriteria: 'name',
            operatorType: OPERATOR_TYPES.ALPHABETIC,
            searchValueType: SEARCH_VALUE_TYPES.TEXT,
        },
        {
            name: 'Advertiser',
            filterCriteria: 'businessEntity.id',
            operatorType: OPERATOR_TYPES.BOOLEAN,
            searchValueType: SEARCH_VALUE_TYPES.SELECT,
            selectValues: selectableBusinessEntities,
            selectSortOrder: SelectSortOrder.ASC,
            selectSearchable: true,
            toStringConfig: {
                getParameterValueLabel: advertiserPredicateToString,
            },
        },
    ];
}
