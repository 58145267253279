<template>
    <SrSelect :value="value" :items="availableVastVersions" label="Vast Version" required @change="onValueChange" />
</template>

<script>
import { SrSelect } from '@ads/design-system';

export default {
    name: 'VastVersion',
    components: {
        SrSelect,
    },
    props: {
        value: {
            type: String,
        },
    },
    data() {
        return {
            availableVastVersions: [
                { label: '2.0', value: '2.0' },
                { label: '3.0', value: '3.0' },
                { label: '4.0', value: '4.0' },
            ],
        };
    },
    methods: {
        onValueChange(newValue) {
            this.$emit('input', newValue);
        },
    },
};
</script>
