<template>
    <VForm ref="form" v-model="formIsValid" :disabled="form.disabled">
        <VRow v-if="viewModel.shouldRenderForm()" no-gutters>
            <VCol cols="6">
                <VRow no-gutters>
                    <VCol cols="12">
                        <SrHeadline level="3" weight="bold" class="mb-6"> URL Assets </SrHeadline>
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="10">
                        <SrURLInput v-model="viewModel.advertiserUrl" label="Advertiser URL" required secure-url />
                    </VCol>
                </VRow>
                <VastUrlInput v-model="viewModel.vastUrl" />
                <SrHeadline class="mb-6" level="3" weight="bold"> Attributes Input Method </SrHeadline>
                <VRadioGroup v-model="viewModel.isVastUrlParsed" row>
                    <SrRadioButton :value="true" label="Auto" />
                    <SrRadioButton :value="false" label="Manual" />
                </VRadioGroup>
                <SrLabel
                    v-if="viewModel.isVastUrlParsed"
                    class="ma-1"
                    color="blue"
                    text="Parses some VAST properties from VAST URL secure content."
                />
                <div v-else>
                    <VastVersion v-model="viewModel.vastVersion" />
                    <SrInput
                        v-model="viewModel.duration"
                        :rules="viewModel.rules.duration"
                        class="vast-duration"
                        label="Audio duration [s]"
                        type="number"
                        min="1"
                        step="1"
                        maxlength="10"
                        required
                    />
                    <SrInput
                        v-model="viewModel.bitrate"
                        :rules="viewModel.rules.bitrate"
                        class="vast-duration"
                        label="Bitrate"
                        type="number"
                        min="1"
                        step="1"
                        maxlength="10"
                        required
                    />
                    <AudioVastMimeTypeSelect v-model="viewModel.mimeTypes" />
                    <ErrorBox :message="viewModel.loadingMimeTypesErrorMsg" :show="!!viewModel.loadingMimeTypesErrorMsg" />
                    <VastApiFrameworksSelect
                        v-model="viewModel.vastApiFrameworks"
                        @error="(errorMsg) => viewModel.handleApiFrameworksError(errorMsg)"
                    />
                    <ErrorBox :message="viewModel.loadingFrameworksErrorMsg" :show="!!viewModel.loadingFrameworksErrorMsg" />
                </div>
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrHeadline, SrInput, SrLabel, SrRadioButton, SrURLInput } from '@ads/design-system';
import FormBasics from '@/components/domain/creatives/FormBasics';
import AudioAssetsViewModel from '@/components/domain/creatives/audio/assets/AudioAssetsViewModel';
import ErrorBox from '@/components/domain/creatives/shared/ErrorBox';
import VastUrlInput from '@/components/domain/vast/VastUrlInput';
import VastVersion from '@/components/domain/vast/VastVersion';
import VastApiFrameworksSelect from '@/components/domain/vast/VastApiFrameworksSelect';
import AudioVastMimeTypeSelect from '@/components/domain/vast/AudioVastMimeTypeSelect';

export default {
    name: 'AudioAssets',
    components: {
        AudioVastMimeTypeSelect,
        VastVersion,
        VastApiFrameworksSelect,
        VastUrlInput,
        ErrorBox,
        SrInput,
        SrRadioButton,
        SrLabel,
        SrHeadline,
        SrURLInput,
    },
    mixins: [FormBasics],
    data() {
        return {
            viewModel: new AudioAssetsViewModel(this.value),
        };
    },
    watch: {
        value(newValue) {
            this.viewModel.reinitialize(newValue);
        },
    },
};
</script>
