import { IMimeType } from 'api-contracts';
import MimeTypeStoreState from '@/store/mime-type/MimeTypeStoreState';

export type MimeTypeStoreModule = {
    namespaced: boolean;
    state: MimeTypeStoreState;
    mutations: {
        setMimeTypes(state: MimeTypeStoreState, newValue: IMimeType[]): void;
        setIsLoadingMimeTypes(state: MimeTypeStoreState, newValue: boolean): void;
        setErrorMessage(state: MimeTypeStoreState, newValue: string): void;
    };
    actions: {
        fetchMimeTypes({
            state,
            commit,
        }: {
            state: MimeTypeStoreState;
            commit: (mutationName: string, newValue: unknown) => void;
        }): Promise<void>;
    };
    getters: {
        getMimeTypes(state: MimeTypeStoreState): IMimeType[];
        getIsLoadingMimeTypes(state: MimeTypeStoreState): boolean;
        getErrorMessage(state: MimeTypeStoreState): string | null;
    };
};

export default class MimeTypeStoreFactory {
    public static createModule(listMimeTypes: () => Promise<IMimeType[]>): MimeTypeStoreModule {
        return {
            namespaced: true,
            state: {
                mimeTypes: [],
                isLoadingMimeTypes: false,
                errorMessage: null,
            },
            mutations: {
                setMimeTypes(state: MimeTypeStoreState, newValue: IMimeType[]): void {
                    state.mimeTypes = newValue;
                },
                setIsLoadingMimeTypes(state: MimeTypeStoreState, newValue: boolean): void {
                    state.isLoadingMimeTypes = newValue;
                },
                setErrorMessage(state: MimeTypeStoreState, newValue: string): void {
                    state.errorMessage = newValue;
                },
            },
            actions: {
                async fetchMimeTypes({
                    state,
                    commit,
                }: {
                    state: MimeTypeStoreState;
                    commit: (mutationName: string, newValue: unknown) => void;
                }): Promise<void> {
                    if (state.mimeTypes.length === 0) {
                        try {
                            commit('setIsLoadingMimeTypes', true);
                            const mimeTypes = await listMimeTypes();
                            commit('setMimeTypes', mimeTypes);
                        } catch (err) {
                            commit('setErrorMessage', err);
                        } finally {
                            commit('setIsLoadingMimeTypes', false);
                        }
                    }
                },
            },
            getters: {
                getMimeTypes(state: MimeTypeStoreState): IMimeType[] {
                    return state.mimeTypes;
                },
                getIsLoadingMimeTypes(state: MimeTypeStoreState): boolean {
                    return state.isLoadingMimeTypes;
                },
                getErrorMessage(state: MimeTypeStoreState): string | null {
                    return state.errorMessage;
                },
            },
        };
    }
}
