<template>
    <VRow no-gutters>
        <ListHeader :headline-name="headlineName" class="overview-header">
            <div slot="actions" class="header-actions">
                <slot name="header-actions" />
            </div>
            <template #filter>
                <SrAdvancedFilter
                    v-if="advancedFilterConfig"
                    :value="advancedFilterPredicate"
                    :config="advancedFilterConfig"
                    class="mt-6 filters"
                    @input="onAdvancedFilterPredicateChange"
                />
            </template>
        </ListHeader>

        <Divider />

        <DefaultTable
            :headers="headers"
            :items="creatives"
            :is-loading="isLoading"
            :options="options"
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50],
            }"
            :server-items-length="totalCreativesCount"
            @update:options="onOptionsChange"
        >
            <template #name="item">
                <router-link :to="getUpdateRoute(item)" class="font-weight-bold">
                    {{ item.name }}
                </router-link>
            </template>
            <template #dimensions="item"> {{ item.dimensions.width }}x{{ item.dimensions.height }} </template>
            <template #actions="item">
                <TableActions
                    :action-routes="actionRoutes"
                    :disable-preview="disablePreview"
                    :item="item"
                    :creative-service="creativeService"
                />
            </template>
        </DefaultTable>
    </VRow>
</template>

<script>
import { SrAdvancedFilter } from '@ads/design-system';
import { OPERATORS } from '@ads/design-system/src/components/SrAdvancedFilter/types';
import DefaultTable from '@/components/shared/table/DefaultTable';
import TableActions from '@/components/shared/table/TableActions';
import ListHeader from '@/components/shared/list-header/ListHeader';
import Divider from '@/components/shared/divider/Divider';
import { creativeService } from '@/services/CreativeService';
import { TABLE_ACTION_TYPES } from '@/components/shared/table/types';
import { getPropertiesToFilterBy } from '@/components/domain/creatives/getPropertiesToFilterBy';
import { sortOptions } from '@/components/domain/creatives/sortOptions';

export default {
    name: 'CreativeOverview',
    components: {
        DefaultTable,
        TableActions,
        ListHeader,
        Divider,
        SrAdvancedFilter,
    },
    props: {
        serviceDomain: {
            type: String,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        editRoute: {
            type: String,
            required: true,
        },
        headlineName: {
            type: String,
            required: true,
        },
        disablePreview: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            creatives: [],
            totalCreativesCount: 0,
            creativeService: creativeService(this.serviceDomain),
            options: sortOptions,
            advancedFilterPredicate: null,
            actionRoutes: {
                [TABLE_ACTION_TYPES.EDIT]: {
                    name: 'Edit',
                    icon: 'Edit',
                    routeName: this.editRoute,
                },
            },
        };
    },
    computed: {
        isOnFirstPage() {
            return this.options.page === 1;
        },
        businessEntities() {
            return this.$store.getters['iamStore/businessEntities'];
        },
        advancedFilterConfig() {
            const propertiesToFilterBy = getPropertiesToFilterBy(this.businessEntities);
            const propertiesToQuickSearchBy = ['dspId', 'name'];
            return {
                properties: propertiesToFilterBy,
                operators: OPERATORS,
                quickSearch: {
                    enabled: true,
                    fields: propertiesToQuickSearchBy,
                },
            };
        },
    },
    methods: {
        onAdvancedFilterPredicateChange(predicate) {
            this.advancedFilterPredicate = predicate;
            if (this.isOnFirstPage) {
                this.loadCreatives();
                return;
            }
            this.jumpToFirstPage();
        },
        onOptionsChange(options) {
            this.options = options;
            this.loadCreatives();
        },
        async loadCreatives() {
            this.isLoading = true;
            try {
                const response = await this.creativeService.list(this.options, this.advancedFilterPredicate);
                this.creatives = response.results;
                this.totalCreativesCount = response.totalCount;
            } catch (error) {
                this.error = error.response.message;
            } finally {
                this.isLoading = false;
            }
        },
        getUpdateRoute(creative) {
            return {
                name: this.actionRoutes[TABLE_ACTION_TYPES.EDIT].routeName,
                params: {
                    id: creative.id,
                },
            };
        },
        jumpToFirstPage() {
            this.options.page = 1;
        },
    },
};
</script>
