import { ISelectableObject } from '@/components/domain/creatives/types';
import { IdObject } from '@/components/types';

export default class IdModelTransformer {
    transformSelectables(selectables: ISelectableObject[]): IdObject[] {
        return selectables.map((selectable) => ({ id: Number(selectable.value) }));
    }
}

export const idModelTransformer = new IdModelTransformer();
