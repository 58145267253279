<template>
    <VForm ref="form" v-model="formIsValid" :disabled="form.disabled">
        <VRow>
            <VCol lg="5" md="6" sm="12" xlg="4">
                <SrHeadline class="mb-6" level="3" weight="bold"> URL Assets </SrHeadline>
                <SrURLInput
                    v-model="viewModel.advertiserUrl"
                    :rules="viewModel.rules.url"
                    class="advertiser-url"
                    label="Advertiser URL"
                    maxlength="2048"
                    required
                    secure-url
                />
                <VastUrlInput v-model="viewModel.vastUrl" />
                <SrHeadline class="mb-6" level="3" weight="bold"> Attributes Input Method </SrHeadline>
                <VRadioGroup v-model="viewModel.isVastUrlParsed" row>
                    <SrRadioButton :value="true" label="Auto" />
                    <SrRadioButton :value="false" label="Manual" />
                </VRadioGroup>
                <SrLabel
                    v-if="viewModel.isVastUrlParsed"
                    class="ma-1"
                    color="blue"
                    text="Parses some VAST properties from VAST URL secure content."
                />
                <div v-else>
                    <VastVersion v-model="viewModel.vastVersion" />
                    <SrInput
                        v-model="viewModel.duration"
                        :rules="viewModel.rules.duration"
                        class="vast-duration"
                        label="Video duration [s]"
                        type="number"
                        min="1"
                        step="1"
                        maxlength="10"
                        required
                    />
                    <VideoVastMimeTypeSelect v-model="viewModel.mimeTypes" />
                    <VastApiFrameworksSelect v-model="viewModel.vastApiFrameworks" />
                </div>
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrHeadline, SrInput, SrLabel, SrRadioButton, SrURLInput } from '@ads/design-system';
import FormBasics from '@/components/domain/creatives/FormBasics';
import VideoAssetsViewModel from '@/components/domain/creatives/video/VideoAssetsViewModel';
import VastUrlInput from '@/components/domain/vast/VastUrlInput';
import VastVersion from '@/components/domain/vast/VastVersion';
import VastApiFrameworksSelect from '@/components/domain/vast/VastApiFrameworksSelect';
import VideoVastMimeTypeSelect from '@/components/domain/vast/VideoVastMimeTypeSelect';

export default {
    name: 'VideoAssets',
    components: {
        VideoVastMimeTypeSelect,
        VastVersion,
        VastApiFrameworksSelect,
        VastUrlInput,
        SrHeadline,
        SrURLInput,
        SrRadioButton,
        SrInput,
        SrLabel,
    },
    mixins: [FormBasics],
    data() {
        return {
            viewModel: new VideoAssetsViewModel(this.value),
        };
    },
    watch: {
        value(creative) {
            this.viewModel.reInitialize(creative);
        },
    },
};
</script>
