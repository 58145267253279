import { IBusinessEntity, IRuntimeCreate, VideoCreativeKeysToFilterBy } from 'api-contracts';
import { format } from 'date-fns';
import { v1 as uuidv1 } from 'uuid';
import formatCreativeName, { IFilenameTemplateOptions } from '@/components/domain/creatives/shared/bulk/formatCreativeName';
import CreativeBulkUploadViewModel, {
    IBulkCreative,
} from '@/components/domain/creatives/shared/bulk/CreativeBulkUploadViewModel';
import { headers } from '@/components/domain/creatives/video/bulk/headers';
import { ITableHeaders } from '@/components/domain/creatives/shared/bulk/ITableHeaders';
import IBulkVideoCreativeRow from '@/components/domain/creatives/video/bulk/IBulkVideoCreativeRow';
import ExcelCreativeExtractor from '@/shared/excel/ExcelCreativeExtractor';
import IRawVideoExcelRow from '@/components/domain/creatives/video/bulk/IRawVideoExcelRow';

interface IVideoBulkCreative extends IBulkCreative {
    vastUrl: string;
    isSkippable: boolean;
    isVastUrlParsed: boolean;
}

export default class VideoCreativeBulkUploadViewModel extends CreativeBulkUploadViewModel {
    public businessEntity: IBusinessEntity;

    public advertiserUrl: string;

    public shouldDisableTemplateNaming: boolean;

    public uploadErrors = [];

    public readonly creatives: IVideoBulkCreative[] = [];

    private readonly excelCreativeExtractor: ExcelCreativeExtractor<IRawVideoExcelRow, IBulkVideoCreativeRow>;

    constructor(excelCreativeExtractor: ExcelCreativeExtractor<IRawVideoExcelRow, IBulkVideoCreativeRow>) {
        super();
        this.excelCreativeExtractor = excelCreativeExtractor;
    }

    hasInitialProperties(): boolean {
        return Boolean(this.businessEntity && this.advertiserUrl);
    }

    getTemplateNamingOptions(): IFilenameTemplateOptions {
        return {
            template: '${date}_${advertiserName}_${filename}',
            getTemplateParametersFromResponseOrSpreadsheetRow: (row: IBulkVideoCreativeRow) => ({
                filename: row.name,
                advertiserName: this.businessEntity.name,
                date: format(new Date(), 'yyMMdd'),
            }),
            forceDefaultTemplate: this.shouldDisableTemplateNaming,
        };
    }

    getTableHeaders(): ITableHeaders<VideoCreativeKeysToFilterBy>[] {
        return headers();
    }

    async fileAdded(file): Promise<void> {
        const fileContent = await file.arrayBuffer();

        const extractedExcelData = await this.excelCreativeExtractor.extract(fileContent);

        this.uploadErrors = extractedExcelData.errors;
        if (!this.uploadErrors.length) {
            this.addCreatives(extractedExcelData.rows, file);
        }
    }

    private addCreatives(filteredRows: IBulkVideoCreativeRow[], file: unknown): void {
        filteredRows.forEach((row) => {
            const runtime = this.getRuntime(row);

            const creative = {
                originName: row.name,
                name: formatCreativeName(
                    this.getTemplateNamingOptions().getTemplateParametersFromResponseOrSpreadsheetRow(row),
                    this.getTemplateNamingOptions().template,
                    this.getTemplateNamingOptions().forceDefaultTemplate,
                ),
                vastUrl: row.vastUrl,
                isSkippable: row.skippable,
                hasClickTracking: true,
                isVastUrlParsed: true,
                runtime,
                businessEntity: { id: this.businessEntity.id },
                uniqueId: uuidv1(),
                advertiserUrl: this.advertiserUrl,
                file,
                open: false,
            };
            this.creatives.push(creative);
        });
    }

    private getRuntime(creativeRow: Partial<IBulkVideoCreativeRow>): IRuntimeCreate {
        if (!creativeRow.expirationStart || !creativeRow.expirationEnd || !creativeRow.expirationTimezone) {
            return null;
        }
        return {
            startDate: creativeRow.expirationStart,
            endDate: creativeRow.expirationEnd,
            timezone: creativeRow.expirationTimezone,
        };
    }
}
