<template>
    <BasicCreative ref="basic" v-model="creative" v-bind="$attrs" v-on="$listeners">
        <template #creative-specific-fields>
            <SrHeadline level="3" weight="bold" class="mb-6"> Measure Viewability </SrHeadline>
            <SrCheckbox v-model="creative.hasMeasureViewability" label="Apply Measure Viewability" />
        </template>
    </BasicCreative>
</template>

<script>
import { SrCheckbox, SrHeadline } from '@ads/design-system';
import BasicCreative from '@/components/domain/creatives/shared/basics/BasicCreative';

export default {
    name: 'DisplayCreativeBasics',
    components: {
        SrHeadline,
        SrCheckbox,
        BasicCreative,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            creative: this.value,
        };
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                this.creative = value;
            },
        },
        creative: {
            deep: true,
            handler(creative) {
                this.$emit('input', creative);
            },
        },
    },
};
</script>
