<script>
import CreativeFormTemplate from '@/components/domain/creatives/CreativeFormTemplate';

export default {
    name: 'DisplayCreativeFormTemplate',
    extends: CreativeFormTemplate,
    computed: {
        components() {
            if (!this.isMounted) {
                return [];
            }
            return [
                this.$refs['basic-creative'].$refs.basic,
                this.$refs['asset-creative'],
                this.$refs['categorization-creative'],
            ];
        },
    },
};
</script>
