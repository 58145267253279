import { Html5CreativeKeysToFilterBy, IBusinessEntity, IUploadResponse } from 'api-contracts';
import { format } from 'date-fns';
import CreativeBulkUploadViewModel, {
    IBulkCreative,
} from '@/components/domain/creatives/shared/bulk/CreativeBulkUploadViewModel';
import { ITableHeaders } from '@/components/domain/creatives/shared/bulk/ITableHeaders';
import getFilenameWithoutExtension from '@/components/domain/creatives/shared/bulk/getFilenameWithoutExtension';
import { headers } from '@/components/domain/creatives/html5/bulk/headers';
import { IFilenameTemplateOptions } from '@/components/domain/creatives/shared/bulk/formatCreativeName';

interface IHtml5BulkCreative extends IBulkCreative {
    clickUrl: string;
}

export default class Html5CreativeBulkUploadViewModel extends CreativeBulkUploadViewModel {
    public businessEntity: IBusinessEntity;

    public advertiserUrl: string;

    public clickUrl: string;

    public isDooh: boolean;

    public shouldDisableTemplateNaming: boolean;

    public uploadErrors = [];

    public readonly creatives: IHtml5BulkCreative[] = [];

    hasInitialProperties(): boolean {
        return Boolean(this.businessEntity && this.advertiserUrl && this.clickUrl);
    }

    getTemplateNamingOptions(): IFilenameTemplateOptions {
        return {
            template: '${date}_${advertiserName}_${filename}',
            getTemplateParametersFromResponseOrSpreadsheetRow: (row: IUploadResponse) => ({
                filename: getFilenameWithoutExtension(row.filename),
                advertiserName: this.businessEntity.name,
                date: format(new Date(), 'yyMMdd'),
            }),
            forceDefaultTemplate: this.shouldDisableTemplateNaming,
        };
    }

    getTableHeaders(): ITableHeaders<Html5CreativeKeysToFilterBy>[] {
        return headers();
    }
}
