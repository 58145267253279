<template>
    <VRow>
        <VCol cols="12">
            <SrDropzoneUploadFileList v-if="fileList.length > 0" :value="fileList" @removeFile="removeFile" />
        </VCol>
    </VRow>
</template>

<script>
import { SrDropzoneUploadFileList } from '@ads/design-system';

export default {
    name: 'ImageAssetsPreview',
    components: {
        SrDropzoneUploadFileList,
    },
    props: {
        imageAssets: {
            type: Array,
            required: true,
        },
        onRemove: {
            type: Function,
            required: true,
        },
    },
    computed: {
        fileList() {
            return this.imageAssets.map((imageAsset) => ({
                name: imageAsset.secureUrl,
                size: `${imageAsset.dimensions.width}x${imageAsset.dimensions.height}`,
                secureUrl: imageAsset.secureUrl,
            }));
        },
    },
    methods: {
        removeFile(file, index) {
            this.onRemove(index);
        },
    },
};
</script>

<style lang="scss" scoped>
.asset-img {
    width: 50px;
    height: 50px;

    object-fit: cover;
}
</style>
