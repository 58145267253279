<template>
    <ActionModal v-if="isOpen" :width="width" description="HTML Macro Merge-Diff" title="Edit Selected Creative" @close="close">
        <template #content>
            <MergeView v-model="template" :options="options" :replacements="replacements" />
        </template>
        <template #cancel>
            <SrButton class="close" @click="close"> Close </SrButton>
        </template>
        <template #actions />
    </ActionModal>
</template>

<script>
import { SrButton } from '@ads/design-system';
import ActionModal from '@/components/domain/creatives/shared/ActionModal';
import MergeView from '@/components/domain/creatives/html/HtmlCodeDiff/MergeView';
import StringReplacementExecutor from '@/components/domain/creatives/html/string-replacement/StringReplacementExecutor';

export default {
    name: 'MergeViewModal',
    components: {
        ActionModal,
        MergeView,
        SrButton,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        replacements: {
            type: Array,
            required: true,
            default: () => [],
        },
        templateString: {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            default: 1400,
        },
    },
    data() {
        return {
            template: this.templateString,
            replacementExecutor: new StringReplacementExecutor(),
        };
    },
    computed: {
        options() {
            return {
                value: this.templateString,
                origRight: this.replacementExecutor.executeReplacements(this.templateString, this.replacements),
                readOnly: true,
            };
        },
    },
    watch: {
        templateString(value) {
            this.template = value;
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
