<template>
    <VForm ref="form" v-model="formIsValid" :disabled="form.disabled" class="bulk-video-upload">
        <VRow>
            <VCol class="align-items-end">
                <ExcelTemplateDownloadButton :download-url="templateDownloadUrl" />
            </VCol>
        </VRow>
        <VRow>
            <VCol lg="5" md="6" sm="12" xlg="4">
                <VInput
                    v-model="viewModel.creatives"
                    :rules="viewModel.getInputRules().creativesWithoutDimensions"
                    class="hidden-input"
                    type="hidden"
                />
                <VInput
                    :rules="viewModel.getInputRules().duplicateName"
                    :value="viewModel.hasDuplicatedCreativeNames()"
                    class="hidden-input"
                    type="hidden"
                />
                <SrHeadline class="mb-6" level="3" weight="bold"> Select Advertiser </SrHeadline>
                <SrSelect
                    v-model="viewModel.businessEntity"
                    :items="businessEntities"
                    :rules="viewModel.getInputRules().businessEntity"
                    item-text="name"
                    item-value="id"
                    label="Advertiser"
                    required
                    return-object
                    searchable
                    sort-order="ASC"
                />
            </VCol>
            <VCol lg="5" md="6" sm="12" xlg="4">
                <SrURLInput
                    v-model="viewModel.advertiserUrl"
                    :rules="viewModel.getInputRules().url"
                    class="advertiser-url mt-11"
                    label="Advertiser URL"
                    maxlength="2048"
                    required
                    secure-url
                />
            </VCol>
        </VRow>
        <VRow>
            <VCol lg="5" md="6" sm="12" xlg="4">
                <SrCheckbox v-model="viewModel.shouldDisableTemplateNaming" label="Disable Template Naming" />
            </VCol>
        </VRow>
        <VRow v-if="viewModel.hasInitialProperties()">
            <VCol>
                <BulkCreativeDropzone
                    ref="uploadDropzone"
                    v-model="viewModel.creatives"
                    :business-entity="viewModel.businessEntity"
                    :dropzone-options="dropzoneOptions"
                    :name-template-options="viewModel.getTemplateNamingOptions()"
                    disabled
                    @errorMessage="errorMessage"
                    @fileAdded="fileAdded"
                    @fileRemoved="removeAllCreatives"
                />
            </VCol>
        </VRow>
        <VRow v-if="viewModel.hasInitialProperties()">
            <VCol>
                <VideoBulkCreativeTable
                    v-model="viewModel.creatives"
                    :advertiser-name="viewModel.businessEntity.name"
                    :disable-template-naming="viewModel.shouldDisableTemplateNaming"
                    :headers="viewModel.getTableHeaders()"
                    @deleteItem="removeCreative"
                    @editItem="editItem"
                    @errorMessage="errorMessage"
                />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrCheckbox, SrHeadline, SrSelect, SrURLInput } from '@ads/design-system';
import VideoBulkCreativeTable from '@/components/domain/creatives/video/bulk/VideoBulkCreativeTable';
import BulkCreativeDropzone from '@/components/domain/creatives/shared/bulk/bulk-creative-dropzone/BulkCreativeDropzone';
import UploadOptions from '@/services/upload/UploadOptions';
import ExcelTemplateDownloadButton from '@/components/domain/creatives/shared/bulk/ExcelTemplateDownloadButton';
import { APP_URL } from '@/config';
import VideoCreativeBulkUploadViewModel from '@/components/domain/creatives/video/bulk/VideoCreativeBulkUploadViewModel';
import CreativeBulkUploadMixin from '@/components/domain/creatives/shared/bulk/CreativeBulkUploadMixin';
import ExcelFileUpload from '@/components/domain/creatives/shared/bulk/ExcelFileUpload';
import createVideoExcelCreativeExtractor from '@/components/domain/creatives/video/bulk/createVideoExcelCreativeExtractor';

export default {
    name: 'VideoCreativeBulkUpload',
    components: {
        SrHeadline,
        SrSelect,
        SrURLInput,
        BulkCreativeDropzone,
        VideoBulkCreativeTable,
        SrCheckbox,
        ExcelTemplateDownloadButton,
    },
    mixins: [CreativeBulkUploadMixin, ExcelFileUpload],
    data() {
        const templateDownloadUrl = new URL('/downloads/Video-bulk-upload-template.xlsx', APP_URL).href;
        return {
            viewModel: new VideoCreativeBulkUploadViewModel(createVideoExcelCreativeExtractor()),
            dropzoneOptions: UploadOptions.createDropzoneOptions('', {
                acceptedFiles: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                maxFiles: 1,
                uploadMultiple: false,
                parallelUploads: 1,
                autoQueue: false,
                autoProcessQueue: false,
            }),
            templateDownloadUrl,
        };
    },
};
</script>

<style lang="scss">
.bulk-video-upload {
    .dz-details {
        text-align: center !important;
    }

    .dz-filename {
        display: block !important;
        color: #3367d6 !important;
    }

    .dz-progress {
        display: none !important;
    }
}
</style>
