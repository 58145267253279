import { utcToZonedTime } from 'date-fns-tz';

function formatDate(date: string, timezone = 'UTC', locale = Intl.DateTimeFormat().resolvedOptions().locale): string {
    if (!date) {
        return '';
    }
    const dateObj = utcToZonedTime(date, timezone);
    return dateObj.toLocaleString(locale);
}

export default formatDate;
