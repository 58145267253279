<template>
    <ActionModal
        v-if="isOpen"
        :description="description"
        :html-description="true"
        :error="error"
        :title="title"
        :is-loading="isLoading"
        v-bind="$attrs"
        @close="close"
    >
        <template #actions>
            <SrButton :loading="isLoading" class="confirm" type="secondary" @click="done"> Yes </SrButton>
        </template>
    </ActionModal>
</template>

<script>
import { SrButton } from '@ads/design-system';
import ActionModal from '@/components/domain/creatives/shared/ActionModal';

export default {
    name: 'ConfirmModal',
    components: {
        ActionModal,
        SrButton,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            error: null,
        };
    },
    methods: {
        done() {
            this.error = null;
            this.$emit('done');
        },
        close() {
            this.error = null;
            this.$emit('close');
        },
    },
};
</script>
