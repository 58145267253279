<template>
    <VForm ref="form" v-model="formIsValid" :disabled="form.disabled">
        <VRow>
            <VCol v-if="selectableCategories.length && selectableDeclarableAttributes.length" xlg="4" lg="5" md="6" sm="12">
                <SrHeadline level="3" weight="bold" class="mb-6"> Basics </SrHeadline>
                <SrSelect
                    v-model="selectedCategories"
                    disabled
                    class="creative-category"
                    required
                    multiple
                    label="Creative Categories"
                    searchable
                    :items="selectableCategories"
                    return-object
                />
                <SrHeadline level="3" weight="bold" class="mb-6"> Google </SrHeadline>
                <SrCheckbox
                    v-model="formData.isServedAgainstGoogle"
                    disabled
                    class="is-against-google"
                    label="This creative will be served against Google"
                />
                <SrSelect
                    v-model="selectedDeclarableAttributes"
                    disabled
                    class="creative-declarable-attributes"
                    required
                    multiple
                    label="Google Creative Attributes"
                    searchable
                    :items="selectableDeclarableAttributes"
                    return-object
                />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrCheckbox, SrHeadline, SrSelect } from '@ads/design-system';
import FormBasics from '@/components/domain/creatives/FormBasics';
import { categorizationService } from '@/services/categorization/CategorizationService';
import { idModelTransformer } from '@/components/shared/IdModelTransformer';
import { selectableCategoriesTransformer } from '@/components/domain/creatives/shared/categorization/SelectableCategoriesTransformer';
import { selectableDeclarableAttributesTransformer } from '@/components/domain/creatives/shared/categorization/SelectableDeclarableAttributesTransformer';

export default {
    name: 'CategorizationCreative',
    components: {
        SrCheckbox,
        SrHeadline,
        SrSelect,
    },
    mixins: [FormBasics],
    props: {
        preSelectedItems: {
            type: Array,
        },
    },
    data() {
        return {
            categorizationService,
            selectableCategoriesTransformer,
            selectableDeclarableAttributesTransformer,
            idModelTransformer,
            selectableCategories: [],
            selectableDeclarableAttributes: [],
        };
    },
    computed: {
        selectedCategories: {
            get() {
                return this.selectableCategoriesTransformer.transform(this.formData.categories);
            },
            set(selectedCategories) {
                this.formData.categories = this.idModelTransformer.transformSelectables(selectedCategories);
            },
        },
        selectedDeclarableAttributes: {
            get() {
                return this.selectableDeclarableAttributesTransformer.transform(this.formData.declarableAttributes);
            },
            set(selectedDeclarableAttributes) {
                this.formData.declarableAttributes = this.idModelTransformer.transformSelectables(selectedDeclarableAttributes);
            },
        },
    },
    watch: {
        preSelectedItems: {
            deep: true,
            immediate: true,
            handler(value) {
                if (!value) {
                    return;
                }
                this.formData.declarableAttributes = value;
            },
        },
    },
    async created() {
        this.loading = true;
        this.selectableCategories = await this.getCategories();
        this.selectableDeclarableAttributes = await this.getDeclarableAttributes();
        this.loading = false;
    },
    methods: {
        async getCategories() {
            const categories = await this.categorizationService.listCategories();
            return this.selectableCategoriesTransformer.transform(categories);
        },
        async getDeclarableAttributes() {
            const declarableAttributes = await this.categorizationService.listDeclarableAttributes();
            return this.selectableDeclarableAttributesTransformer.transform(declarableAttributes);
        },
    },
};
</script>
