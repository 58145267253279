<template>
    <SrSelect
        :value="modelValue"
        :items="vastApiFrameworks"
        item-text="title"
        item-value="name"
        label="Api Frameworks"
        multiple
        return-object
        searchable
        :clearable="true"
        :loading="isLoadingVastApiFrameworks"
        @change="emitInput"
    />
</template>
<script>
import { SrSelect } from '@ads/design-system';

export default {
    name: 'VastApiFrameworksSelect',
    components: { SrSelect },
    model: {
        prop: 'modelValue',
        event: 'input',
    },
    props: {
        modelValue: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isLoadingVastApiFrameworks: true,
        };
    },
    computed: {
        vastApiFrameworks() {
            return this.$store.state.vastApiFrameworks;
        },
    },
    async created() {
        await this.fetchVastApiFrameworks();
    },
    methods: {
        async fetchVastApiFrameworks() {
            try {
                await this.$store.dispatch('fetchVastApiFrameworks');
            } catch (_) {
                this.emitError();
            } finally {
                this.isLoadingVastApiFrameworks = false;
            }
        },
        emitInput(value) {
            this.$emit('input', value);
        },
        emitError() {
            this.$emit('error', 'Could not load VAST API Frameworks. Please reload the page or contact support.');
        },
    },
};
</script>
