import { IDisplayCreative } from 'api-contracts';
import ErrorRuleFactory from '@/components/domain/creatives/ErrorRuleFactory';

export default class DynamicCreativeErrorRuleFactory extends ErrorRuleFactory {
    protected mappings = {
        'name, business_entity_id': 'name',
        secureUrl: 'secureUrl',
    };

    protected name = (nameBusinessEntity: string, creative: IDisplayCreative) => {
        const [name, businessEntityId] = nameBusinessEntity.split(',');
        return [
            (v) =>
                v !== name ||
                (v === name && Number(businessEntityId) !== creative.businessEntity.id) ||
                `'${name}' already exists for this advertiser.`,
        ];
    };

    protected secureUrl = (url: string) => [(v) => v !== url || 'The given url does not provide a valid image format.'];
}

export const dynamicCreativeErrorRuleFactory = new DynamicCreativeErrorRuleFactory();
