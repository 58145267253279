import { Replacement } from '@/components/domain/creatives/html/string-replacement/Replacement';

export type Macro = {
    id: number;
    name: string;
    replacements: Replacement[];
};

export default class AvailableMacros {
    private static readonly defaultMacro: Macro = {
        id: 0,
        name: 'Default',
        replacements: [],
    };

    private static readonly specialMacros: Macro[] = [
        {
            id: 1,
            name: 'Flashtalking Click',
            replacements: [
                {
                    origin: 'window.ftClick = "";',
                    replacement: 'window.ftClick="${CLICK_URL_ENC_ENC}";',
                },
            ],
        },
        {
            id: 2,
            name: 'Google DoubleClick Campaign Manager Click',
            replacements: [
                {
                    origin: "data-dcm-app-id=''>",
                    replacement: "data-dcm-click-tracker='${CLICK_URL}'     data-dcm-app-id=''>",
                },
            ],
        },
        {
            id: 3,
            name: 'Adform Click',
            replacements: [
                {
                    origin: 'http:',
                    replacement: 'https:',
                },
                {
                    origin: '<a href="',
                    replacement: '<a href="${CLICK_URL}',
                },
                {
                    origin: 'ord=[timestamp]',
                    replacement: 'ord=${CACHE_BUSTER}',
                },
                {
                    origin: '"></script>',
                    replacement: ';click=${CLICK_URL}"></script>',
                },
            ],
        },
        {
            id: 4,
            name: 'AppsFlyer Impression',
            replacements: [
                {
                    origin: '&af_siteid=%7bpublisherid%7d&af_sub_siteid=${page_domain}',
                    replacement:
                        '&af_ua=${UA_ENC}&af_ip=${IP_ADDRESS}&af_ad_id=${CREATIVE_ID}' +
                        '&af_adset_id=${LINE_ITEM_ID}&af_c_id=${CAMPAIGN_ID}&af_siteid=${SITE_ID}&af_sub_siteid=${APP_BUNDLE_ID}',
                },
                {
                    origin: '&clickid=${pc_conv_context}',
                    replacement: '&clickid=${PV_CONV_CONTEXT}',
                },
            ],
        },
        {
            id: 5,
            name: 'AppsFlyer Click',
            replacements: [
                {
                    origin: '&af_siteid=%7bpublisherid%7d&af_sub_siteid=${page_domain}',
                    replacement:
                        '&af_ua=${UA_ENC}&af_ip=${IP_ADDRESS}&af_ad_id=${CREATIVE_ID}' +
                        '&af_adset_id=${LINE_ITEM_ID}&af_c_id=${CAMPAIGN_ID}&af_siteid=${SITE_ID}&af_sub_siteid=${APP_BUNDLE_ID}',
                },
                {
                    origin: '&clickid=${pc_conv_context}',
                    replacement: '&clickid=${PC_CONV_CONTEXT}',
                },
            ],
        },
    ];

    static getAll(): Macro[] {
        return [this.defaultMacro, ...this.specialMacros];
    }

    static getDefault(): Macro {
        return this.defaultMacro;
    }
}
