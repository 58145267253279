<template>
    <SrURLInput
        :value="value"
        :rules="rules"
        class="click-url"
        label="Click URL"
        maxlength="2048"
        required
        secure-url
        @input="onValueChange"
    />
</template>

<script>
import { SrURLInput } from '@ads/design-system';

export default {
    name: 'ClickUrl',
    components: {
        SrURLInput,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            rules: [
                (v) => (v && !!v.trim()) || 'URL is required',
                (v) => !v.includes('…') || "Invalid character '…' found. Was the URL shortened?",
            ],
        };
    },
    methods: {
        onValueChange(newValue) {
            this.$emit('input', newValue);
        },
    },
};
</script>
