import { IAudioCreative, IBusinessEntity, IMimeType, IVastApiFramework } from 'api-contracts';
import MediaAssetService from '@/components/shared/services/MediaAssetService';
import VastVersion from 'api-contracts/vast/version/VastVersion';
import MediaAssetViewModel from '@/components/domain/creatives/shared/view-models/MediaAssetViewModel';
import { ValidationResult, ValidationRuleFn } from '@/shared/validation/types';

type AudioAssetsRules = {
    url: ValidationRuleFn[];
    duration: ValidationRuleFn[];
    bitrate: ValidationRuleFn[];
};

export default class AudioAssetsViewModel extends MediaAssetViewModel {
    private audioCreative: IAudioCreative;

    constructor(creative: IAudioCreative) {
        super();
        this.audioCreative = creative;
    }

    public reinitialize(creative: IAudioCreative): void {
        this.audioCreative = creative;
    }

    public shouldRenderForm(): boolean {
        const maybeBusinessEntity = this.audioCreative.businessEntity as IBusinessEntity;
        return Boolean(maybeBusinessEntity?.name && maybeBusinessEntity?.id);
    }

    get rules(): AudioAssetsRules {
        return {
            url: [(v): ValidationResult => (v && !!v.trim()) || 'URL is required'],
            duration: [
                (v): ValidationResult => v != null || 'Audio duration is required',
                (v): ValidationResult =>
                    (v != null && Number.isInteger(v)) || 'Audio duration should be a whole number (seconds)',
                (v): ValidationResult => (v != null && v > 0) || 'Audio duration should be greater than 0 (seconds)',
            ],
            bitrate: [
                (v): ValidationResult => v != null || 'Bitrate is required',
                (v): ValidationResult => (v != null && Number.isInteger(v)) || 'Bitrate should be a whole number',
                (v): ValidationResult => (v != null && v > 0) || 'Bitrate should be greater than 0',
            ],
        };
    }

    get advertiserUrl(): string {
        return this.audioCreative.advertiserUrl;
    }

    set advertiserUrl(value: string) {
        this.audioCreative.advertiserUrl = value;
    }

    get vastApiFrameworks(): IVastApiFramework[] | { name: string }[] {
        return this.audioCreative.vastApiFrameworks;
    }

    set vastApiFrameworks(value) {
        this.audioCreative.vastApiFrameworks = value;
    }

    get mimeTypes(): IMimeType[] | { mimeType: string }[] {
        return this.audioCreative.vastMimeTypes;
    }

    set mimeTypes(value) {
        this.audioCreative.vastMimeTypes = value;
    }

    get vastVersion(): VastVersion {
        return this.audioCreative.vastVersion;
    }

    set vastVersion(value) {
        this.audioCreative.vastVersion = value;
    }

    get isVastUrlParsed(): boolean {
        return this.audioCreative.isVastUrlParsed;
    }

    set isVastUrlParsed(value) {
        this.audioCreative.isVastUrlParsed = value;
    }

    get vastUrl(): string {
        return this.audioCreative.vastUrl;
    }

    set vastUrl(value) {
        this.audioCreative.vastUrl = value;
    }

    get duration(): number {
        return this.audioCreative.vastDuration;
    }

    set duration(value) {
        this.audioCreative.vastDuration = Number(value);
    }

    set bitrate(value: number) {
        this.audioCreative.vastBitrate = Number(value);
    }

    get bitrate(): number {
        return this.audioCreative.vastBitrate;
    }
}
