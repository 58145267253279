<template>
    <VForm ref="form" v-model="formIsValid" :disabled="form.disabled">
        <VInput v-model="hasAssignedCreatives" :rules="rules.hasAssignedCreatives" class="hidden-input" type="hidden" />
        <VRow class="mt-5">
            <VCol lg="6" md="12">
                <SrHeadline class="mb-6" level="3" weight="bold">
                    Select Campaigns
                    <SrInfoBox> Select a campaign where you would like to assign uploaded creatives </SrInfoBox>
                </SrHeadline>
            </VCol>
            <VCol lg="6" md="12">
                <SrHeadline class="mb-6" level="3" weight="bold">
                    Select Line Items To Assign
                    <SrInfoBox left> Select line items to which you would like to assign uploaded creatives </SrInfoBox>
                </SrHeadline>
            </VCol>
        </VRow>
        <VRow class="line-item-selection">
            <VCol class="d-flex pb-0 ml-0" lg="6" md="12">
                <VCol class="pl-0 pb-0" cols="4">
                    <SrSelect v-model="currentCampaignStatus" :items="campaignStatus" label="Status" required />
                </VCol>

                <VCol class="px-0" cols="8">
                    <SrSelect
                        v-model="currentCampaign"
                        :items="campaigns"
                        :disabled="!currentCampaignStatus"
                        searchable
                        searchable-property="name"
                        item-text="name"
                        item-value="id"
                        return-object
                        label="Campaign"
                        required
                    />
                </VCol>
            </VCol>
            <VCol class="d-flex pb-0ml-0" lg="6" md="12">
                <VCol class="pl-0 pb-0" cols="4">
                    <SrSelect
                        v-model="currentLineItemStatus"
                        :items="lineItemStatus"
                        :disabled="!currentCampaign"
                        label="Status"
                        required
                    />
                </VCol>
                <VCol class="px-0" cols="8">
                    <SrSelect
                        v-model="currentLineItems"
                        :items="lineItems"
                        :disabled="!currentCampaign || !currentLineItemStatus"
                        searchable
                        searchable-property="name"
                        label="Line Items"
                        item-text="name"
                        item-value="id"
                        multiple
                        return-object
                        required
                    >
                        <template #selection="{ item, index }">
                            <template v-if="index === 0">
                                {{ item.name }}
                            </template>
                            <span v-if="index === 1" class="grey--text caption"> , + {{ currentLineItems.length - 1 }} </span>
                        </template>
                    </SrSelect>
                </VCol>
            </VCol>
        </VRow>

        <VDivider />
        <VRow class="mt-5">
            <VCol lg="6" md="12">
                <CreativeSelection
                    ref="creatives-section"
                    :has-quick-search="true"
                    :headers="creativeSelectionHeaders"
                    :items="unselectedCreatives"
                    :quick-search.sync="quickSearch"
                    :creative-service="creativeService"
                    action="add"
                    @filteredItems="filteredItems"
                    @add="add"
                    @addAll="addAllUnselected"
                />
            </VCol>
            <VCol lg="6" md="12">
                <CreativeSelection
                    :headers="creativeSelectionHeaders"
                    :items="selectedCreatives"
                    :creative-service="creativeService"
                    action="remove"
                    title="Selected Creatives"
                    @remove="remove"
                    @removeAll="removeAll"
                />
            </VCol>
        </VRow>
        <ConfirmModal
            :description="unassignedCreativeDescription"
            :is-open="showUnassignedCreativesModal"
            :html-description="true"
            :title="unassignedCreativeTitle"
            @close="closeUnassignedCreativesModal"
            @done="unassignedCreativesAccepted"
        />
        <ConfirmModal
            :description="noAssignmentDescription"
            :is-open="showNoAssignmentModal"
            :html-description="true"
            :title="noAssignmentTitle"
            @close="closeNoAssignmentModal"
            @done="noAssignmentAccepted"
        />
    </VForm>
</template>

<script>
import { SrHeadline, SrInfoBox, SrSelect } from '@ads/design-system';
import FormBasics from '@/components/domain/creatives/FormBasics';
import { creativeSelectionHeaders } from '@/components/domain/creatives/shared/bulk/assignment/creativeSelectionHeaders';
import CreativeService from '@/services/CreativeService';
import CreativeSelection from '@/components/domain/creatives/shared/bulk/assignment/CreativeSelection';
import { programmaticCampaignsService } from '@/services/programmatic-campaigns/ProgrammaticCampaignsService';
import ConfirmModal from '@/components/domain/creatives/shared/ConfirmModal';

export default {
    name: 'CreativeBulkAssign',
    components: {
        ConfirmModal,
        CreativeSelection,
        SrHeadline,
        SrInfoBox,
        SrSelect,
    },
    mixins: [FormBasics],
    props: {
        creativeService: {
            type: CreativeService,
            required: true,
        },
        businessEntity: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            unassignedCreativeTitle: 'Not all creatives assigned',
            unassignedCreativeDescription:
                'Not all creatives have been assigned to line items.' +
                ' If you continue now you can assign creatives later' +
                ' from the overview screen.<br/><br/>' +
                ' Are you sure you want to continue?',
            noAssignmentTitle: 'No creatives Assigned',
            noAssignmentDescription:
                "Creatives haven't been assigned to line items." +
                ' If you exit now you can assign creatives later from the' +
                ' overview screen.<br/><br/>' +
                ' Are you sure you want to Exit this Step?',
            showNoAssignmentModal: false,
            showUnassignedCreativesModal: false,
            rules: {
                hasAssignedCreatives: [(v) => v || 'Has no assigned creatives.'],
            },
            selectedCreatives: [],
            quickSearch: '',
            currentCampaignStatus: '',
            campaignStatus: [
                { label: 'Active', value: 'Active' },
                { label: 'Inactive', value: 'Inactive' },
            ],
            currentLineItemStatus: '',
            lineItemStatus: [
                { label: 'Active', value: 'Active' },
                { label: 'Inactive', value: 'Inactive' },
                { label: 'Archive', value: 'Archive' },
            ],
            filteredCreatives: [],
            lineItems: [],
            lineItemCache: {},
            currentLineItems: [],
            campaigns: [],
            campaignCache: {},
            currentCampaign: null,
            submitCallback: null,
            noAssignmentCallback: null,
            creatives: this.value,
            creativeSelectionHeaders,
            programmaticCampaignsService,
        };
    },
    computed: {
        hasUnassignedCreatives() {
            return this.unselectedCreatives.length > 0;
        },
        hasAssignedCreatives() {
            return this.selectedCreatives.length > 0;
        },
        unselectedCreatives() {
            return this.creatives.filter(
                (creative) => !this.selectedCreatives.find((selectedCreative) => selectedCreative === creative),
            );
        },
    },
    watch: {
        creatives: {
            deep: true,
            handler(value) {
                this.$emit('input', value);
            },
        },
        async currentCampaignStatus(value) {
            if (!this.campaignCache[value]) {
                this.loading = true;
                this.campaignCache[value] = await this.programmaticCampaignsService.listCampaigns({
                    status: value,
                    businessEntityId: this.businessEntity.id,
                });
                this.loading = false;
            }
            this.campaigns = this.campaignCache[value];
        },
        currentCampaign: {
            deep: true,
            async handler() {
                if (!this.currentLineItemStatus) {
                    return;
                }

                await this.fetchLineItemsByStatus(this.currentLineItemStatus);
            },
        },
        async currentLineItemStatus(value) {
            await this.fetchLineItemsByStatus(value);
        },
    },
    methods: {
        async submit(callback) {
            try {
                const creativeDspIds = this.selectedCreatives.map((creative) => ({ dspId: creative.dspId }));
                await Promise.all(
                    this.currentLineItems.map(async (lineItem) =>
                        this.programmaticCampaignsService.assignCreativesToLineItem({
                            lineItemId: lineItem.id,
                            creativeDspIds,
                        }),
                    ),
                );
                this.$emit('closeDone');
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
                this.$emit('errorMessage', 'Assignment of creatives failed.');
            } finally {
                callback();
            }
        },
        filteredItems(value) {
            this.filteredCreatives = value;
        },
        addAllUnselected() {
            this.unselectedCreatives.forEach((creative) => {
                this.selectedCreatives.push(creative);
            });
        },
        removeAll() {
            this.selectedCreatives.splice(0, this.selectedCreatives.length);
        },
        add({ id }) {
            const addedCreative = this.creatives.find((creative) => creative.id === id);
            this.selectedCreatives.push(addedCreative);
        },
        remove({ id }) {
            const removedIndex = this.selectedCreatives.findIndex((creative) => creative.id === id);
            this.selectedCreatives.splice(removedIndex, 1);
        },
        triggerNoAssignmentModal(done) {
            this.noAssignmentCallback = done;
            this.showNoAssignmentModal = true;
        },
        noAssignmentAccepted() {
            this.closeNoAssignmentModal();
            this.noAssignmentCallback();
        },
        closeNoAssignmentModal() {
            this.showNoAssignmentModal = false;
        },
        async triggerSubmit(done) {
            if (!this.hasUnassignedCreatives) {
                await this.submit(done);
                return;
            }
            this.submitCallback = done;
            this.showUnassignedCreativesModal = true;
        },
        async unassignedCreativesAccepted() {
            this.closeUnassignedCreativesModal();
            await this.submit(this.submitCallback);
        },
        closeUnassignedCreativesModal() {
            this.showUnassignedCreativesModal = false;
            this.submitCallback();
        },
        async fetchLineItemsByStatus(status) {
            if (!this.lineItemCache[`${this.currentCampaign.id}-${status}`]) {
                this.loading = true;
                this.lineItemCache[`${this.currentCampaign.id}-${status}`] =
                    await this.programmaticCampaignsService.listLineItems({
                        status,
                        campaignId: this.currentCampaign.id,
                    });
                this.loading = false;
            }
            this.lineItems = this.lineItemCache[`${this.currentCampaign.id}-${status}`];
        },
    },
};
</script>

<style lang="scss" scoped>
.line-item-selection {
    margin-top: -30px;
}
</style>
