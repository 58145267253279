<template>
    <SrSelect
        :value="modelValue"
        :disabled="disabled"
        :items="vastEventTypes"
        :required="required"
        label="Type"
        no-data-text="No video event types available"
        @change="onSelectChange"
    />
</template>

<script>
import { SrSelect } from '@ads/design-system';

export default {
    name: 'VastEventTypeSelect',
    components: {
        SrSelect,
    },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },
    props: {
        modelValue: {
            type: String,
            required: false,
        },
        vastEventTypes: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onSelectChange(value) {
            this.$emit('update:modelValue', value);
        },
    },
};
</script>
