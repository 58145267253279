<template>
    <SrMenu v-model="isOpened" offset-y right min-width="100" :disabled="disabled">
        <template #activator="{ on, attrs }">
            <SrButton color="transparent" elevation="0" v-bind="attrs" class="pr-1 pl-3" :disabled="disabled" v-on="on">
                <span class="button-text">Table Actions</span>
                <SrIcon :active="isOpened" icon="more" size="xs" class="ml-2" />
            </SrButton>
        </template>
        <SrList dense>
            <slot />
        </SrList>
    </SrMenu>
</template>

<script>
import { SrButton, SrIcon, SrMenu, SrList } from '@ads/design-system';

export default {
    components: { SrButton, SrIcon, SrMenu, SrList },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpened: false,
        };
    },
};
</script>

<style scoped lang="scss">
@import '~@ads/design-system/src/scss/variables';

.button-text {
    font-weight: normal;
    color: $brand-blue;
    font-size: 14px;
}
</style>
