import { HtmlCreativeKeysToFilterBy, IBusinessEntity } from 'api-contracts';
import { format } from 'date-fns';
import { v1 as uuidv1 } from 'uuid';
import formatCreativeName, { IFilenameTemplateOptions } from '@/components/domain/creatives/shared/bulk/formatCreativeName';
import CreativeBulkUploadViewModel, {
    IBulkCreative,
} from '@/components/domain/creatives/shared/bulk/CreativeBulkUploadViewModel';
import { ITableHeaders } from '@/components/domain/creatives/shared/bulk/ITableHeaders';
import StringReplacementExecutor from '@/components/domain/creatives/html/string-replacement/StringReplacementExecutor';
import { headers } from '@/components/domain/creatives/html/bulk/headers';
import ExcelCreativeExtractor from '@/shared/excel/ExcelCreativeExtractor';
import IRawHtmlExcelRow from '@/components/domain/creatives/html/bulk/IRawHtmlExcelRow';
import IBulkHtmlCreativeRow from '@/components/domain/creatives/html/bulk/IBulkHtmlCreativeRow';
import { Replacement } from '@/components/domain/creatives/html/string-replacement/Replacement';

interface IHtmlBulkCreative extends IBulkCreative {
    originTemplate: string;
    template: string;
}

export default class HtmlCreativeBulkUploadViewModel extends CreativeBulkUploadViewModel {
    public businessEntity: IBusinessEntity;

    public advertiserUrl: string;

    public currentMacroReplacements: Replacement[] = [];

    public shouldDisableTemplateNaming: boolean;

    public uploadErrors = [];

    public readonly creatives: IHtmlBulkCreative[] = [];

    private readonly excelCreativeExtractor: ExcelCreativeExtractor<IRawHtmlExcelRow, IBulkHtmlCreativeRow>;

    constructor(excelCreativeExtractor: ExcelCreativeExtractor<IRawHtmlExcelRow, IBulkHtmlCreativeRow>) {
        super();
        this.excelCreativeExtractor = excelCreativeExtractor;
    }

    hasInitialProperties(): boolean {
        return Boolean(this.businessEntity && this.advertiserUrl);
    }

    setCurrentReplacements(replacements: Replacement[]): void {
        this.currentMacroReplacements = replacements;
        this.refreshCreativeTemplateReplacements();
    }

    getTemplateNamingOptions(): IFilenameTemplateOptions {
        return {
            template: '${date}_${advertiserName}_${filename}_${dimensions.width}x${dimensions.height}',
            getTemplateParametersFromResponseOrSpreadsheetRow: (row: IBulkHtmlCreativeRow) => ({
                dimensions: row.size,
                filename: row.name,
                advertiserName: this.businessEntity.name,
                date: format(new Date(), 'yyMMdd'),
            }),
            forceDefaultTemplate: this.shouldDisableTemplateNaming,
        };
    }

    getTableHeaders(): ITableHeaders<HtmlCreativeKeysToFilterBy>[] {
        return headers();
    }

    async fileAdded(file: File): Promise<void> {
        const fileContent = await file.arrayBuffer();
        const extractedExcelData = await this.excelCreativeExtractor.extract(fileContent);
        this.uploadErrors = extractedExcelData.errors;
        if (!this.uploadErrors.length) {
            this.addCreatives(extractedExcelData.rows, file);
        }
    }

    private addCreatives(bulkHtmlCreativeRows: IBulkHtmlCreativeRow[], file: File): void {
        bulkHtmlCreativeRows.forEach((row) => {
            const replacementExecutor = new StringReplacementExecutor();
            const creative = {
                originName: row.name,
                name: formatCreativeName(
                    this.getTemplateNamingOptions().getTemplateParametersFromResponseOrSpreadsheetRow(row),
                    this.getTemplateNamingOptions().template,
                    this.getTemplateNamingOptions().forceDefaultTemplate,
                ),
                dimensions: row.size,
                originTemplate: row.template,
                template: replacementExecutor.executeReplacements(row.template, this.currentMacroReplacements),
                runtime: null,
                businessEntity: { id: this.businessEntity.id },
                uniqueId: uuidv1(),
                advertiserUrl: this.advertiserUrl,
                file,
                open: false,
            };
            this.creatives.push(creative);
        });
    }

    private refreshCreativeTemplateReplacements(): void {
        const replacementExecutor = new StringReplacementExecutor();
        this.creatives.forEach((creative) => {
            creative.template = replacementExecutor.executeReplacements(creative.originTemplate, this.currentMacroReplacements);
        });
    }
}
