<template>
    <Form v-model="creative" :submit-action="actionType" :after-route="afterRoute" @close="close" />
</template>

<script>
import Form from '@/components/domain/creatives/video/VideoCreativeForm';
import { ACTION_TYPES } from '@/components/domain/creatives/types';
import { emptyVideoCreative } from '@/components/domain/creatives/video/types';

export default {
    name: 'VideoCreativeCreate',
    components: {
        Form,
    },
    data() {
        return {
            creative: { ...emptyVideoCreative },
            afterRoute: 'video-creative-overview',
            actionType: ACTION_TYPES.CREATE,
        };
    },
    methods: {
        close() {
            this.creative = { ...emptyVideoCreative };
        },
    },
};
</script>
