<template>
    <div class="d-flex align-center justify-end">
        <SrButton v-if="!disablePreview" icon class="creative-preview" :loading="isPreviewLoading" @click="preview">
            <SrIcon icon="observe" size="xs" />
        </SrButton>
        <SrMenu v-model="optionsAreOpened" offset-y left min-width="100" :disabled="disabled">
            <template #activator="{ on, attrs }">
                <SrButton :disabled="disabled" icon v-bind="attrs" v-on="on">
                    <SrIcon :active="optionsAreOpened" icon="more" size="xs" />
                </SrButton>
            </template>
            <SrList dense>
                <SrListItem
                    v-for="route in actionRoutesAsArray"
                    :key="route.routeName"
                    :to="getRoute(route.routeName)"
                    :disabled="route.disabled"
                >
                    <SrListItemIcon>
                        <SrIcon :icon="route.icon" size="xs" />
                    </SrListItemIcon>
                    <SrListItemTitle>
                        {{ route.name }}
                    </SrListItemTitle>
                </SrListItem>
            </SrList>
        </SrMenu>
    </div>
</template>

<script>
import { SrButton, SrIcon, SrMenu, SrList, SrListItem, SrListItemTitle, SrListItemIcon } from '@ads/design-system';
import CreativeService from '@/services/CreativeService';
import PreviewService from '@/components/domain/creatives/PreviewService';

export default {
    components: { SrButton, SrIcon, SrMenu, SrList, SrListItem, SrListItemTitle, SrListItemIcon },
    props: {
        item: {
            type: Object,
            required: true,
        },
        actionRoutes: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        creativeService: {
            type: CreativeService,
            required: true,
        },
        disablePreview: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            optionsAreOpened: false,
            previewService: new PreviewService(this.creativeService),
            isPreviewLoading: false,
        };
    },
    computed: {
        actionRoutesAsArray() {
            return Object.values(this.actionRoutes).filter((route) => {
                if (route.visibilityCondition) {
                    return route.visibilityCondition(this.item);
                }

                return true;
            });
        },
    },
    methods: {
        getRoute(path) {
            const { id } = this.item;
            return {
                name: path,
                params: { id: this.item.id ? id.toString() : undefined },
            };
        },
        async preview() {
            this.isPreviewLoading = true;
            await this.previewService.openPreviewWindowForCreativeById(this.item.dspId);
            this.isPreviewLoading = false;
        },
    },
};
</script>
