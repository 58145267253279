import { ACTION_NAME, RequestService } from '@ads/iam-library';
import { VastEventType } from 'api-contracts';
import URLPathBuilder from '@/services/URLPathBuilder';
import { API_URL } from '@/config';

export default class VastEventTypeService {
    private readonly requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async listForBusinessEntity(businessEntityId: number): Promise<VastEventType[]> {
        const options = {
            domain: 'creative-management',
            resource: 'vast-event-type',
            action: ACTION_NAME.READ,
        };
        const url = this.composeListForBusinessEntityUrl(businessEntityId);
        return this.requestService.get(url, options);
    }

    private composeListForBusinessEntityUrl(businessEntityId: number): string {
        return new URLPathBuilder('/vast-event-types').setParam('businessEntityId', businessEntityId.toString()).build();
    }
}

export const vastEventTypeService = new VastEventTypeService(new RequestService({ baseUrl: API_URL }));
