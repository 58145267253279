import { IDimensions } from 'api-contracts';

export const creativeSelectionHeaders = [
    {
        text: 'Name',
        value: 'name',
        sortable: false,
    },
    {
        text: 'Size',
        value: 'dimensions',
        align: 'start',
        sortable: true,
        sort: (a: IDimensions, b: IDimensions) => {
            if (a.width < b.width) {
                return -1;
            }
            if (a.width === b.width) {
                if (a.height < b.height) {
                    return -1;
                }
                if (a.height === b.height) {
                    return 0;
                }
            }
            return 1;
        },
    },
    {
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '80px',
        sortable: false,
    },
];
