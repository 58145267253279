import { BusinessEntity } from '@/services/iam/types';
import { ValidationResult, ValidationRuleFn } from '@/shared/validation/types';

function getValidationResult(condition: boolean, message: string): ValidationResult {
    return condition || message;
}

function getLengthValidationRules(property: string, minLength: number, maxLength: number): ValidationRuleFn[] {
    return [
        (value): ValidationResult =>
            getValidationResult(
                minLength === 0 || (value != null && value.toString().length >= minLength),
                `${property} should have at least ${minLength} characters`,
            ),
        (value): ValidationResult =>
            getValidationResult(
                value != null && value.toString().length <= maxLength,
                `${property} should have ${maxLength} characters or less`,
            ),
    ];
}

function basicCreativeRules(): Record<string, ValidationRuleFn[]> {
    return {
        name: getLengthValidationRules('Creative Name', 3, 128),
        businessEntity: [
            (value: BusinessEntity): ValidationResult => getValidationResult(value.id !== null, 'Advertiser is required'),
        ],
    };
}

export default basicCreativeRules;
