import { getValidationResult } from '@/shared/validation';
import { ValidationResult, ValidationValue } from '@/shared/validation/types';

export default function isRequired(value: ValidationValue, propertyName = 'Field'): ValidationResult {
    const message = `${propertyName} is required`;

    const condition = value !== undefined && value !== null && Boolean(value.toString().trim());

    return getValidationResult(condition, message);
}
