export default class ComponentError {
    private readonly messages: string[] = [];

    setMessage(message: string | null) {
        if (message === null) {
            this.messages.splice(0, this.messages.length);
            return;
        }
        this.messages.push(message);
    }

    getMessage(): string | null {
        if (!this.messages.length) {
            return null;
        }

        return this.messages.reduce((accumulator, message) => `${accumulator}${message}\n`, '');
    }
}
