export default {
    methods: {
        removeSizeInformation(creative) {
            if (this.disableTemplateNaming) {
                return creative.originName;
            }
            return creative.originName.replace(/_?\s?\d*\s?(?<=\d|\s)x\s?\d*_?\s?/, '');
        },
    },
};
