<template>
    <AudioCreativeForm v-model="creative" :submit-action="actionType" :after-route="afterRoute" @close="close" />
</template>

<script>
import { ACTION_TYPES } from '@/components/domain/creatives/types';
import AudioCreativeForm from '@/components/domain/creatives/audio/AudioCreativeForm';
import { getEmptyAudioCreativeData } from './getEmptyAudioCreativeData';

export default {
    name: 'AudioCreativeCreate',
    components: {
        AudioCreativeForm,
    },
    data() {
        return {
            creative: getEmptyAudioCreativeData(),
            afterRoute: 'audio-creative-overview',
            actionType: ACTION_TYPES.CREATE,
        };
    },
    methods: {
        close() {
            this.creative = getEmptyAudioCreativeData();
        },
    },
};
</script>
