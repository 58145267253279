export default class URLPathBuilder {
    private path: string;

    private readonly params: URLSearchParams;

    constructor(path: string) {
        this.path = path;
        this.params = new URLSearchParams();
    }

    setParam(key: string, value: string): URLPathBuilder {
        if (!value) {
            return this;
        }
        this.params.set(key, value);
        return this;
    }

    setConditionalParam(key: string, value: CallableFunction, condition: boolean): URLPathBuilder {
        if (!condition) {
            return this;
        }
        this.setParam(key, value());
        return this;
    }

    prependPath(prependPath: string): URLPathBuilder {
        this.path = prependPath + this.path;
        return this;
    }

    appendPath(appendPath: string): URLPathBuilder {
        this.path += appendPath;
        return this;
    }

    build(): string {
        const paramString = this.params.toString();
        if (!paramString) {
            return this.path;
        }

        return `${this.path}?${paramString}`;
    }
}
