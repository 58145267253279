import { IImageCreativeCreate } from 'api-contracts';

const emptyImageCreative: IImageCreativeCreate = {
    name: '',
    advertiserUrl: '',
    clickUrl: '',
    secureUrl: '',
    isDooh: false,
    hasClickTracking: true,
    hasMeasureViewability: true,
    isServedAgainstGoogle: true,
    businessEntity: { id: null },
    runtime: null,
    dimensions: null,
    categories: [
        {
            id: 2,
            name: 'Card Games',
            code: 'IAB9-7',
            parent: {
                id: 1,
                name: 'Hobbies & Interests',
                code: 'IAB9',
            },
        },
    ],
    declarableAttributes: [
        {
            id: 1,
            name: 'CreativeType: Image/Rich Media',
        },
    ],
    fileSize: null,
    impressionTrackerHtml: '',
};

export { emptyImageCreative };
