import { ICategory } from 'api-contracts';

export default class SelectableCategoriesTransformer {
    transform(categories: ICategory[]) {
        return categories.map((category) => {
            if (category.parent) {
                return { label: `${category.name} (${category.parent.name})`, value: category.id };
            }

            return { label: category.name, value: category.id };
        });
    }
}

export const selectableCategoriesTransformer = new SelectableCategoriesTransformer();
