<template>
    <div class="d-flex align-center justify-space-between">
        <SrButton :loading="isPreviewLoading" icon data-testid="CreativeTableActions__preview-action-icon" @click="preview">
            <SrIcon icon="observe" size="xs" />
        </SrButton>
        <SrButton
            v-if="isActionAdd"
            :disabled="isDisabled"
            class="ml-2"
            color="blue lighten-5 blue--text text--lighten-1"
            elevation="0"
            small
            data-testid="CreativeTableActions__add-action-button"
            @click="add"
        >
            Add
        </SrButton>
        <SrIcon
            v-if="!isActionAdd"
            :disabled="isDisabled"
            :class="removeIconClasses"
            data-testid="CreativeTableActions__remove-action-icon"
            icon="close"
            size="xs"
            @click="remove"
        />
    </div>
</template>

<script>
import { SrButton, SrIcon } from '@ads/design-system';
import CreativeService from '@/services/CreativeService';
import PreviewService from '@/components/domain/creatives/PreviewService';

export default {
    name: 'CreativeTableActions',
    components: {
        SrButton,
        SrIcon,
    },
    props: {
        creativeService: {
            type: CreativeService,
            required: true,
        },
        dspId: {
            type: Number,
            required: true,
        },
        action: {
            type: String,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            previewService: new PreviewService(this.creativeService),
            isPreviewLoading: false,
        };
    },
    computed: {
        isActionAdd() {
            return this.action === 'add';
        },
        removeIconClasses() {
            if (this.isDisabled) {
                return 'close-icon';
            }
            return 'clickable close-icon';
        },
    },
    methods: {
        add() {
            this.$emit('add');
        },
        remove() {
            if (!this.isDisabled) {
                this.$emit('remove');
            }
        },
        async preview() {
            this.isPreviewLoading = true;
            await this.previewService.openPreviewWindowForCreativeById(this.dspId);
            this.isPreviewLoading = false;
        },
    },
};
</script>

<style>
.close-icon {
    background-color: #acacac;
    border-radius: 50px;
    padding: 3px;
}
</style>
