<template>
    <Form
        v-model="creative"
        :after-route="afterRoute"
        :error-message="errorMessage"
        :loading="isLoading"
        :submit-action="actionType"
        :is-edit-form="true"
        @close="close"
    />
</template>

<script>
import Form from '@/components/domain/creatives/native/NativeCreativeForm';
import { ACTION_TYPES, CREATIVE_SERVICE_DOMAIN } from '@/components/domain/creatives/types';
import { creativeService } from '@/services/CreativeService';
import LoadCreativeByIdMixin from '@/components/domain/creatives/LoadCreativeByIdMixin';
import { getEmptyNativeCreativeData } from '@/components/domain/creatives/native/getEmptyNativeCreativeData';

export default {
    name: 'NativeCreativeEdit',
    components: {
        Form,
    },
    mixins: [LoadCreativeByIdMixin],
    data() {
        return {
            creative: getEmptyNativeCreativeData(),
            afterRoute: 'native-creative-overview',
            actionType: ACTION_TYPES.EDIT,
            // Defined for LoadCreativeByIdMixin
            creativeService: creativeService(CREATIVE_SERVICE_DOMAIN.NATIVE),
            isLoading: false,
        };
    },
    methods: {
        close() {
            this.creative = getEmptyNativeCreativeData();
        },
    },
};
</script>
