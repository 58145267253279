import { v1 as uuidv1 } from 'uuid';
import {
    IDimensions,
    IdObject,
    IImageMultiUploadResponse,
    IImageUploadResponse,
    IMultiUploadResponse,
    IRuntime,
    IUploadResponse,
    IValidationError,
} from 'api-contracts';
import getFilenameWithoutExtension from '@/components/domain/creatives/shared/bulk/getFilenameWithoutExtension';
import formatCreativeName, { IFilenameTemplateOptions } from '@/components/domain/creatives/shared/bulk/formatCreativeName';

export interface IBulkFileAttributes {
    originName: string;
    name: string;
    runtime?: IRuntime;
    businessEntity: IdObject;
    secureUrl: string;
    open: boolean;
    uniqueId: string;
    dimensions?: IDimensions;
}

export type ISrDropzoneFile = {
    dropzoneFile: File;
    name: string;
    size: string;
};
export type DropzoneFileWithBulkAttributes = ISrDropzoneFile & Partial<IBulkFileAttributes>;

export default class BulkCreativeDropzoneViewModel {
    public files: DropzoneFileWithBulkAttributes[];

    constructor(files: DropzoneFileWithBulkAttributes[] = []) {
        this.files = files;
    }

    public getResponseForFile(
        file: ISrDropzoneFile,
        multiResponse: IMultiUploadResponse | IImageMultiUploadResponse,
    ): IUploadResponse | IImageUploadResponse | null {
        return multiResponse.data.find((response) => response.filename === file.name) || null;
    }

    public updateFile(
        file: ISrDropzoneFile,
        businessEntityId: number,
        response: IUploadResponse,
    ): asserts file is DropzoneFileWithBulkAttributes {
        (file as DropzoneFileWithBulkAttributes).originName = getFilenameWithoutExtension(file.name);
        (file as DropzoneFileWithBulkAttributes).runtime = null;
        (file as DropzoneFileWithBulkAttributes).secureUrl = response.secureUrl;
        (file as DropzoneFileWithBulkAttributes).businessEntity = { id: businessEntityId };
        (file as DropzoneFileWithBulkAttributes).uniqueId = uuidv1();
        (file as DropzoneFileWithBulkAttributes).open = false;
    }

    public createTemplatedName(options: IFilenameTemplateOptions, response: IUploadResponse): string {
        return formatCreativeName(
            options.getTemplateParametersFromResponseOrSpreadsheetRow(response),
            options.template,
            options.forceDefaultTemplate,
        );
    }

    public validateFile(
        file: ISrDropzoneFile,
        multiResponse: IMultiUploadResponse | IImageMultiUploadResponse,
    ): IValidationError | null {
        const responseError = this.getErrorForFile(file, multiResponse);
        if (responseError) {
            return responseError;
        }
        const duplicateError = this.getDuplicateErrorForFile(file);
        if (duplicateError) {
            return duplicateError;
        }

        const missingResponseError = this.getMissingResponseError(file, multiResponse);
        if (missingResponseError) {
            return missingResponseError;
        }

        return null;
    }

    private getErrorForFile(
        file: ISrDropzoneFile,
        multiResponse: IMultiUploadResponse | IImageMultiUploadResponse,
    ): IValidationError | null {
        return multiResponse.errors.find((error) => error.filename === file.name) || null;
    }

    private getDuplicateErrorForFile(file: ISrDropzoneFile | DropzoneFileWithBulkAttributes): IValidationError | null {
        const isDuplicate = this.files.find((existingFile) => this.areDuplicateFiles(existingFile, file));
        if (isDuplicate) {
            return {
                filename: file.name,
                message: `Uploaded file: '${file.name}' already exists.`,
            };
        }
        return null;
    }

    private getMissingResponseError(
        file: ISrDropzoneFile,
        multiResponse: IMultiUploadResponse | IImageMultiUploadResponse,
    ): IValidationError | null {
        const isResponseMissing = this.getResponseForFile(file, multiResponse) === null;
        if (isResponseMissing) {
            return { filename: file.name, message: 'upload response for the file is missing.' };
        }
        return null;
    }

    private areDuplicateFiles(existingFile: DropzoneFileWithBulkAttributes, file: DropzoneFileWithBulkAttributes): boolean {
        const isNameSame = getFilenameWithoutExtension(existingFile.originName || '') === getFilenameWithoutExtension(file.name);
        const areDimensionsSame = this.getAreDimensionsSame(existingFile, file);
        return isNameSame && areDimensionsSame && existingFile !== file;
    }

    private getAreDimensionsSame(file1: DropzoneFileWithBulkAttributes, file2: DropzoneFileWithBulkAttributes): boolean {
        if (!file1.dimensions || !file2.dimensions) {
            return true;
        }
        const isWidthSame = file1.dimensions.width === file2.dimensions.width;
        const isHeightSame = file1.dimensions.height === file2.dimensions.height;
        return isWidthSame && isHeightSame;
    }
}
