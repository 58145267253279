<template>
    <div>
        <MergeView v-if="mode === 'MergeView'" v-model="templateString" :options="options" :replacements="replacements" />
        <div v-else>
            <SrButton color="blue" class="code-diff-details" text @click="showMergeView">
                <slot name="details-text"> View Code </slot>
            </SrButton>
        </div>
        <MergeViewModal
            ref="mergeViewModal"
            :is-open="showMergeViewModal"
            :replacements="replacements"
            :template-string="templateString"
            @close="closeMergeViewModal"
        />
    </div>
</template>

<script>
import { SrButton } from '@ads/design-system';
import MergeView from '@/components/domain/creatives/html/HtmlCodeDiff/MergeView';
import MergeViewModal from '@/components/domain/creatives/html/HtmlCodeDiff/MergeViewModal';
import StringReplacementExecutor from '@/components/domain/creatives/html/string-replacement/StringReplacementExecutor';

export default {
    name: 'HtmlCodeDiff',
    components: {
        MergeView,
        SrButton,
        MergeViewModal,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        mode: {
            type: String,
            default: 'ButtonView',
        },
        replacements: {
            type: Array,
            required: true,
            default: () => [],
        },
        template: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                { text: 'Origin', value: 'origin' },
                { text: 'Replacement', value: 'replacement' },
                { text: 'Count', value: 'count' },
            ],
            templateString: this.template,
            showMergeViewModal: false,
            replacementExecutor: new StringReplacementExecutor(),
            options: {},
        };
    },
    watch: {
        template(value) {
            this.options = {
                value,
                origRight: this.replacementExecutor.executeReplacements(value, this.replacements),
            };
        },
        templateString() {
            this.emitGeneratedCode();
        },
        replacements: {
            deep: true,
            async handler() {
                this.emitGeneratedCode();
            },
        },
    },
    methods: {
        emitGeneratedCode() {
            this.$emit('input', this.replacementExecutor.executeReplacements(this.templateString, this.replacements));
        },
        showMergeView() {
            if (!this.templateString) {
                return;
            }
            this.showMergeViewModal = true;
        },
        closeMergeViewModal() {
            this.showMergeViewModal = false;
        },
    },
};
</script>
