<template>
    <Form v-model="creative" :submit-action="actionType" :after-route="afterRoute" @close="close" />
</template>

<script>
import { ACTION_TYPES } from '@/components/domain/creatives/types';
import Form from '@/components/domain/creatives/native/NativeCreativeForm';
import { getEmptyNativeCreativeData } from './getEmptyNativeCreativeData';

export default {
    name: 'NativeCreativeCreate',
    components: {
        Form,
    },
    data() {
        return {
            creative: getEmptyNativeCreativeData(),
            afterRoute: 'native-creative-overview',
            actionType: ACTION_TYPES.CREATE,
        };
    },
    methods: {
        close() {
            this.creative = getEmptyNativeCreativeData();
        },
    },
};
</script>
