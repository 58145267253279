import Vue from 'vue';
import formatBulkCreativeName from '@/components/domain/creatives/shared/bulk/formatBulkCreativeName';

export default {
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        headers: {
            type: Array,
            required: true,
        },
        advertiserName: {
            type: String,
            required: true,
        },
        disableTemplateNaming: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            creatives: this.value,
            selected: [],
            showEditModal: false,
            showBulkEditModal: false,
            editIndex: null,
            editItem: null,
            modalErrorMessage: null,
        };
    },
    watch: {
        creatives: {
            deep: true,
            handler(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        closeEditModal() {
            this.modalErrorMessage = null;
            this.showEditModal = false;
        },
        closeBulkEditModal() {
            this.modalErrorMessage = null;
            this.showBulkEditModal = false;
        },
        editModalChanged(creative, index) {
            let creativeName = creative.originName;
            if (!this.disableTemplateNaming) {
                creativeName = formatBulkCreativeName(creative.dimensions, creative.originName, this.advertiserName);
            }
            creative.name = creativeName;
            const duplicatedIndex = this.creatives.findIndex((currentCreative) => currentCreative.name === creative.name);
            if (duplicatedIndex !== -1 && duplicatedIndex !== index) {
                this.modalErrorMessage = `Creative name ${creative.name} already exists. Please select a different name.`;
                return;
            }
            // preserve dropzone file reference
            creative.dropzoneFile = this.creatives[index].dropzoneFile;
            Object.assign(this.creatives[index], creative);
            this.closeEditModal();
            this.$emit('editItem');
        },
        formatCreativeName(item, creative) {
            if (!item.dimensions) {
                return formatBulkCreativeName(
                    null,
                    creative.originName,
                    this.advertiserName,
                    '${date}_${advertiserName}_${filename}',
                );
            }
            return formatBulkCreativeName(item.dimensions, creative.originName, this.advertiserName, this.template);
        },
        bulkEditModalChanged(creative) {
            const hasDuplicatesInTable = this.hasDuplicateCreatives(this.creatives, creative);
            const creativeIsInSelected = this.hasDuplicateCreatives(this.selected, creative);
            if (hasDuplicatesInTable && !creativeIsInSelected) {
                this.modalErrorMessage = 'Duplication warning. Please change the creative name.';
                return;
            }
            this.selected.forEach((item) => {
                let creativeName = creative.originName;
                if (!this.disableTemplateNaming) {
                    creativeName = this.formatCreativeName(item, creative);
                }

                Object.assign(item, {
                    ...item,
                    ...creative,
                    name: creativeName,
                });
            });
            this.selected = [];
            this.closeBulkEditModal();
        },
        hasDuplicateCreatives(creatives, creative) {
            return creatives.some((currentCreative) => {
                let name = creative.originName;
                if (!this.disableTemplateNaming) {
                    name = this.formatCreativeName(currentCreative, creative);
                }
                return currentCreative.name === name;
            });
        },
        edit(item) {
            this.editIndex = this.creatives.indexOf(item);
            this.editItem = Vue.set(this, 'editItem', JSON.parse(JSON.stringify(item)));
            this.showEditModal = true;
        },
        bulkEdit() {
            const uniqueItems = new Set(this.creatives.map((v) => v.name));
            const hasDuplicatedCreativeNames = uniqueItems.size !== this.creatives.length;
            if (hasDuplicatedCreativeNames) {
                this.$emit('errorMessage', 'Duplicated creative names found.');
                return;
            }
            const hasMissingDimensions = this.selected.some((v) => !v.dimensions);
            if (hasMissingDimensions) {
                this.$emit('errorMessage', "Size missing. Creatives without size can't be bulk edited.");
                return;
            }
            const uniqueSizes = new Set(this.selected.map((v) => `${v.dimensions.width}x${v.dimensions.height}`));
            if (this.selected.length !== uniqueSizes.size) {
                this.$emit('errorMessage', "Creatives with the same size can't be bulk edited.");
                return;
            }
            this.showBulkEditModal = true;
        },
        deleteItem(item: string) {
            const selectedIndex = this.selected.findIndex((creative: string) => creative === item);
            if (selectedIndex !== -1) {
                this.selected.splice(selectedIndex, 1);
            }

            this.$emit('deleteItem', item);
        },
        bulkDelete() {
            this.selected.forEach((item) => {
                this.$emit('deleteItem', item);
            });
            this.selected = [];
        },
    },
};
