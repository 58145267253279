<template>
    <VCol class="pl-0 mr-2">
        <VRow>
            <VCol class="d-flex justify-space-between align-items-center">
                <SrHeadline v-if="title" class="title" level="2" weight="bold">
                    {{ title }}
                </SrHeadline>
                <SrInput
                    v-if="hasQuickSearch"
                    v-model="selectionQuickSearch"
                    :hide-details="true"
                    class="ma-0 pa-0 overview-quicksearch"
                    label="Quick search"
                    prepend-icon="search"
                />
                <SrButton
                    v-if="isActionAdd"
                    :disabled="hasNoItems"
                    color="blue lighten-5 blue--text text--lighten-1"
                    large
                    data-testid="CreativeSelection__add-all-button"
                    @click="addAll"
                >
                    Add All
                </SrButton>
                <SrButton
                    v-else
                    :disabled="hasNoItems"
                    type="delete"
                    large
                    data-testid="CreativeSelection__remove-all-button"
                    @click="removeAll"
                >
                    Remove All
                </SrButton>
            </VCol>
        </VRow>
        <DefaultTable
            :footer-props="footerProperties"
            :headers="headers"
            :is-loading="loading"
            :items="filteredItems"
            :options.sync="options"
            class="pa-0"
            item-key="id"
        >
            <template #name="item">
                <div :title="item.name" class="truncate-creative-name">
                    {{ item.name }}
                </div>
            </template>
            <template #dimensions="item">
                <template v-if="item.dimensions && item.dimensions.width && item.dimensions.height">
                    {{ item.dimensions.width }}x{{ item.dimensions.height }}
                </template>
            </template>
            <template #actions="item">
                <CreativeTableActions
                    :action="action"
                    :dsp-id="item.dspId"
                    :creative-service="creativeService"
                    @add="add(item)"
                    @remove="remove(item)"
                />
            </template>
        </DefaultTable>
    </VCol>
</template>

<script>
import { SrButton, SrHeadline, SrInput } from '@ads/design-system';
import DefaultTable from '@/components/shared/table/DefaultTable';
import CreativeTableActions from '@/components/domain/creatives/shared/bulk/assignment/CreativeTableActions';
import CreativeService from '@/services/CreativeService';

export default {
    name: 'CreativeSelection',
    components: {
        CreativeTableActions,
        SrHeadline,
        SrButton,
        SrInput,
        DefaultTable,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        creativeService: {
            type: CreativeService,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        hasQuickSearch: {
            type: Boolean,
            default: false,
        },
        headers: {
            type: Array,
            required: true,
        },
        serverItemsLength: {
            type: Number,
            default: 10,
        },
        title: {
            type: String,
        },
        action: {
            type: String,
            required: true,
        },
        footerProperties: {
            type: Object,
            default: () => ({
                'items-per-page-options': [5, 10, 15],
            }),
        },
        quickSearch: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            options: {
                page: 1,
                itemsPerPage: 10,
            },
            selectionQuickSearch: this.quickSearch,
        };
    },
    computed: {
        isActionAdd() {
            return this.action === 'add';
        },
        filteredItems() {
            if (!this.selectionQuickSearch) {
                return this.items;
            }
            return this.items.filter((creative) => creative.name.includes(this.selectionQuickSearch));
        },
        hasNoItems() {
            return !this.items || this.items?.length === 0;
        },
    },
    watch: {
        filteredItems(value) {
            this.$emit('update:filteredItems', value);
        },
    },
    methods: {
        add(item) {
            this.$emit('add', item);
        },
        addAll() {
            this.$emit('addAll');
        },
        remove(item) {
            this.$emit('remove', item);
        },
        removeAll() {
            this.$emit('removeAll');
        },
    },
};
</script>

<style lang="scss" scoped>
.overview-quicksearch {
    max-width: 250px;
}

.align-items-center {
    align-items: center;
}

.title {
    height: 55px;
    line-height: 55px;
    margin-bottom: 0;
}
.truncate-creative-name {
    width: 170px !important;
}
</style>
