import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { Predicate } from '@ads/predicate-model';
import {
    DisplayCreativePaginationResult,
    Html5CreativeKeysToFilterBy,
    HtmlCreativeKeysToFilterBy,
    IHtml5Creative,
    IHtml5CreativeCreate,
    IHtmlCreative,
    IHtmlCreativeCreate,
    IImageCreative,
    IImageCreativeCreate,
    ImageCreativeKeysToFilterBy,
    IVideoCreative,
    VideoCreativeKeysToFilterBy,
} from 'api-contracts';
import { API_URL } from '@/config';
import ITableOptions from '@/components/domain/creatives/ITableOptions';
import URLPathBuilder from '@/services/URLPathBuilder';
import BatchRequest from '@/services/BatchRequest';

type CreativeCreateType = IImageCreativeCreate | IHtmlCreativeCreate | IHtml5CreativeCreate;
type CreativeType = IImageCreative | IHtmlCreative | IHtml5Creative | IVideoCreative;
type CreativesKeysToFilterBy =
    | Html5CreativeKeysToFilterBy
    | HtmlCreativeKeysToFilterBy
    | ImageCreativeKeysToFilterBy
    | VideoCreativeKeysToFilterBy;

export default class CreativeService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'creative-management',
        resource: 'creative',
    };

    private readonly domainPath: string;

    private readonly bulkBatchSize: number;

    constructor(requestService: RequestService, domainPath: string, bulkBatchSize?: number) {
        this.requestService = requestService;
        this.domainPath = domainPath;
        this.bulkBatchSize = bulkBatchSize;
    }

    async create(creative: CreativeCreateType): Promise<CreativeCreateType> {
        const options = this.getRequestOptions({ action: ACTION_NAME.CREATE });
        const urlPathBuilder = new URLPathBuilder(`/${this.domainPath}`).build();
        return this.requestService.post(urlPathBuilder, creative, options);
    }

    async bulkCreate(creatives: CreativeCreateType[]): Promise<BatchRequest<CreativeCreateType>> {
        const options = this.getRequestOptions({ action: ACTION_NAME.CREATE });
        const urlPathBuilder = new URLPathBuilder(`/${this.domainPath}`);
        const urlPath = urlPathBuilder.appendPath('/bulk-create').build();

        return new BatchRequest<CreativeCreateType>(creatives, (batch) => this.requestService.post(urlPath, batch, options)).send(
            this.bulkBatchSize || creatives.length,
        );
    }

    async bulkAssign(creatives: CreativeCreateType[]): Promise<void> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });
        const urlPathBuilder = new URLPathBuilder(`/${this.domainPath}`);
        const urlPath = urlPathBuilder.appendPath('/bulk-assign').build();
        return this.requestService.put(urlPath, creatives, options);
    }

    async getById(id: number): Promise<CreativeType> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });
        const urlPathBuilder = new URLPathBuilder(`/${this.domainPath}`);
        const urlPath = urlPathBuilder.appendPath(`/${id}`).build();
        return this.requestService.get(urlPath, options);
    }

    async getPreview(dspId: number): Promise<string> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });
        const urlPathBuilder = new URLPathBuilder(`/${this.domainPath}`);
        const urlPath = urlPathBuilder.appendPath(`/${dspId}/preview`).build();
        return this.requestService.get(urlPath, options);
    }

    async edit(creative: CreativeType): Promise<CreativeType> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });
        const urlPathBuilder = new URLPathBuilder(`/${this.domainPath}`);
        const urlPath = urlPathBuilder.appendPath(`/${creative.id}`).build();
        return this.requestService.put(urlPath, creative, options);
    }

    async list(
        tableOptions: ITableOptions<CreativesKeysToFilterBy>,
        filter?: Predicate<unknown, CreativesKeysToFilterBy>,
    ): Promise<DisplayCreativePaginationResult> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });
        const urlPathBuilder = new URLPathBuilder(`/${this.domainPath}`);
        const urlPath = urlPathBuilder
            .setParam('sortBy', String(tableOptions.sortBy))
            .setParam('sortDesc', String(tableOptions.sortDesc))
            .setParam('page', String(tableOptions.page))
            .setParam('limit', String(tableOptions.itemsPerPage))
            .setConditionalParam('query', () => btoa(filter.toMinifiedJsonString()), Boolean(filter))
            .build();

        return this.requestService.get(urlPath, options);
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }
}

export const creativeService = (domainPath?: string): CreativeService =>
    new CreativeService(new RequestService({ baseUrl: API_URL }), domainPath, Number(process.env.VUE_APP_BULK_BATCH_SIZE));
