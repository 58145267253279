import { areIntervalsOverlapping, isWithinInterval } from 'date-fns';
import { isDateBeforeStartOfCurrentDateInGivenTimeZone } from '@/shared/date-time';
import { getValidationResult } from '@/shared/validation';
import { ValidationResult } from '@/shared/validation/types';

type DateRange = {
    start: Date | string;
    end: Date | string;
};

export const isDateBefore = (firstDate: Date | string, secondDate: Date | string, message: string): ValidationResult => {
    if (!firstDate || !secondDate) {
        return true;
    }
    const firstDateTimestamp = new Date(firstDate).getTime();
    const secondDateTimestamp = new Date(secondDate).getTime();
    return getValidationResult(firstDateTimestamp < secondDateTimestamp, message);
};

export const isDateInRange = (
    date: Date | string,
    startDate: Date | string,
    endDate: Date | string,
    message: string,
): ValidationResult => {
    if (!startDate || !endDate) {
        return true;
    }
    const dateTimestamp = new Date(date).getTime();
    const startDateTimestamp = new Date(startDate).getTime();
    const endDateTimestamp = new Date(endDate).getTime();
    const isWithin = isWithinInterval(dateTimestamp, {
        start: startDateTimestamp,
        end: endDateTimestamp,
    });
    return getValidationResult(isWithin, message);
};

export const areDateRangesNotOverlapping = (
    firstDateRange: DateRange,
    secondDateRange: DateRange,
    message = 'Date ranges should not overlap',
): ValidationResult => {
    if (!firstDateRange?.start || !firstDateRange?.end || !secondDateRange?.start || !secondDateRange?.end) {
        return true;
    }

    const firstInterval = { start: new Date(firstDateRange.start), end: new Date(firstDateRange.end) };
    const secondInterval = { start: new Date(secondDateRange.start), end: new Date(secondDateRange.end) };

    try {
        const areNotOverlapping = !areIntervalsOverlapping(firstInterval, secondInterval, { inclusive: true });
        return getValidationResult(areNotOverlapping, message);
    } catch (e) {
        return 'Invalid date ranges';
    }
};

export const isDateInFuture = (
    date: Date | string,
    disableValidation = false,
    message = 'Date and time have to be in the future',
): ValidationResult => {
    if (disableValidation || !date) {
        return true;
    }
    const dateTimestamp = new Date(date).getTime();
    const currentTimestamp = new Date().getTime();
    return getValidationResult(dateTimestamp > currentTimestamp, message);
};

export const isDateEqualOrLaterThanStartOfToday = (
    date: Date | string,
    timeZone: string,
    disableValidation = false,
    message = 'Date has to be later than start of today',
): ValidationResult => {
    if (disableValidation) {
        return true;
    }
    if (!date || !timeZone) {
        return true;
    }
    return getValidationResult(!isDateBeforeStartOfCurrentDateInGivenTimeZone(date, timeZone), message);
};
