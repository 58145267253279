<template>
    <VContainer class="pa-0 mb-6">
        <SrHeadline class="mb-6" level="3" weight="bold">Custom Impression Tracker</SrHeadline>
        <SrCheckbox :input-value="isEnabled()" label="Track" @change="onIsTrackingChange" />
        <SrEditableList
            v-slot="{ item, index }"
            :items="viewModel.getItems()"
            item-name="Custom Impression Tracker"
            :disabled="!isEnabled()"
            @addItem="addTracker"
            @removeItem="removeTracker"
        >
            <CustomImpressionTracker
                :model-value="item"
                :disabled="!isEnabled()"
                @update:modelValue="updateTracker($event, index)"
            />
        </SrEditableList>
    </VContainer>
</template>

<script>
import OptionalListViewModel from '@/components/shared/OptionalListViewModel';
import CustomImpressionTracker from '@/components/domain/creatives/shared/custom-impression-tracking/CustomImpressionTracker';
import { SrCheckbox, SrEditableList, SrHeadline } from '@ads/design-system';

export default {
    name: 'CustomImpressionTrackerList',
    components: {
        SrHeadline,
        SrCheckbox,
        SrEditableList,
        CustomImpressionTracker,
    },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },
    props: {
        modelValue: {
            type: Array,
            default: null,
        },
    },
    data() {
        const viewModel = new OptionalListViewModel(() => '');
        viewModel.setValue(this.modelValue);
        return { viewModel };
    },
    watch: {
        modelValue: {
            handler(value) {
                this.viewModel.setValue(value);
            },
        },
    },
    methods: {
        isEnabled() {
            return this.viewModel.isEnabled();
        },
        onIsTrackingChange(isTracking) {
            this.viewModel.setEnabled(isTracking);
            this.emitModelValue();
        },
        addTracker() {
            this.viewModel.addEmptyItem();
            this.emitModelValue();
        },
        removeTracker(index) {
            this.viewModel.removeItemAtIndex(index);
            this.emitModelValue();
        },
        updateTracker(value, index) {
            this.viewModel.updateItemAtIndex(value, index);
            this.emitModelValue();
        },
        emitModelValue() {
            const value = this.viewModel.getValue();
            this.$emit('update:modelValue', value);
        },
    },
};
</script>
