import { DataTableHeader } from 'vuetify';
import { Html5CreativeKeysToFilterBy, HtmlCreativeKeysToFilterBy, ImageCreativeKeysToFilterBy } from 'api-contracts';
import formatDate from '@/components/domain/creatives/formatDate';

interface IFormatter {
    formatter: (...config) => string;
}

interface IValueConfig {
    valueConfig?: { formatter: (...config) => string };
}

interface IHeaders<T> extends Omit<DataTableHeader, 'value'>, IValueConfig {
    text: string;
    value: T | 'actions';
    align?: 'start' | 'center' | 'end';
    sortable?: boolean;
    width?: string;
    valueConfig?: IFormatter;
}

export function headers(): IHeaders<HtmlCreativeKeysToFilterBy & Html5CreativeKeysToFilterBy & ImageCreativeKeysToFilterBy>[] {
    return [
        {
            text: 'DSP ID',
            value: 'dspId',
            align: 'start',
            width: '100px',
            sortable: true,
        },
        {
            text: 'Name',
            value: 'name',
            align: 'start',
            sortable: true,
        },
        {
            text: 'Last Modified',
            value: 'updatedAt',
            align: 'start',
            valueConfig: {
                formatter: formatDate,
            },
            sortable: true,
        },
        {
            text: 'Size',
            value: 'dimensions',
            align: 'start',
            sortable: false,
        },
        {
            text: 'Advertiser',
            value: 'businessEntity.name',
            align: 'start',
            sortable: true,
        },
        {
            text: 'Actions',
            value: 'actions',
            align: 'end',
            sortable: false,
        },
    ];
}
