<template>
    <SrButton type="light-blue" :href="downloadUrl"> Download Excel Template </SrButton>
</template>
<script>
import { SrButton } from '@ads/design-system';

export default {
    name: 'ExcelTemplateDownloadButton',
    components: {
        SrButton,
    },
    props: {
        downloadUrl: {
            type: String,
            required: true,
        },
    },
};
</script>
