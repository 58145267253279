<template>
    <VForm ref="form" v-model="formIsValid" :disabled="form.disabled" class="bulk-upload">
        <VRow>
            <VCol lg="5" md="6" sm="12" xlg="4">
                <VInput
                    v-model="viewModel.creatives"
                    :rules="viewModel.getInputRules().creatives"
                    class="hidden-input"
                    type="hidden"
                />
                <VInput
                    :rules="viewModel.getInputRules().duplicateName"
                    :value="viewModel.hasDuplicatedCreativeNames()"
                    class="hidden-input"
                    type="hidden"
                />
                <SrHeadline class="mb-6" level="3" weight="bold"> Select Advertiser</SrHeadline>
                <SrSelect
                    v-model="viewModel.businessEntity"
                    :items="businessEntities"
                    :rules="viewModel.getInputRules().businessEntity"
                    item-text="name"
                    item-value="id"
                    label="Advertiser"
                    required
                    return-object
                    searchable
                    sort-order="ASC"
                />
            </VCol>
            <VCol lg="5" md="6" sm="12" xlg="4">
                <SrURLInput
                    v-model="viewModel.advertiserUrl"
                    :rules="viewModel.getInputRules().url"
                    class="advertiser-url mt-11"
                    label="Advertiser URL"
                    maxlength="2048"
                    secure-url
                    required
                />
            </VCol>
        </VRow>
        <VRow>
            <VCol lg="5" md="6" sm="12" xlg="4">
                <ClickUrl v-model="viewModel.clickUrl" />
            </VCol>
            <VCol lg="5" md="6" sm="12" xlg="4">
                <SrCheckbox v-model="viewModel.shouldDisableTemplateNaming" label="Disable Template Naming" />
            </VCol>
        </VRow>
        <VRow v-if="viewModel.hasInitialProperties()">
            <VCol>
                <BulkCreativeDropzone
                    ref="uploadDropzone"
                    v-model="viewModel.creatives"
                    :business-entity="viewModel.businessEntity"
                    :is-loading.sync="isDropzoneLoading"
                    :dropzone-options="dropzoneOptions"
                    :name-template-options="viewModel.getTemplateNamingOptions()"
                    @errorMessage="errorMessage"
                    @uploadedFile="uploadedFile"
                />
            </VCol>
        </VRow>
        <div class="dooh-note">
            <SrCheckbox
                v-model="viewModel.isDooh"
                label="Enable DOOH creatives (disables tracking)"
                data-test-id="is-dooh"
            ></SrCheckbox>
            <SrInfoBox class="ml-2">
                <div style="max-width: 250px">
                    Allows uploading files up to 4.5MB in size. Ideal for Digital Out-of-Home (DOOH) advertising, but not for
                    standard display ads due to loss of tracking and size restrictions by ad networks.
                </div>
            </SrInfoBox>
        </div>
        <VRow v-if="viewModel.hasInitialProperties()">
            <VCol>
                <ImageBulkCreativeTable
                    v-model="viewModel.creatives"
                    :headers="viewModel.getTableHeaders()"
                    :advertiser-name="viewModel.businessEntity.name"
                    :is-loading="isDropzoneLoading"
                    :disable-template-naming="viewModel.shouldDisableTemplateNaming"
                    @editItem="editItem"
                    @errorMessage="errorMessage"
                    @deleteItem="removeCreative"
                />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrCheckbox, SrHeadline, SrSelect, SrURLInput, SrInfoBox } from '@ads/design-system';
import UploadOptions from '@/services/upload/UploadOptions';
import BulkCreativeDropzone from '@/components/domain/creatives/shared/bulk/bulk-creative-dropzone/BulkCreativeDropzone';
import ImageBulkCreativeTable from '@/components/domain/creatives/image/bulk/ImageBulkCreativeTable';
import ImageCreativeBulkUploadViewModel from '@/components/domain/creatives/image/bulk/ImageCreativeBulkUploadViewModel';
import CreativeBulkUploadMixin from '@/components/domain/creatives/shared/bulk/CreativeBulkUploadMixin';
import ClickUrl from '@/components/domain/creatives/shared/basics/ClickUrl';
import getDropzoneFileSize from '@/components/domain/creatives/shared/getDropzoneFileSize';

export default {
    name: 'ImageCreativeBulkUpload',
    components: {
        ClickUrl,
        ImageBulkCreativeTable,
        SrHeadline,
        SrSelect,
        SrURLInput,
        BulkCreativeDropzone,
        SrCheckbox,
        SrInfoBox,
    },
    mixins: [CreativeBulkUploadMixin],
    data() {
        return {
            viewModel: new ImageCreativeBulkUploadViewModel(),
            dropzoneOptionsImage: UploadOptions.createDropzoneOptionsForMultipleImages(),
            dropzoneOptionsDOOHImage: UploadOptions.createDropzoneOptionsForMultipleDOOHImages(),
            isDropzoneLoading: false,
        };
    },
    computed: {
        dropzoneOptions() {
            return this.viewModel.isDooh ? this.dropzoneOptionsDOOHImage : this.dropzoneOptionsImage;
        },
    },
    watch: {
        'viewModel.isDooh': function () {
            this.$refs.uploadDropzone.updateOptions(this.dropzoneOptions);
        },
        'viewModel.businessEntity': function (businessEntity) {
            this.viewModel.advertiserUrl = businessEntity.advertiserUrl ?? '';
        },
    },
    methods: {
        uploadedFile(creative, fileResponse) {
            creative.dimensions = fileResponse.dimensions;
            if (!creative.dimensions.height || !creative.dimensions.width) {
                this.$emit('errorMessage', `Can't retrieve size of file: '${creative.name}'.`);
                this.removeCreative(creative);
            }
            creative.advertiserUrl = this.viewModel.advertiserUrl;
            creative.clickUrl = this.viewModel.clickUrl;
            creative.fileSize = getDropzoneFileSize(creative.dropzoneFile.dataURL);
            creative.isDooh = this.viewModel.isDooh ?? false;
        },
        removeCreative(creative) {
            this.viewModel.removeCreative(creative);
            this.removeFileFromDropzone(creative);
            this.$refs.form.validate();
        },
        removeFileFromDropzone(creative) {
            this.$refs.uploadDropzone.$refs.uploadDropzone.removeFile(creative);
        },
    },
};
</script>

<style lang="scss">
.bulk-upload {
    .dz-details {
        text-align: center !important;
    }

    .dz-filename {
        display: block !important;
        color: #3367d6 !important;
    }
}

.dooh-note {
    display: flex;
    align-items: center;
}
</style>
