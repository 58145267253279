<template>
    <VCombobox
        ref="dimensionsRef"
        v-model="dimensionsString"
        :items="selectableDimensions"
        :rules="rules.dimensions"
        clearable
        :disabled="loading"
        :loading="loading"
        label="Select Size"
        outlined
        return-object
        v-bind="$attrs"
    />
</template>

<script>
import { selectableDimensionsTransformer } from '@/components/domain/creatives/shared/dimensions/SelectableDimensionsTransformer';
import { dimensionsService } from '@/components/domain/creatives/shared/dimensions/DimensionsService';
import DimensionsTypeFactory from '@/components/domain/creatives/shared/dimensions/DimensionsTypeFactory';
import dimensionsRules from '@/components/domain/creatives/shared/dimensionsRules';

export default {
    name: 'Dimensions',
    props: {
        value: {
            type: Object,
            default: null,
        },
        rules: {
            type: Object,
            default: () => dimensionsRules,
        },
    },
    data() {
        return {
            dimensionsString: DimensionsTypeFactory.toString(this.value),
            selectableDimensions: [],
            selectableDimensionsTransformer,
            dimensionsService: dimensionsService(),
            loading: false,
        };
    },
    watch: {
        value: {
            handler() {
                this.dimensionsString = DimensionsTypeFactory.toString(this.value);
            },
            deep: true,
        },
        dimensionsString() {
            const dimensions = DimensionsTypeFactory.fromString(this.dimensionsString);
            this.$emit('input', dimensions);
        },
    },
    async created() {
        this.loading = true;
        try {
            const dimensionList = await this.dimensionsService.listDimensions();
            this.selectableDimensions = this.selectableDimensionsTransformer.transform(dimensionList);
        } catch (e) {
            // ignore
        } finally {
            this.loading = false;
        }
    },
};
</script>
