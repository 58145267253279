<template>
    <div class="text-center">
        <VDialog
            :persistent="isLoading"
            :value="true"
            :width="width"
            v-bind="$attrs"
            attach="#creative-management-content"
            @click:outside="close"
        >
            <VCard>
                <VProgressLinear v-if="isLoading" indeterminate />
                <VCardTitle class="headline lighten-2">
                    {{ title }}
                </VCardTitle>

                <VSpacer />

                <slot name="content">
                    <VCardText v-if="!htmlDescription">
                        {{ description }}
                    </VCardText>
                    <VCardText v-else v-html="description" />
                </slot>

                <VCardText v-if="error">
                    <ErrorBox :message="error" :show="Boolean(error)" class="mb-0" />
                </VCardText>

                <VDivider />

                <VCardActions>
                    <VSpacer />
                    <slot name="cancel">
                        <SrButton :disabled="isLoading" class="close" @click="close"> Cancel </SrButton>
                    </slot>
                    <slot name="actions" />
                </VCardActions>
            </VCard>
        </VDialog>
    </div>
</template>

<script>
import { SrButton } from '@ads/design-system';
import ErrorBox from '@/components/domain/creatives/shared/ErrorBox';

export default {
    name: 'ActionModal',
    components: {
        SrButton,
        ErrorBox,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        width: {
            type: Number,
            default: 600,
        },
        htmlDescription: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dialog: true,
        };
    },
    methods: {
        close() {
            if (!this.isLoading) {
                this.$emit('close');
            }
        },
    },
};
</script>
