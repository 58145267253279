<template>
    <ActionModal
        v-if="isOpen"
        :description="description"
        :error="error"
        :is-loading="isLoading"
        title="Edit Selected Creative"
        @close="close"
    >
        <template #content>
            <VForm ref="form" v-model="formIsValid">
                <slot :creative="creative" :description="description" :rules="rules" :runtime="runtime" name="form-content" />
            </VForm>
        </template>
        <template #cancel>
            <SrButton :disabled="isLoading" class="close" color="blue" outlined x-large @click="close"> Cancel </SrButton>
        </template>
        <template #actions>
            <SrButton
                ref="confirm"
                :disabled="!formIsValid"
                :loading="isLoading"
                class="confirm"
                type="secondary"
                x-large
                @click="done"
            >
                Save
            </SrButton>
        </template>
    </ActionModal>
</template>

<script>
import Vue from 'vue';
import { SrButton } from '@ads/design-system';
import { emptyRuntime } from '@/components/domain/creatives/types';
import editCreativeRules from '@/components/domain/creatives/shared/bulk/editCreativeRules';
import ActionModal from '@/components/domain/creatives/shared/ActionModal';
import ModalError from '@/components/domain/creatives/shared/ModalError';

export default {
    name: 'EditCreativeModal',
    components: {
        ActionModal,
        SrButton,
    },
    mixins: [ModalError],
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
        },
        index: {
            type: Number,
        },
    },
    data() {
        return {
            isLoading: false,
            creative: this.item,
            formIsValid: false,
            rules: editCreativeRules(),
        };
    },
    computed: {
        description() {
            return `Edit creative '${this.creative.name}'`;
        },
        runtime: {
            get() {
                if (!this.creative.runtime) {
                    return { ...emptyRuntime };
                }

                return this.creative.runtime;
            },
            set(expiration) {
                if (expiration.startDate === '') {
                    this.creative.runtime = null;
                } else {
                    Vue.set(this.creative, 'runtime', expiration);
                }
            },
        },
    },
    watch: {
        item: {
            deep: true,
            handler(value) {
                this.creative = value;
            },
        },
    },
    methods: {
        done() {
            this.$emit('done', this.creative, this.index);
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>
