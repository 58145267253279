<template>
    <BulkEditCreativeModal v-bind="$attrs" v-on="$listeners">
        <template #form-content="{ creative, rules, description, runtime }">
            <VCardText>{{ description }}</VCardText>
            <VCardText>
                <SrInput
                    :rules="rules.name"
                    :value="removeSizeInformation(creative)"
                    class="creative-name"
                    label="Creative Name"
                    maxlength="128"
                    required
                    @input="(value) => changeCreativeName(creative, value)"
                />
                <ClickUrl v-model="creative.clickUrl" />
                <SrURLInput
                    v-model="creative.advertiserUrl"
                    :rules="rules.url"
                    class="advertiser-url"
                    label="Advertiser URL"
                    maxlength="2048"
                    required
                    secure-url
                />
                <CreativeRuntime :value="runtime" @input="(runtime) => changeRuntime(creative, runtime)" />
            </VCardText>
        </template>
    </BulkEditCreativeModal>
</template>

<script>
import { SrInput, SrURLInput } from '@ads/design-system';
import CreativeRuntime from '@/components/domain/creatives/shared/basics/CreativeRuntime';
import BulkEditCreativeModal from '@/components/domain/creatives/shared/bulk/BulkEditCreativeModal';
import removeCreativeSizeFromName from '@/components/domain/creatives/html5/bulk/removeCreativeSizeFromName';
import ClickUrl from '@/components/domain/creatives/shared/basics/ClickUrl';

export default {
    name: 'BulkEditHtml5CreativeModal',
    components: {
        ClickUrl,
        BulkEditCreativeModal,
        CreativeRuntime,
        SrInput,
        SrURLInput,
    },
    mixins: [removeCreativeSizeFromName],
    props: {
        disableTemplateNaming: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        changeRuntime(creative, runtime) {
            creative.runtime = runtime;
        },
        changeCreativeName(creative, name) {
            creative.originName = name;
        },
    },
};
</script>
