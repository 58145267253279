<template>
    <VForm ref="form" v-model="formIsValid" :disabled="form.disabled">
        <VRow>
            <VCol xlg="4" lg="5" md="6" sm="12">
                <SrHeadline level="3" weight="bold" class="mb-6"> Basics </SrHeadline>
                <SrInput v-model="formData.name" :rules="rules.name" class="creative-name" label="Creative Name" required />
                <SrSelect
                    v-model="formData.businessEntity"
                    label="Advertiser"
                    :items="businessEntities"
                    :rules="rules.businessEntity"
                    item-value="id"
                    item-text="name"
                    return-object
                    required
                    :disabled="Boolean(formData.id)"
                    searchable
                    sort-order="ASC"
                    @change="validateForm"
                />
                <SrHeadline level="3" weight="bold" class="mb-6"> Click Tracking </SrHeadline>
                <SrCheckbox v-model="formData.hasClickTracking" label="Clicks can be tracked on this creative" />
                <slot name="creative-specific-fields" />
            </VCol>
            <VCol xlg="4" lg="5" md="6" sm="12" offset-lg="1">
                <SrHeadline level="3" weight="bold" class="mb-6"> Lifetime </SrHeadline>
                <CreativeRuntime v-model="runtime" />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrCheckbox, SrHeadline, SrInput, SrSelect } from '@ads/design-system';
import deepmerge from 'deepmerge';
import Vue from 'vue';
import basicCreativeRules from '@/components/domain/creatives/shared/basics/basicCreativeRules';
import FormBasics from '@/components/domain/creatives/FormBasics';
import CreativeRuntime from '@/components/domain/creatives/shared/basics/CreativeRuntime';
import { emptyRuntime } from '@/components/domain/creatives/types';

export default {
    name: 'BasicCreative',
    components: {
        SrCheckbox,
        SrHeadline,
        SrInput,
        SrSelect,
        CreativeRuntime,
    },
    mixins: [FormBasics],
    props: {
        dynamicRules: {
            type: Object,
            default: () => ({}),
        },
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            businessEntities: [],
        };
    },
    watch: {
        'formData.businessEntity': {
            handler(businessEntity) {
                if (!this.formData.id) {
                    this.formData.advertiserUrl = businessEntity.advertiserUrl ?? '';
                }
            },
        },
    },
    computed: {
        rules() {
            return deepmerge(basicCreativeRules(), this.dynamicRules);
        },
        runtime: {
            get() {
                if (!this.formData.runtime) {
                    return { ...emptyRuntime };
                }

                return this.value.runtime;
            },
            set(expiration) {
                Vue.set(this.formData, 'runtime', expiration);
            },
        },
    },
    async created() {
        this.loading = true;
        this.businessEntities = await this.$store.dispatch('iamStore/getBusinessEntities');
        this.loading = false;
    },
    methods: {
        validateForm() {
            this.$refs.form.validate();
        },
    },
};
</script>
