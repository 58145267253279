<template>
    <VForm ref="form" v-model="formIsValid" :disabled="form.disabled" class="html5-assets-form">
        <VRow no-gutters>
            <VCol cols="6">
                <VRow no-gutters>
                    <VCol cols="12">
                        <SrHeadline level="3" weight="bold" class="mb-6"> URL Assets </SrHeadline>
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="8">
                        <SrURLInput
                            v-model="formData.advertiserUrl"
                            label="Advertiser URL"
                            required
                            data-test-id="advertiser-url"
                            secure-url
                        />
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="8">
                        <ClickUrl v-model="formData.clickUrl" data-test-id="click-url" />
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="8">
                        <SrURLInput
                            v-model="formData.secureUrl"
                            label="Secure HTML URL"
                            :loading="loading"
                            secure-url
                            required
                            disabled
                            data-test-id="secure-url"
                        />
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="8">
                        <SrURLInput
                            v-model="formData.zipFileUrl"
                            label="Secure ZIP URL"
                            :loading="loading"
                            secure-url
                            disabled
                            data-test-id="zip-file-url"
                        />
                    </VCol>
                </VRow>
            </VCol>
            <VCol cols="6">
                <VRow no-gutters>
                    <VCol cols="12">
                        <SrHeadline level="3" weight="bold" class="mb-6"> Upload Creative </SrHeadline>
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="12">
                        <Dimensions v-model="formData.dimensions" required />
                    </VCol>
                </VRow>
                <VRow no-gutters>
                    <VCol cols="12">
                        <SrDropzoneUpload
                            id="html5-upload-dropzone"
                            ref="uploadDropzone"
                            :dropzone-options="dropzoneOptions"
                            @vdropzone-complete="uploadCompleted"
                            @vdropzone-file-added="fileAdded"
                            @uploadSuccess="uploadSuccess"
                            @vdropzone-removed-file="clearSecureUrl"
                            @vdropzone-error="uploadError"
                        />
                    </VCol>
                </VRow>
                <div class="dooh-note">
                    <SrCheckbox
                        v-model="formData.isDooh"
                        label="Enable DOOH creatives"
                        data-test-id="Assets__is-dooh-checkbox"
                        :disabled="Boolean(formData.id)"
                    ></SrCheckbox>
                    <SrInfoBox class="ml-2" main>
                        <div style="max-width: 250px">
                            Allows uploading files up to 4.5MB in size. Ideal for Digital Out-of-Home (DOOH) advertising, but not
                            advised for standard display ads due to size restrictions by ad networks.
                        </div>
                    </SrInfoBox>
                </div>
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrHeadline, SrURLInput, SrDropzoneUpload, SrCheckbox, SrInfoBox } from '@ads/design-system';
import FormBasics from '@/components/domain/creatives/FormBasics';
import Dimensions from '@/components/domain/creatives/shared/dimensions/Dimensions';
import UploadOptions from '@/services/upload/UploadOptions';
import './index.scss';
import ClickUrl from '@/components/domain/creatives/shared/basics/ClickUrl';
import { updateDropzoneOptions } from '@/components/domain/creatives/shared/updateDropzoneOptions';
import DropzoneFileList from '@/components/domain/creatives/shared/bulk/bulk-creative-dropzone/DropzoneFileList';
import { convertMiBToMB } from '@/utils/conversions';

export default {
    name: 'Html5Assets',
    components: {
        SrInfoBox,
        SrCheckbox,
        ClickUrl,
        SrURLInput,
        SrHeadline,
        Dimensions,
        SrDropzoneUpload,
    },
    mixins: [FormBasics],
    data() {
        return {
            dropzoneOptionsHtml5: UploadOptions.createDropzoneOptionsForSingleHtml5(),
            dropzoneOptionsDOOH: UploadOptions.createDropzoneOptionsForSingleDOOHHtml5(),
            lastUploadedZipUrl: '',
            dropzone: null,
        };
    },
    computed: {
        dropzoneOptions() {
            return this.formData.isDooh ? this.dropzoneOptionsDOOH : this.dropzoneOptionsHtml5;
        },
    },
    watch: {
        'creative.secureUrl': function (value, oldValue) {
            if (oldValue !== '') {
                this.clearUploadedZip();
            }
        },
        'formData.isDooh': function () {
            updateDropzoneOptions(this.dropzoneOptions, this.$refs.uploadDropzone.$refs.vueDropzone);
        },
    },
    mounted() {
        this.dropzone = this.$refs.uploadDropzone.$refs.vueDropzone;
    },
    methods: {
        fileAdded() {
            this.loading = true;
        },
        uploadCompleted() {
            this.loading = false;
        },
        uploadSuccess(file, response) {
            this.lastUploadedZipUrl = response.secureUrl;
            this.formData.zipFileUrl = response.zipFileUrl;
            this.formData.secureUrl = response.secureUrl;
            this.$emit('errorMessage', null);
        },
        clearSecureUrl() {
            if (this.$refs.uploadDropzone.getListSize() === 0) {
                this.lastUploadedZipUrl = '';
                this.formData.secureUrl = '';
            }
        },
        uploadError(file, msg) {
            if (msg.message) {
                this.$emit('errorMessage', `"${file.upload.filename}":\n${msg.message}`);
                return;
            }
            const isFileSizeExceededError = typeof msg === 'string' && msg.includes('File is too big');
            if (isFileSizeExceededError) {
                this.emitErrorForTooLargeFiles([file]);
                return;
            }
            this.$emit('errorMessage', msg);
        },
        emitErrorForTooLargeFiles(fileList) {
            const files = new DropzoneFileList(fileList);
            const maxAllowedFileSizeInMB = this.getMaxAllowedFileSizeInMB();
            const errorMessage = files.generateErrorMessageForTooLargeFiles(maxAllowedFileSizeInMB);
            if (errorMessage.length > 0) {
                this.$emit('errorMessage', errorMessage);
            }
        },
        getMaxAllowedFileSizeInMB() {
            const maxAllowedFileSizeInMiB = this.dropzoneOptions?.maxFilesize;
            return convertMiBToMB(maxAllowedFileSizeInMiB);
        },
        uploadedZipChanged() {
            return this.lastUploadedZipUrl !== this.formData.secureUrl;
        },
        clearUploadedZip() {
            if (!this.uploadedZipChanged()) {
                return;
            }
            this.$emit('errorMessage', null);
            this.lastUploadedZipUrl = '';
            this.dropzone.removeAllFiles();
        },
    },
};
</script>
