import { getTimezoneOffset } from 'date-fns-tz';
import { getCurrentDateInGivenTimezone } from '@/shared/date-time/index';

export default function getStartOfCurrentDateInGivenTimezone(timeZone: string): Date {
    const timeZoneOffset = getTimezoneOffset(timeZone);

    const currentDateInGivenTimezone = getCurrentDateInGivenTimezone(timeZone);
    const utcStartOfCurrentDateInGivenTimeZone = currentDateInGivenTimezone.setUTCHours(0, 0, 0, 0);

    return new Date(utcStartOfCurrentDateInGivenTimeZone - timeZoneOffset);
}
