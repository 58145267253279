<template>
    <Form
        v-model="creative"
        :after-route="afterRoute"
        :error-message="errorMessage"
        :loading="isLoading"
        :submit-action="actionType"
        :is-edit-form="true"
        @close="close"
    />
</template>

<script>
import Form from '@/components/domain/creatives/video/VideoCreativeForm';
import { ACTION_TYPES, CREATIVE_SERVICE_DOMAIN } from '@/components/domain/creatives/types';
import { creativeService } from '@/services/CreativeService';
import LoadCreativeByIdMixin from '@/components/domain/creatives/LoadCreativeByIdMixin';
import { emptyVideoCreative } from '@/components/domain/creatives/video/types';

export default {
    name: 'VideoCreativeEdit',
    components: {
        Form,
    },
    mixins: [LoadCreativeByIdMixin],
    data() {
        return {
            creative: { ...emptyVideoCreative },
            isLoading: true,
            afterRoute: 'video-creative-overview',
            actionType: ACTION_TYPES.EDIT,
            creativeService: creativeService(CREATIVE_SERVICE_DOMAIN.VIDEO),
        };
    },
    methods: {
        close() {
            this.creative = { ...emptyVideoCreative };
        },
    },
};
</script>
