import Vuex from 'vuex';
import Vue from 'vue';
import globalStore from '@/store/globalStore';
import iamStore from '@/store/iamStore';
import { videoMimeTypeStore } from '@/store/mime-type/videoMimeTypeStore';
import { audioMimeTypeStore } from '@/store/mime-type/audioMimeTypeStore';

Vue.use(Vuex);

const modules = {
    iamStore,
    videoMimeTypeStore,
    audioMimeTypeStore,
};

const store = new Vuex.Store({
    ...globalStore,
    modules,
});

export default store;
