/* eslint-disable @typescript-eslint/unbound-method */
import Vue from 'vue';
import App from '@/App.vue';
import DesignSystem, { vuetify } from '@ads/design-system';
import '@/scss/main.scss';
import VueRouter from 'vue-router';
import singleSpaVue from 'single-spa-vue';
import { ObserveVisibility } from 'vue-observe-visibility';
import store from '@/store';
import routes from '@/routes';
import '@/vuetify-loader.conf';

Vue.directive('observe-visibility', ObserveVisibility);
Vue.use(DesignSystem);
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: 'creative-management',
    routes,
});

const vueLifeCycles = singleSpaVue({
    Vue,
    appOptions: {
        vuetify,
        render: (h) => h(App),
        router,
        store,
        el: '#microfrontend-application-container',
    },
});

const bootstrap = vueLifeCycles.bootstrap;
const mount = vueLifeCycles.mount;
const unmount = vueLifeCycles.unmount;

export { bootstrap, mount, unmount };
