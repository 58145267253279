import { IDimensions } from 'api-contracts';
import IValidatingTransformer from '@/shared/excel/IValidatingTransformer';
import IRawHtmlExcelRow from '@/components/domain/creatives/html/bulk/IRawHtmlExcelRow';
import IBulkHtmlCreativeRow from '@/components/domain/creatives/html/bulk/IBulkHtmlCreativeRow';

export default class HtmlCreativeImportTransformer implements IValidatingTransformer<IRawHtmlExcelRow, IBulkHtmlCreativeRow> {
    transform({ name, size, template }: IRawHtmlExcelRow): IBulkHtmlCreativeRow | string {
        if (!name) {
            return `Required field name is missing.`;
        }
        if (typeof name !== 'string') {
            return `Required field name needs to be a string instead of ${name}.`;
        }
        if (!template) {
            return `Required field template is missing.`;
        }
        if (typeof template !== 'string') {
            return `Required field template needs to be a string instead of ${template}.`;
        }
        if (!size) {
            return `Required field size is missing.`;
        }
        if (typeof size !== 'string') {
            return `Required field size needs to be a string instead of ${size}.`;
        }
        const transformedSize = this.transformSize(size);
        if (!Number.isInteger(transformedSize.width) || !Number.isInteger(transformedSize.height)) {
            return `Dimensions invalid.`;
        }
        return {
            name,
            template,
            size: transformedSize,
        };
    }

    private transformSize(size: string): IDimensions {
        const [width, height] = size.split('x');
        return { width: parseInt(width, 10), height: parseInt(height, 10) };
    }
}
