import dimensionsRules from '@/components/domain/creatives/shared/dimensionsRules';

function editCreativeRules() {
    return {
        name: [
            (v) => (v && !!v.trim()) || 'Creative name is required',
            (v) => v.length >= 3 || 'Creative name has to be not shorter than 3 characters',
            (v) => v.length <= 128 || 'Creative name has to be not longer than 128 characters',
        ],
        url: [(v) => (v && !!v.trim()) || 'URL is required'],
        ...dimensionsRules,
    };
}

export default editCreativeRules;
