import { IDeclarableAttribute } from 'api-contracts';

export default class SelectableDeclarableAttributesTransformer {
    transform(declarableAttributes: IDeclarableAttribute[]) {
        return declarableAttributes.map((declarableAttribute) => ({
            label: declarableAttribute.name,
            value: declarableAttribute.id,
        }));
    }
}

export const selectableDeclarableAttributesTransformer = new SelectableDeclarableAttributesTransformer();
