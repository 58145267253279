import es6Renderer from 'express-es6-template-engine';
import { IImageUploadResponse, IUploadResponse } from 'api-contracts';
import IBulkHtmlCreativeRow from '@/components/domain/creatives/html/bulk/IBulkHtmlCreativeRow';
import IBulkVideoCreativeRow from '@/components/domain/creatives/video/bulk/IBulkVideoCreativeRow';

export interface IFilenameTemplateOptions {
    template: string;
    getTemplateParametersFromResponseOrSpreadsheetRow: (
        responseOrRow: IUploadResponse | IImageUploadResponse | IBulkVideoCreativeRow | IBulkHtmlCreativeRow,
    ) => BulkCreativeTemplateParameters;
    forceDefaultTemplate: boolean;
}

export type BulkCreativeTemplateParameters = {
    filename: string;
    dimensions?: {
        width: number;
        height: number;
    };
    advertiserName?: string;
    date?: string;
};

const processResult = (err: Error, content: string): string => {
    if (err instanceof Error) {
        throw err;
    }
    return content;
};

const DEFAULT_TEMPLATE = '${filename}';

function formatCreativeName(
    templateParameters: BulkCreativeTemplateParameters,
    template = DEFAULT_TEMPLATE,
    forceDefaultTemplate = false,
): string {
    let filename = templateParameters.filename;
    if (!forceDefaultTemplate) {
        const filenameWithoutDimensionsDuplication = templateParameters.filename.replace(/_?\s?\d*\s?(?<=\d|\s)x\s?\d*_?\s?/, '');
        filename = filenameWithoutDimensionsDuplication;
    }
    const renderedName = es6Renderer(
        forceDefaultTemplate ? DEFAULT_TEMPLATE : template,
        {
            template: true,
            locals: {
                ...templateParameters,
                filename,
            },
        },
        processResult,
    );
    return sanitizeWhitespace(renderedName);
}

function sanitizeWhitespace(value: string): string {
    return value.replace(/\s+/g, ' ').trim();
}

export default formatCreativeName;
