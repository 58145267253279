import IExcelFileReader from '@/shared/excel/IExcelFileReader';
import ExcelJS from 'exceljs';
import ExcelCellValue from '@/shared/excel/ExcelCellValue';

export default class ExcelJsExcelFileReader implements IExcelFileReader {
    async readRowsAsObjects<TResult>(file: ArrayBuffer, columnNames: (keyof TResult)[]): Promise<TResult[]> {
        const worksheet = await this.getWorksheetFromFile(file);
        return this.readContentFromWorksheet(worksheet, columnNames);
    }

    private async getWorksheetFromFile(file: ArrayBuffer): Promise<ExcelJS.Worksheet> {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(file);
        return workbook.worksheets[0];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readContentFromWorksheet<TResult>(worksheet: ExcelJS.Worksheet, columnNames: (keyof TResult)[]): any {
        const results = [];
        worksheet.eachRow((row, rowNumber) => {
            if (rowNumber <= 1) {
                return;
            }
            const parsedRow = {};
            columnNames.forEach((columnName, index) => {
                const cell = row.getCell(index + 1);
                parsedRow[columnName as string] = this.getValue(cell);
            });

            results.push(parsedRow);
        });
        return results;
    }

    private getValue(cell: ExcelJS.Cell): ExcelCellValue {
        const value = cell.value;
        if (value == null) {
            return undefined;
        }
        if (this.isHyperlink(value)) {
            return value.hyperlink;
        }
        if (cell.type === ExcelJS.ValueType.Formula) {
            return cell.result;
        }
        if (this.isRichText(value)) {
            return this.getRichTextAsString(value);
        }
        if (typeof value === 'object') {
            return undefined;
        }
        return value;
    }

    private getRichTextAsString(cellValue: ExcelJS.CellRichTextValue): string {
        const texts = cellValue.richText.map((richTextEntry) => richTextEntry.text);
        return texts.join('');
    }

    private isHyperlink(cellValue: ExcelJS.CellValue): cellValue is ExcelJS.CellHyperlinkValue {
        return typeof cellValue === 'object' && Object.hasOwn(cellValue, 'hyperlink');
    }

    private isRichText(cellValue: ExcelJS.CellValue): cellValue is ExcelJS.CellRichTextValue {
        return typeof cellValue === 'object' && Object.hasOwn(cellValue, 'richText');
    }
}
