function bulkCreativeRules(): { [key: string]: CallableFunction[] } {
    return {
        businessEntity: [(v) => (v && Boolean(Object.keys(v).length)) || 'Advertiser is required'],
        url: [(v) => (v && !!v.trim()) || 'URL is required'],
        creatives: [
            (v) =>
                (v && Boolean(v.length) && !v.some((creative) => typeof creative.dimensions === 'undefined')) ||
                'Dimensions missing',
        ],
        creativesWithoutDimensions: [(v) => (v && Boolean(v.length)) || 'No creative was found.'],
        duplicateName: [(v) => !v || 'Duplicated creative names found.'],
    };
}

export default bulkCreativeRules;
