<template>
    <SrDateTimeInput
        ref="date"
        v-bind="$attrs"
        v-model="datetime"
        :target-time-zone="targetTimeZone"
        :min="minSelectableDate"
        :max="maxSelectableDate"
        :rules="rules"
        :disabled="isDisabled"
        required
    />
</template>

<script>
import { SrDateTimeInput } from '@ads/design-system';
import { isBefore } from 'date-fns';
import { getStartOfCurrentDateInGivenTimezone, isDateBeforeStartOfCurrentDateInGivenTimeZone } from '@/shared/date-time';

export default {
    name: 'FutureDateTimeSelector',
    components: {
        SrDateTimeInput,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: String,
            default: undefined,
        },
        targetTimeZone: {
            type: String,
            required: true,
        },
        rules: {
            type: Array,
            default: () => [],
        },
        minDate: {
            type: [Date, String], // UTCZ
            default: undefined,
        },
        maxDate: {
            type: [Date, String], // UTCZ
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            datetime: this.value,
        };
    },
    computed: {
        isDisabled() {
            return this.disabled || this.isMaxSelectableDateBeforeStartOfToday;
        },
        isMaxSelectableDateBeforeStartOfToday() {
            if (!this.maxSelectableDate) {
                return false;
            }
            return isDateBeforeStartOfCurrentDateInGivenTimeZone(this.maxSelectableDate, this.targetTimeZone);
        },
        minSelectableDate() {
            return this.getStartOfTodayOrDateIfLater(this.minDate, this.targetTimeZone);
        },
        maxSelectableDate() {
            if (!this.maxDate) {
                return undefined;
            }
            return new Date(this.maxDate);
        },
    },
    watch: {
        value(newValue) {
            this.datetime = newValue;
            this.$refs.date.validate();
        },
        datetime(newValue) {
            this.$emit('input', newValue);
        },
    },
    methods: {
        getStartOfTodayOrDateIfLater(date, targetTimeZone) {
            const UTCStartOfTodayInSelectedTimezone = getStartOfCurrentDateInGivenTimezone(targetTimeZone);
            if (!date) {
                return UTCStartOfTodayInSelectedTimezone;
            }
            const parsedDate = new Date(date);
            if (isBefore(parsedDate, UTCStartOfTodayInSelectedTimezone)) {
                return UTCStartOfTodayInSelectedTimezone;
            }
            return parsedDate;
        },
    },
};
</script>
