<template>
    <SrModalPageForm
        v-model="form.step"
        :show.sync="show"
        :steps="navigationSteps"
        :tabs="shouldHaveTabs"
        v-bind="$attrs"
        :loading="isLoading"
        :loading-config="loadingConfig"
        :show-submit-button="shouldShowSubmitButton"
        @close="close"
        @submit="submit"
    >
        <SrNotification
            v-if="componentError.getMessage()"
            type="error"
            title="Error"
            :description="componentError.getMessage()"
            :dismissible="true"
            class="pt-5"
            @close="closeError"
        />
        <SrModalPageStep
            v-for="(navigationStep, index) in navigationSteps"
            :key="index"
            :step="index + 1"
            :title="`${navigationStep.headline}`"
        >
            <slot :name="index + 1"> Slot {{ index + 1 }} </slot>
        </SrModalPageStep>
    </SrModalPageForm>
</template>

<script>
import { createActionValidator, SrModalPageForm, SrModalPageStep, SrNotification } from '@ads/design-system';
import { ACTION_NAME } from '@ads/iam-library';
import ComponentError from '@/components/domain/creatives/ComponentError';

export default {
    name: 'Form',
    components: {
        SrModalPageForm,
        SrModalPageStep,
        SrNotification,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        navigationSteps: {
            type: Array,
            required: true,
        },
        errorMessage: {
            type: String,
            default: null,
        },
        componentError: {
            type: ComponentError,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        loadingProgress: {
            type: Number,
            default: 0,
        },
        isComponentLoading: {
            type: Object,
        },
        value: {
            type: Object,
            required: true,
        },
        afterRoute: {
            type: String,
            required: true,
        },
        components: {
            type: Array,
            required: true,
            default: () => [],
        },
        shouldHaveTabs: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: this.value,
            show: true,
            userPermittedToCreateCreative: true,
            userPermittedToEditCreative: true,
        };
    },
    computed: {
        isLoading() {
            return (
                this.loading ||
                Object.keys(this.isComponentLoading).some((componentKey) => this.isComponentLoading[componentKey] === true)
            );
        },
        loadingConfig() {
            if (!this.loadingProgress) {
                return undefined;
            }
            return {
                indeterminate: false,
                progress: this.loadingProgress,
            };
        },
        shouldShowSubmitButton() {
            return this.userPermittedToCreateCreative || this.userPermittedToEditCreative;
        },
    },
    watch: {
        errorMessage(message) {
            this.form.disabled = true;
            this.componentError.setMessage(message);
        },
        form() {
            this.$emit('input', this.form); // update components v-model
        },
    },
    async created() {
        await this.validateUserPermissions();
    },
    methods: {
        submit(done = () => null) {
            this.$emit('submit', done);
        },
        close() {
            this.$emit('close');
        },
        closeError() {
            this.componentError.setMessage(null);
        },
        async validateUserPermissions() {
            const permissionContext = {
                domainName: 'creative-management',
                resourceType: 'creative',
                userId: this.$root.user.getCognitoId(),
            };
            const actionValidator = await createActionValidator(this.$root.user);
            this.userPermittedToCreateCreative = await actionValidator.validateAction({
                actionName: ACTION_NAME.CREATE,
                ...permissionContext,
            });
            this.userPermittedToEditCreative = await actionValidator.validateAction({
                actionName: ACTION_NAME.EDIT,
                ...permissionContext,
            });
        },
    },
};
</script>
