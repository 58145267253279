import { IVideoCreative } from 'api-contracts';

const emptyVideoCreative: IVideoCreative = {
    id: null,
    dspId: null,
    name: '',
    businessEntity: { id: null },
    runtime: null,
    hasClickTracking: true,
    isServedAgainstGoogle: true,
    isSkippable: false,
    customImpressionTrackers: null,
    vastTrackingVideoEventTypes: null,
    isVastUrlParsed: true,
    categories: [
        {
            id: 2,
            name: 'Card Games',
            code: 'IAB9-7',
            parent: {
                id: 1,
                name: 'Hobbies & Interests',
                code: 'IAB9',
            },
        },
    ],
    declarableAttributes: [],
    vastUrl: '',
    advertiserUrl: '',
    vastVersion: null,
    vastVideoDuration: null,
    vastMimeTypes: [],
    vastApiFrameworks: [],
    createdAt: undefined,
    updatedAt: undefined,
};

export { emptyVideoCreative };
