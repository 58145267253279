import FormBasics from '@/components/domain/creatives/FormBasics';

export default {
    props: {
        selectedBusinessEntity: {
            type: Object,
            required: true,
        },
    },
    mixins: [FormBasics],
    async created() {
        this.loading = true;
        this.businessEntities = await this.$store.dispatch('iamStore/getBusinessEntities');
        this.loading = false;
    },
    data() {
        return {
            businessEntities: [],
        };
    },
    watch: {
        'viewModel.creatives': {
            deep: true,
            handler(value) {
                this.$emit('input', value);
            },
        },
        'viewModel.businessEntity': {
            deep: true,
            handler(value) {
                this.$emit('update:selectedBusinessEntity', value);
            },
        },
        hasDuplicatedCreativeNames(value) {
            if (value) {
                this.errorMessage('Duplicated creative names found.');
            } else {
                this.errorMessage(null);
            }
        },
    },
    methods: {
        errorMessage(message) {
            this.$emit('errorMessage', message);
        },
        editItem() {
            this.$refs.form.validate();
        },
    },
};
