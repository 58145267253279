export type IFileListItem = {
    status: 'success' | 'error';
    name: string;
    size: number;
};

export class FileListItem implements IFileListItem {
    readonly status: 'success' | 'error';

    readonly name: string;

    readonly size: number;

    constructor(fileListItem: IFileListItem) {
        this.name = fileListItem.name;
        this.size = fileListItem.size;
        this.status = fileListItem.status;
    }

    hasError(): boolean {
        return this.status === 'error';
    }

    hasSizeAbove(sizeInByte: number): boolean {
        return this.size > sizeInByte;
    }
}
