import { IBusinessEntity, ImageCreativeKeysToFilterBy, IImageUploadResponse } from 'api-contracts';
import { format } from 'date-fns';
import CreativeBulkUploadViewModel, {
    IBulkCreative,
} from '@/components/domain/creatives/shared/bulk/CreativeBulkUploadViewModel';
import { headers } from '@/components/domain/creatives/image/bulk/headers';
import { ITableHeaders } from '@/components/domain/creatives/shared/bulk/ITableHeaders';
import getFilenameWithoutExtension from '@/components/domain/creatives/shared/bulk/getFilenameWithoutExtension';
import { IFilenameTemplateOptions } from '@/components/domain/creatives/shared/bulk/formatCreativeName';

interface IImageBulkCreative extends IBulkCreative {
    clickUrl: string;
}

export default class ImageCreativeBulkUploadViewModel extends CreativeBulkUploadViewModel {
    public businessEntity: IBusinessEntity;

    public advertiserUrl: string;

    public clickUrl: string;

    public isDooh: boolean;

    public shouldDisableTemplateNaming: boolean;

    public uploadErrors = [];

    public readonly creatives: IImageBulkCreative[] = [];

    hasInitialProperties(): boolean {
        return Boolean(this.businessEntity && this.advertiserUrl && this.clickUrl);
    }

    getTemplateNamingOptions(): IFilenameTemplateOptions {
        return {
            template: '${date}_${advertiserName}_${filename}_${dimensions.width}x${dimensions.height}',
            getTemplateParametersFromResponseOrSpreadsheetRow: (response: IImageUploadResponse) => ({
                dimensions: response.dimensions,
                filename: getFilenameWithoutExtension(response.filename),
                advertiserName: this.businessEntity.name,
                date: format(new Date(), 'yyMMdd'),
            }),
            forceDefaultTemplate: this.shouldDisableTemplateNaming,
        };
    }

    getTableHeaders(): ITableHeaders<ImageCreativeKeysToFilterBy>[] {
        return headers();
    }
}
