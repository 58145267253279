import ConversionException from '@/utils/ConversionException';

export function convertMiBToMB(mib: number): number {
    throwIfInvalidValue(mib);

    return mib * 1.048_576;
}

export function convertMBToMiB(mb: number): number {
    throwIfInvalidValue(mb);

    return mb / 1.048_576;
}

export function convertMBToByte(mb: number): number {
    throwIfInvalidValue(mb);

    return mb * 1_000_000;
}

export function convertByteToMb(byte: number): number {
    throwIfInvalidValue(byte);

    return byte / 1_000_000;
}

export function convertByteToKb(byte: number): number {
    throwIfInvalidValue(byte);

    return byte / 1_000;
}

export function convertMbToKb(mb: number): number {
    throwIfInvalidValue(mb);

    return mb * 1_000;
}

function throwIfInvalidValue(value: number): void {
    if (value === undefined) {
        throw new ConversionException('undefined can not be converted');
    }

    if (value === null) {
        throw new ConversionException('null can not be converted');
    }

    if (isNaN(value)) {
        throw new ConversionException('NaN can not be converted');
    }
}
