import ExcelCellValue from '@/shared/excel/ExcelCellValue';

export interface INamedRow {
    name: ExcelCellValue;
}

export default class DuplicatedNameIndexFinder {
    find(unfilteredRows: INamedRow[]): number[] {
        const errorIndices = [];
        for (let outerIndex = 0; outerIndex < unfilteredRows.length; outerIndex++) {
            for (let innerIndex = outerIndex + 1; innerIndex < unfilteredRows.length; innerIndex++) {
                if (unfilteredRows[outerIndex].name === unfilteredRows[innerIndex].name) {
                    errorIndices.push(innerIndex);
                }
            }
        }
        return errorIndices;
    }
}
