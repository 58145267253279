<template>
    <div>
        <BulkCreativeTable
            v-model="creatives"
            :advertiser-name="advertiserName"
            :headers="headers"
            :is-loading="isLoading"
            :selected.sync="selected"
            :sort-by="['dimensions']"
            :sort-desc="[false]"
            v-bind="$attrs"
            @bulkEdit="bulkEdit"
            @edit="edit"
            v-on="$listeners"
        />
        <BulkEditVideoCreativeModal
            ref="bulkEdit"
            :is-open="showBulkEditModal"
            :selected="selected"
            :modal-error-message="modalErrorMessage"
            @close="closeBulkEditModal"
            @done="bulkEditModalChanged"
        />
        <EditVideoCreativeModal
            ref="edit"
            :advertise-name="advertiserName"
            :index="editIndex"
            :is-open="showEditModal"
            :item="editItem"
            :modal-error-message="modalErrorMessage"
            @close="closeEditModal"
            @done="editModalChanged"
        />
    </div>
</template>

<script>
import BulkCreativeTable from '@/components/domain/creatives/shared/bulk/BulkCreativeTable';
import BulkCreativeTableTemplate from '@/components/domain/creatives/shared/bulk/BulkCreativeTableTemplate';
import BulkEditVideoCreativeModal from '@/components/domain/creatives/video/bulk/BulkEditVideoCreativeModal';
import EditVideoCreativeModal from '@/components/domain/creatives/video/bulk/EditVideoCreativeModal';

export default {
    name: 'VideoBulkCreativeTable',
    components: {
        EditVideoCreativeModal,
        BulkCreativeTable,
        BulkEditVideoCreativeModal,
    },
    mixins: [BulkCreativeTableTemplate],
    methods: {
        bulkEditModalChanged(creative) {
            this.selected.forEach((item) => {
                let creativeName = item.name;
                if (!this.disableTemplateNaming) {
                    creativeName = this.formatCreativeName(item, item);
                }

                Object.assign(item, {
                    ...item,
                    ...creative,
                    originName: item.originName,
                    name: creativeName,
                });
            });
            this.selected = [];
            this.closeBulkEditModal();
        },
        editModalChanged(creative, index) {
            let creativeName = creative.originName;
            if (!this.disableTemplateNaming) {
                creativeName = this.formatCreativeName(creative, creative);
            }
            creative.name = creativeName;
            // preserve dropzone file reference
            creative.dropzoneFile = this.creatives[index].dropzoneFile;
            Object.assign(this.creatives[index], creative);
            this.closeEditModal();
            this.$emit('editItem');
        },
    },
};
</script>
