import ExcelTransformer from '@/shared/excel/ExcelTransformer';
import ITransformedDataOrErrors from '@/shared/excel/ITransformedDataOrErrors';
import IExcelFileReader from '@/shared/excel/IExcelFileReader';
import { INamedRow } from '@/shared/excel/DuplicatedNameIndexFinder';
import IValidatingTransformer from '@/shared/excel/IValidatingTransformer';
import ExcelJsExcelFileReader from '@/shared/excel/ExcelJsExcelFileReader';

export default class ExcelCreativeExtractor<TExcelRow extends INamedRow, TTarget> {
    public static create<TExcelRow extends INamedRow, TTarget>(
        transformer: IValidatingTransformer<TExcelRow, TTarget>,
        headers: (keyof TExcelRow)[],
    ): ExcelCreativeExtractor<TExcelRow, TTarget> {
        const excelTransformer = ExcelTransformer.create(transformer);
        return new ExcelCreativeExtractor(new ExcelJsExcelFileReader(), excelTransformer, headers);
    }

    private readonly excelFileReader: IExcelFileReader;

    private readonly transformer: ExcelTransformer<TExcelRow, TTarget>;

    private readonly headers: (keyof TExcelRow)[];

    constructor(
        excelFileReader: IExcelFileReader,
        excelTransformer: ExcelTransformer<TExcelRow, TTarget>,
        headers: (keyof TExcelRow)[],
    ) {
        this.excelFileReader = excelFileReader;
        this.transformer = excelTransformer;
        this.headers = headers;
    }

    async extract(file: ArrayBuffer): Promise<ITransformedDataOrErrors<TTarget>> {
        try {
            const rawRows = await this.excelFileReader.readRowsAsObjects<TExcelRow>(file, this.headers);
            return this.transformer.transform(rawRows);
        } catch (e) {
            const errorMessage = 'Could not extract creatives from given file.';
            console.error(errorMessage);
            console.error(e);
            return {
                errors: [errorMessage],
                rows: [],
            };
        }
    }
}
