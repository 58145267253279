import ITransformedDataOrErrors from '@/shared/excel/ITransformedDataOrErrors';
import IValidatingTransformer from '@/shared/excel/IValidatingTransformer';
import DuplicatedNameIndexFinder, { INamedRow } from '@/shared/excel/DuplicatedNameIndexFinder';

export default class ExcelTransformer<TRaw extends INamedRow, TTransformed> {
    public static create<TRaw extends INamedRow, TTransformed>(
        validatingTransformer: IValidatingTransformer<TRaw, TTransformed>,
    ): ExcelTransformer<TRaw, TTransformed> {
        return new ExcelTransformer(validatingTransformer, new DuplicatedNameIndexFinder());
    }

    private readonly transformer: IValidatingTransformer<TRaw, TTransformed>;

    private readonly duplicatedNameIndexFinder: DuplicatedNameIndexFinder;

    constructor(transformer: IValidatingTransformer<TRaw, TTransformed>, duplicatedNameIndexFinder: DuplicatedNameIndexFinder) {
        this.transformer = transformer;
        this.duplicatedNameIndexFinder = duplicatedNameIndexFinder;
    }

    transform(rawData: TRaw[]): ITransformedDataOrErrors<TTransformed> {
        if (rawData.length === 0) {
            return {
                rows: [],
                errors: ['No valid entries could be found.'],
            };
        }

        const rows: TTransformed[] = [];
        const errors: string[] = [];

        rawData.forEach((raw, index) => {
            const transformed = this.transformer.transform(raw);
            if (typeof transformed !== 'string') {
                rows.push(transformed);
            } else {
                errors.push(this.appendExcelRow(transformed, index));
            }
        });

        const duplicatedIndexes = this.duplicatedNameIndexFinder.find(rawData);
        if (duplicatedIndexes.length > 0) {
            duplicatedIndexes.forEach((index) => {
                errors.push(this.appendExcelRow('Duplicate entry found.', index));
            });
        }

        return {
            rows: errors.length > 0 ? [] : rows,
            errors,
        };
    }

    private appendExcelRow(message: string, index: number): string {
        const row = this.getExcelRowFromDataIndex(index);
        return `${message} (row ${row} in Excel)`;
    }

    private getExcelRowFromDataIndex(index: number): number {
        return index + 2;
    }
}
