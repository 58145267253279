<template>
    <div>
        <BulkCreativeTable
            v-model="creatives"
            :advertiser-name="advertiserName"
            :headers="headers"
            :is-loading="isLoading"
            :selected.sync="selected"
            :sort-by="['dimensions']"
            :sort-desc="[false]"
            v-bind="$attrs"
            @bulkEdit="bulkEdit"
            @edit="edit"
            v-on="$listeners"
        >
            <template #template="{ item }">
                <HtmlCodeDiff :replacements="replacements" :template="item.originTemplate" :value="generateCode(item)" />
            </template>
        </BulkCreativeTable>
        <BulkEditHtmlCreativeModal
            ref="bulkEdit"
            :is-open="showBulkEditModal"
            :selected="selected"
            :modal-error-message="modalErrorMessage"
            @close="closeBulkEditModal"
            @done="bulkEditModalChanged"
        />
        <EditHtmlCreativeModal
            ref="edit"
            :advertise-name="advertiserName"
            :index="editIndex"
            :is-open="showEditModal"
            :item="editItem"
            :modal-error-message="modalErrorMessage"
            @close="closeEditModal"
            @done="editModalChanged"
        />
    </div>
</template>

<script>
import BulkCreativeTable from '@/components/domain/creatives/shared/bulk/BulkCreativeTable';
import BulkCreativeTableTemplate from '@/components/domain/creatives/shared/bulk/BulkCreativeTableTemplate';
import HtmlCodeDiff from '@/components/domain/creatives/html/HtmlCodeDiff/HtmlCodeDiff';
import StringReplacementExecutor from '@/components/domain/creatives/html/string-replacement/StringReplacementExecutor';
import EditHtmlCreativeModal from '@/components/domain/creatives/html/bulk/EditHtmlCreativeModal';
import BulkEditHtmlCreativeModal from '@/components/domain/creatives/html/bulk/BulkEditHtmlCreativeModal';

export default {
    name: 'HtmlBulkCreativeTable',
    components: {
        HtmlCodeDiff,
        BulkCreativeTable,
        EditHtmlCreativeModal,
        BulkEditHtmlCreativeModal,
    },
    mixins: [BulkCreativeTableTemplate],
    props: {
        replacements: {
            type: Array,
            required: true,
        },
    },
    methods: {
        bulkEditModalChanged(creative) {
            this.selected.forEach((item) => {
                let creativeName = item.name;
                if (!this.disableTemplateNaming) {
                    creativeName = this.formatCreativeName(item, item);
                }

                Object.assign(item, {
                    ...item,
                    ...creative,
                    originName: item.originName,
                    name: creativeName,
                });
            });
            this.selected = [];
            this.closeBulkEditModal();
        },
        generateCode(item) {
            const replacementExecutor = new StringReplacementExecutor();
            return replacementExecutor.executeReplacements(item.originTemplate, this.replacements);
        },
    },
};
</script>
