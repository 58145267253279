import { IdObject, IRuntimeCreate } from 'api-contracts';
import bulkCreativeRules from '@/components/domain/creatives/shared/bulk/bulkCreativeRules';
import { ITableHeaders } from '@/components/domain/creatives/shared/bulk/ITableHeaders';

export interface IBulkCreative {
    originName: string;
    name: string;
    runtime: IRuntimeCreate;
    businessEntity: IdObject;
    uniqueId: string;
    advertiserUrl: string;
    file: unknown;
    open: boolean;
}

export default abstract class CreativeBulkUploadViewModel {
    public abstract creatives: IBulkCreative[];

    abstract hasInitialProperties(): boolean;

    abstract getTableHeaders(): ITableHeaders<unknown>[];

    hasDuplicatedCreativeNames(): boolean {
        const uniqueItems = new Set(this.creatives.map((creative) => creative.name));
        return uniqueItems.size !== this.creatives.length;
    }

    getInputRules(): { [key: string]: CallableFunction[] } {
        return bulkCreativeRules();
    }

    removeCreative(creative): void {
        const deletedIndex = this.creatives.findIndex((currentCreative) => currentCreative === creative);
        if (deletedIndex !== -1) {
            this.creatives.splice(deletedIndex, 1);
        }
    }

    removeAllCreatives() {
        this.creatives.splice(0, this.creatives.length);
    }

    hasCreatives(): boolean {
        return this.creatives.length > 0;
    }
}
