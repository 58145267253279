import { FileListItem, IFileListItem } from '@/components/domain/creatives/shared/bulk/bulk-creative-dropzone/FileListItem';
import { convertMBToByte, convertMbToKb } from '@/utils/conversions';

export default class DropzoneFileList {
    private readonly fileListItems: FileListItem[];

    private readonly erroneousFiles: FileListItem[];

    constructor(fileListArrayLike: ArrayLike<IFileListItem>) {
        this.fileListItems = Array.from(fileListArrayLike).map((fileListData) => new FileListItem(fileListData));
        this.erroneousFiles = this.extractErroneousFiles();
    }

    getAllFiles(): FileListItem[] {
        return this.fileListItems;
    }

    getErroneousFiles(): FileListItem[] {
        return this.erroneousFiles;
    }

    getTooLargeFiles(maxAllowedFileSizeInByte: number): FileListItem[] {
        return this.erroneousFiles.filter((file) => file.hasSizeAbove(maxAllowedFileSizeInByte));
    }

    generateErrorMessageForTooLargeFiles(maxAllowedFileSizeInMB: number): string {
        const maxAllowedFileSizeInByte = convertMBToByte(maxAllowedFileSizeInMB);
        const unit = this.generateUnit(maxAllowedFileSizeInMB);
        const fileSize = this.generateDisplayedSize(maxAllowedFileSizeInMB);

        return this.getTooLargeFiles(maxAllowedFileSizeInByte)
            .map((file) => `"${file.name}":\nExceeded file size limit of ${fileSize} ${unit}`)
            .join('\n');
    }

    private shouldShowMessageInKB(maxAllowedFileSizeInMB: number): boolean {
        return maxAllowedFileSizeInMB < 1;
    }

    private generateUnit(maxAllowedFileSizeInMB: number): string {
        return this.shouldShowMessageInKB(maxAllowedFileSizeInMB) ? 'KB' : 'MB';
    }

    private generateDisplayedSize(maxAllowedFileSizeInMB: number): number {
        return this.shouldShowMessageInKB(maxAllowedFileSizeInMB)
            ? convertMbToKb(maxAllowedFileSizeInMB)
            : maxAllowedFileSizeInMB;
    }

    private extractErroneousFiles(): FileListItem[] {
        return this.fileListItems.filter((file) => file.hasError());
    }
}
