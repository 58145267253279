import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import URLPathBuilder from '@/services/URLPathBuilder';
import { BusinessEntity } from './types';

export default class IamService {
    private readonly requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async listBusinessEntities(): Promise<[BusinessEntity]> {
        const options: IRequestOptions = {
            domain: 'iam',
            resource: 'business-entity',
            action: ACTION_NAME.READ,
        };
        const urlPathBuilder = new URLPathBuilder('/business-entities');
        return this.requestService.get(urlPathBuilder.build(), options);
    }
}

export const iamService = new IamService(new RequestService({ baseUrl: API_URL }));
