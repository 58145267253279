// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ACTION_TYPES {
    CREATE = 'create',
    EDIT = 'edit',
}

interface ISelectableObject {
    label: string;
    value: unknown;
}

export { ISelectableObject };

const emptyRuntime = {
    startDate: '',
    endDate: '',
    timezone: '',
};

export { emptyRuntime };

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum CREATIVE_SERVICE_DOMAIN {
    IMAGE = 'image-creatives',
    HTML = 'html-creatives',
    HTML5 = 'html5-creatives',
    AUDIO = 'audio-creatives',
    VIDEO = 'video-creatives',
    NATIVE = 'native-creatives',
}
