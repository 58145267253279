<template>
    <div>
        <DefaultTable
            :headers="headers"
            :is-loading="isLoading"
            :items="creatives"
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50],
            }"
            :selected.sync="selectedItems"
            :disabled-table-actions="disabledTableActions"
            item-key="uniqueId"
            show-select
            v-bind="$attrs"
        >
            <template #top-actions>
                <SrListItem :disabled="!selectedItems.length" dense @click="bulkEdit()">
                    <SrListItemIcon>
                        <SrIcon icon="Edit" size="xs" />
                    </SrListItemIcon>
                    <SrListItemTitle> Edit Selected Items</SrListItemTitle>
                </SrListItem>
                <SrListItem :disabled="!selectedItems.length" dense @click="bulkDelete()">
                    <SrListItemIcon>
                        <SrIcon icon="Trash" size="xs" />
                    </SrListItemIcon>
                    <SrListItemTitle> Delete Selected Items</SrListItemTitle>
                </SrListItem>
            </template>
            <template #name="item">
                <slot name="name" :item="item">
                    {{ item.name }}
                </slot>
            </template>
            <template #template="item">
                <slot name="template" :item="item"> template</slot>
            </template>
            <template #dimensions="item">
                <template v-if="item.dimensions && item.dimensions.width && item.dimensions.height">
                    {{ item.dimensions.width }}x{{ item.dimensions.height }}
                </template>
            </template>
            <template #[`runtime.startDate`]="item">
                {{ dateFormat(item, 'startDate') }}
            </template>
            <template #[`runtime.endDate`]="item">
                {{ dateFormat(item, 'endDate') }}
            </template>
            <template #actions="{ item }">
                <SrMenu v-model="item.open" left min-width="100" offset-y>
                    <template #activator="{ on, attrs }">
                        <SrButton icon v-bind="attrs" v-on="on">
                            <SrIcon :active="item.open" icon="more" size="xs" />
                        </SrButton>
                    </template>
                    <SrList dense>
                        <SrListItem @click="edit(item)">
                            <SrListItemIcon>
                                <SrIcon icon="Edit" size="xs" />
                            </SrListItemIcon>
                            <SrListItemTitle> Edit</SrListItemTitle>
                        </SrListItem>
                        <SrListItem @click="deleteItem(item)">
                            <SrListItemIcon>
                                <SrIcon icon="trash" size="xs" />
                            </SrListItemIcon>
                            <SrListItemTitle> Remove</SrListItemTitle>
                        </SrListItem>
                    </SrList>
                </SrMenu>
            </template>
        </DefaultTable>
    </div>
</template>

<script>
import { SrButton, SrIcon, SrMenu, SrList, SrListItem, SrListItemIcon, SrListItemTitle } from '@ads/design-system';
import DefaultTable from '@/components/shared/table/DefaultTable';
import formatDate from '@/components/domain/creatives/formatDate';

export default {
    name: 'BulkCreativeTable',
    components: {
        DefaultTable,
        SrIcon,
        SrButton,
        SrMenu,
        SrList,
        SrListItem,
        SrListItemIcon,
        SrListItemTitle,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        selected: {
            type: Array,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        advertiserName: {
            type: String,
            required: true,
        },
        template: {
            type: String,
        },
    },
    data() {
        return {
            creatives: this.value,
            selectedItems: this.selected,
        };
    },
    computed: {
        disabledTableActions() {
            return !this.selected.length;
        },
    },
    watch: {
        creatives: {
            deep: true,
            handler(value) {
                this.$emit('input', value);
            },
        },
        selected: {
            deep: true,
            handler(value) {
                this.selectedItems = value;
            },
        },
        selectedItems: {
            deep: true,
            handler(value) {
                this.$emit('update:selected', value);
            },
        },
    },
    methods: {
        dateFormat(creative, dateKey) {
            if (!creative.runtime) {
                return '';
            }
            return formatDate(creative.runtime[dateKey], creative.runtime.timezone);
        },
        edit(item) {
            item.open = false;
            this.$emit('edit', item);
        },
        bulkEdit() {
            if (!this.selected.length) {
                return;
            }
            this.$emit('bulkEdit');
        },
        deleteItem(item) {
            const selectedIndex = this.selected.findIndex((creative) => creative === item);
            if (selectedIndex !== -1) {
                this.selectedItems.splice(selectedIndex, 1);
            }

            this.$emit('deleteItem', item);
        },
        bulkDelete() {
            this.selectedItems.forEach((item) => {
                this.$emit('deleteItem', item);
            });
            this.selectedItems = [];
        },
    },
};
</script>
