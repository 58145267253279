<template>
    <CreativeOverview :headline-name="headlineName" :edit-route="editRoute" :headers="headers" :service-domain="serviceDomain">
        <div slot="header-actions">
            <SrButton v-if="Boolean(bulkCreateRoute)" class="mr-4" type="light-blue" @click="openBulkCreateForm">
                Bulk Create
            </SrButton>
            <SrButton type="secondary" @click="openCreateForm">
                {{ createButtonName }}
            </SrButton>
        </div>
    </CreativeOverview>
</template>

<script>
import { SrButton } from '@ads/design-system';
import { CREATIVE_SERVICE_DOMAIN } from '@/components/domain/creatives/types';
import CreativeOverview from '@/components/domain/creatives/shared/CreativeOverview';
import { headers } from '@/components/domain/creatives/headers';

export default {
    name: 'Html5CreativeOverview',
    components: {
        CreativeOverview,
        SrButton,
    },
    data() {
        return {
            serviceDomain: CREATIVE_SERVICE_DOMAIN.IMAGE,
            headlineName: 'Image Creatives',
            createButtonName: 'Create',
            createRoute: 'image-creative-create',
            bulkCreateRoute: 'image-creative-bulk-create',
            editRoute: 'image-creative-edit',
            advertisers: [],
            headers: headers(),
        };
    },
    methods: {
        openCreateForm() {
            this.$router.push({ name: this.createRoute });
        },
        openBulkCreateForm() {
            this.$router.push({ name: this.bulkCreateRoute });
        },
    },
};
</script>
