import DimensionsTypeFactory from '@/components/domain/creatives/shared/dimensions/DimensionsTypeFactory';

const dimensionsRules = {
    dimensions: [
        (v) => Boolean(v) || 'Size is required',
        (v) => DimensionsTypeFactory.isValidDimensions(v) || 'Size has wrong format e.g. 100x200',
    ],
};

export default dimensionsRules;
