<template>
    <EditCreativeModal v-bind="$attrs" v-on="$listeners">
        <template #form-content="{ creative, rules, description, runtime }">
            <VCardText>{{ description }}</VCardText>
            <VCardText>
                <SrInput
                    v-model="creative.originName"
                    :rules="rules.name"
                    class="creative-name"
                    label="Creative Name"
                    maxlength="128"
                    required
                />
                <SrURLInput
                    v-model="creative.advertiserUrl"
                    :rules="rules.url"
                    class="advertiser-url"
                    label="Advertiser URL"
                    maxlength="2048"
                    required
                    secure-url
                />
                <VideoSkippable v-model="creative.isSkippable" />
                <CreativeRuntime :value="runtime" @input="(runtime) => changeRuntime(creative, runtime)" />
            </VCardText>
        </template>
    </EditCreativeModal>
</template>

<script>
import { SrInput, SrURLInput } from '@ads/design-system';
import CreativeRuntime from '@/components/domain/creatives/shared/basics/CreativeRuntime';
import EditCreativeModal from '@/components/domain/creatives/shared/bulk/EditCreativeModal';
import VideoSkippable from '@/components/domain/creatives/video/VideoSkippable';

export default {
    name: 'EditVideoCreativeModal',
    components: {
        EditCreativeModal,
        CreativeRuntime,
        SrInput,
        SrURLInput,
        VideoSkippable,
    },
    methods: {
        changeRuntime(creative, runtime) {
            creative.runtime = runtime;
        },
    },
};
</script>
