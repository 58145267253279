import { Replacement } from '@/components/domain/creatives/html/string-replacement/Replacement';

export default class StringReplacementCounter {
    public count(value: string, replacements: Replacement[]): number {
        if (!value) {
            return 0;
        }

        const origins = this.getUniqueOriginsFrom(replacements);

        let count = 0;
        origins.forEach((origin) => {
            count += this.countOccurrencesOfOriginInValue(value, origin);
        });
        return count;
    }

    private getUniqueOriginsFrom(replacements: Replacement[]): string[] {
        const origins = replacements.map((replacement) => replacement.origin);
        const uniqueOrigins = new Set(origins);
        return [...uniqueOrigins];
    }

    private countOccurrencesOfOriginInValue(value: string, origin: string): number {
        const escapedOrigin = this.escapeSpecialCharacters(origin);
        const searchExpression = new RegExp(escapedOrigin, 'g');
        const occurrences = value.match(searchExpression);

        if (occurrences) {
            return occurrences.length;
        }

        return 0;
    }

    private escapeSpecialCharacters(value: string): string {
        return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
}
