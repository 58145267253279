export type BatchRequestProgress = {
    current: number;
    total: number;
};
export type ProgressSubscriber = (progress: BatchRequestProgress) => unknown;

export default class BatchRequest<T> {
    private readonly items: T[];

    private readonly batchAction: (batch: T[]) => Promise<T[]>;

    private readonly progressSubscribers: ProgressSubscriber[] = [];

    private batchRequests: Promise<T[]>[];

    constructor(items: T[], batchAction: (batch: T[]) => Promise<T[]>) {
        this.items = items;
        this.batchAction = batchAction;
    }

    async allDone(): Promise<T[]> {
        const resolvedBatches = await Promise.all(this.batchRequests);
        return resolvedBatches.flat();
    }

    onProgressUpdate(callback: ProgressSubscriber) {
        this.progressSubscribers.push(callback);
    }

    send(batchSize: number): this {
        const progress: BatchRequestProgress = {
            current: 0,
            total: Math.ceil(this.items.length / batchSize),
        };
        const remainingItems: T[] = [...this.items];
        let batchRequests: Promise<T[]>[] = [];

        do {
            const batchItems = remainingItems.splice(0, batchSize);
            const batchRequest = this.sendBatch(batchItems, batchRequests, progress);
            batchRequests = [...batchRequests, batchRequest];
        } while (remainingItems.length);

        this.batchRequests = batchRequests;
        return this;
    }

    private async sendBatch(
        batchItems: T[],
        previousBatchRequests: Promise<T[]>[],
        progress: BatchRequestProgress,
    ): Promise<T[]> {
        await Promise.all(previousBatchRequests);
        const response = await this.batchAction(batchItems);
        progress.current += 1;
        this.notifyProgressSubscribers(progress);

        return response;
    }

    private notifyProgressSubscribers(progress: BatchRequestProgress): void {
        this.progressSubscribers.forEach((subscriber) =>
            subscriber({
                current: progress.current,
                total: progress.total,
            }),
        );
    }
}
