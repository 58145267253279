<template>
    <SrSelect
        ref="macros"
        v-model="selectedMacro"
        :items="macros"
        class="macros"
        item-text="name"
        item-value="id"
        label="Macros"
        return-object
        searchable
        required
        @change="handleMacroSelected"
    />
</template>

<script>
import { SrSelect } from '@ads/design-system';
import AvailableMacros from '@/components/domain/creatives/html/AvailableMacros';
import { defaultReplacements } from '@/components/domain/creatives/html/defaultReplacements';

export default {
    name: 'SelectableMacros',
    components: {
        SrSelect,
    },
    data() {
        return {
            macros: AvailableMacros.getAll(),
            selectedMacro: AvailableMacros.getDefault(),
        };
    },
    created() {
        this.emitDefaultReplacements(defaultReplacements);
    },
    methods: {
        handleMacroSelected(newSelectedMacro) {
            this.emitDefaultAndSelectedReplacements(newSelectedMacro.replacements);
        },
        emitDefaultReplacements() {
            this.emitReplacements(defaultReplacements);
        },
        emitDefaultAndSelectedReplacements(selectedReplacements) {
            this.emitReplacements([...defaultReplacements, ...selectedReplacements]);
        },
        emitReplacements(replacements) {
            this.$emit('currentReplacements', replacements);
        },
    },
};
</script>
