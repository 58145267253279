<template>
    <SrURLInput
        :value="modelValue"
        :disabled="disabled"
        :required="!disabled"
        :secure-url="true"
        label="Custom Impression Tracker"
        @input="onInputChange"
    />
</template>

<script>
import { SrURLInput } from '@ads/design-system';

export default {
    name: 'CustomImpressionTracker',
    components: { SrURLInput },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue',
    },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onInputChange(value) {
            this.$emit('update:modelValue', value);
        },
    },
};
</script>
