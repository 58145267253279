import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import URLPathBuilder from '@/services/URLPathBuilder';

export default class TimezoneService {
    private readonly requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async listTimezones() {
        const options: IRequestOptions = {
            domain: 'creative-management',
            resource: 'creative',
            action: ACTION_NAME.READ,
        };
        const urlPathBuilder = new URLPathBuilder('/timezones');
        return this.requestService.get(urlPathBuilder.build(), options);
    }
}

export const timezoneService = new TimezoneService(new RequestService({ baseUrl: API_URL }));
