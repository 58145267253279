import VideoCreativeOverview from '@/components/domain/creatives/video/Overview.vue';
import VideoCreativeCreate from '@/components/domain/creatives/video/Create.vue';
import VideoCreativeEdit from '@/components/domain/creatives/video/Edit.vue';
import VideoBulkForm from '@/components/domain/creatives/video/bulk/BulkForm.vue';

import { ACTION_TYPES } from '@/components/domain/creatives/types';

export default [
    {
        name: 'video-creative-overview',
        path: '/video-creative',
        component: VideoCreativeOverview,
    },
    {
        name: 'video-creative-create',
        path: '/video-creative/create',
        component: VideoCreativeCreate,
    },
    {
        name: 'video-creative-bulk-create',
        path: '/video-creative/bulk-create',
        component: VideoBulkForm,
        props: () => ({
            afterRoute: 'video-creative-overview',
            submitAction: ACTION_TYPES.CREATE,
        }),
    },
    {
        name: 'video-creative-edit',
        path: '/video-creative/:id',
        component: VideoCreativeEdit,
        props: (route) => ({
            id: route.params.id,
        }),
    },
];
