import IValidatingTransformer from '@/shared/excel/IValidatingTransformer';
import { getTimezoneOffset } from 'date-fns-tz';
import IRawVideoExcelRow from '@/components/domain/creatives/video/bulk/IRawVideoExcelRow';
import IBulkVideoCreativeRow from '@/components/domain/creatives/video/bulk/IBulkVideoCreativeRow';
import ExcelCellValue from '@/shared/excel/ExcelCellValue';

export default class VideoCreativeImportTransformer implements IValidatingTransformer<IRawVideoExcelRow, IBulkVideoCreativeRow> {
    private static readonly UTC_ISO_DATE_REGEX = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d{3}(Z|\+00:00)/;

    transform({
        name,
        vastUrl,
        skippable,
        expirationStart,
        expirationEnd,
        expirationTimezone,
    }: IRawVideoExcelRow): IBulkVideoCreativeRow | string {
        if (!name) {
            return 'Required field name is missing.';
        }
        if (typeof name !== 'string') {
            return "Field 'name' needs to be a string instead of 42.";
        }
        if (!vastUrl) {
            return 'Required field vastUrl is missing.';
        }
        if (typeof vastUrl !== 'string') {
            return "Field 'vastUrl' needs to be a string instead of 42.";
        }
        if (skippable == null) {
            return 'Required field skippable is missing.';
        }
        if (typeof skippable !== 'boolean') {
            return "Field 'skippable' needs to be TRUE or FALSE.";
        }
        if (!this.isOptionalDateFormatValid(expirationStart)) {
            return "Expiration start has a wrong format. Example: '2000-01-22T13:33:33:000Z'.";
        }
        if (!this.isOptionalDateFormatValid(expirationEnd)) {
            return "Expiration end has a wrong format. Example: '2000-01-22T13:33:33:000Z'.";
        }
        if (!this.isOptionalTimezoneValid(expirationTimezone)) {
            return 'Expiration timezone is invalid.';
        }
        if (!this.areAllOrNoneExpirationFieldsSet(expirationStart, expirationEnd, expirationTimezone)) {
            return 'To set an expiration date range you need to specify expiration start, end and timezone.';
        }
        return {
            name,
            vastUrl,
            skippable,
            expirationStart,
            expirationEnd,
            expirationTimezone,
        };
    }

    private isOptionalDateFormatValid(datetime: ExcelCellValue): datetime is string {
        if (datetime == null) {
            return true;
        }
        if (typeof datetime !== 'string') {
            return false;
        }
        return VideoCreativeImportTransformer.UTC_ISO_DATE_REGEX.test(datetime);
    }

    private isOptionalTimezoneValid(timezone: ExcelCellValue): timezone is string {
        if (timezone == null) {
            return true;
        }
        if (typeof timezone !== 'string') {
            return false;
        }
        const timezoneOffset = getTimezoneOffset(timezone);
        return !isNaN(timezoneOffset);
    }

    private areAllOrNoneExpirationFieldsSet(start: string, end: string, timezone: string): boolean {
        const areExpirationFieldsSet = [start != null, end != null, timezone != null];
        return areExpirationFieldsSet.every((x) => x) || areExpirationFieldsSet.every((x) => !x);
    }
}
