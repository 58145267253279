<script>
import ComponentError from '@/components/domain/creatives/ComponentError';

export default {
    name: 'FormTemplate',
    props: {
        afterRoute: {
            type: String,
            required: true,
        },
        submitAction: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            form: {
                step: 1,
                disabled: false,
                rules: {
                    dynamic: {},
                },
            },
            mounted: false,
            componentError: new ComponentError(),
        };
    },
    computed: {
        isMounted() {
            return this.mounted;
        },
    },
    mounted() {
        this.mounted = true;
    },
    methods: {
        async close() {
            this.$emit('close');
            await this.$router.push({ name: this.afterRoute });
        },
        setComponentError(errorMessage) {
            this.componentError.setMessage(errorMessage);
        },
        setComponentRequestErrorMessage(error) {
            let { message } = error.response;
            if (message instanceof Object) {
                message = JSON.stringify(message);
            }
            this.setComponentError(message);
        },
        async request(action, data) {
            try {
                this.setComponentError(null);
                const result = await this.creativeService[action](data);
                return result;
            } catch (error) {
                await this.handleRequestError(error, data);
                throw error;
            }
        },
        async handleRequestError(error, data) {
            this.form.rules.dynamic = this.errorRuleFactory.create(error, data);
            if (!Object.keys(this.form.rules.dynamic).length) {
                this.setComponentRequestErrorMessage(error);
            }
            await this.$nextTick();
            this.validateForm();
        },
        validateForm() {
            this.components
                .reduce((accumulator, component, index) => {
                    const componentStep = index + 1;
                    if (!component?.$refs?.form || componentStep > this.form.step) {
                        return accumulator;
                    }
                    const isValid = component.$refs.form.validate();
                    if (!isValid) {
                        accumulator.push(componentStep);
                    }
                    return accumulator;
                }, [])
                .some((index) => {
                    this.form.step = index;
                    return true;
                });
        },
    },
};
</script>
