<template>
    <CreativeOverview headline-name="Native Creatives" :edit-route="editRoute" :headers="headers" :service-domain="serviceDomain">
        <div slot="header-actions">
            <SrButton type="secondary" @click="openCreateForm">Create</SrButton>
        </div>
    </CreativeOverview>
</template>

<script>
import { SrButton } from '@ads/design-system';
import { CREATIVE_SERVICE_DOMAIN } from '@/components/domain/creatives/types';
import CreativeOverview from '@/components/domain/creatives/shared/CreativeOverview';
import { nativeCreativeTableHeaders } from '@/components/domain/creatives/native/overview/nativeCreativeTableHeaders';

export default {
    name: 'NativeCreativeOverview',
    components: {
        CreativeOverview,
        SrButton,
    },
    data() {
        return {
            editRoute: 'native-creative-edit',
            createRoute: 'native-creative-create',
            serviceDomain: CREATIVE_SERVICE_DOMAIN.NATIVE,
            headers: nativeCreativeTableHeaders(),
        };
    },
    methods: {
        openCreateForm() {
            this.$router.push({ name: this.createRoute });
        },
    },
};
</script>
