<template>
    <ActionModal
        v-if="isOpen"
        :description="description"
        :error="error"
        :is-loading="isLoading"
        title="Edit Selected Items"
        @close="close"
    >
        <template #content>
            <VForm ref="form" v-model="formIsValid">
                <slot :creative="creative" :description="description" :rules="rules" :runtime="runtime" name="form-content" />
            </VForm>
        </template>
        <template #cancel>
            <SrButton :disabled="isLoading" class="close" color="blue" outlined x-large @click="close"> Cancel </SrButton>
        </template>
        <template #actions>
            <SrButton
                ref="confirm"
                :disabled="!formIsValid"
                :loading="isLoading"
                class="confirm"
                type="secondary"
                x-large
                @click="done"
            >
                Save
            </SrButton>
        </template>
    </ActionModal>
</template>

<script>
import Vue from 'vue';
import { SrButton } from '@ads/design-system';
import { emptyRuntime } from '@/components/domain/creatives/types';
import ActionModal from '@/components/domain/creatives/shared/ActionModal';
import editCreativeRules from '@/components/domain/creatives/shared/bulk/editCreativeRules';
import ModalError from '@/components/domain/creatives/shared/ModalError';

export default {
    name: 'BulkEditCreativeModal',
    components: {
        ActionModal,
        SrButton,
    },
    mixins: [ModalError],
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Array,
        },
    },
    data() {
        return {
            creative: {},
            isLoading: false,
            formIsValid: false,
            rules: editCreativeRules(),
        };
    },
    computed: {
        description() {
            return 'All edits made in the fields will be applied to all selected items.';
        },
        runtime: {
            get() {
                if (!this.creative.runtime) {
                    return JSON.parse(JSON.stringify(emptyRuntime));
                }

                return this.creative.runtime;
            },
            set(expiration) {
                Vue.set(this.creative, 'runtime', expiration);
            },
        },
    },
    watch: {
        selected: {
            deep: true,
            handler(selectedItems) {
                this.creative = this.getFirstCreative(selectedItems[0]);
            },
        },
    },
    created() {
        this.creative = this.getFirstCreative(this.selected[0]);
    },
    methods: {
        done() {
            this.$emit('done', this.creative);
        },
        close() {
            this.$emit('close');
        },
        getFirstCreative(selected) {
            if (!selected) {
                return {};
            }

            return {
                originName: selected.originName,
                advertiserUrl: selected.advertiserUrl,
                clickUrl: selected.clickUrl,
                isSkippable: selected.isSkippable,
                runtime: JSON.parse(JSON.stringify(selected.runtime)),
            };
        },
    },
};
</script>
