import { Html5CreativeKeysToFilterBy, IDimensions } from 'api-contracts';
import formatDate from '@/components/domain/creatives/formatDate';
import { ITableHeaders } from '@/components/domain/creatives/shared/bulk/ITableHeaders';

export function headers(): ITableHeaders<Html5CreativeKeysToFilterBy>[] {
    return [
        {
            text: 'Name',
            value: 'name',
            align: 'start',
            sortable: true,
        },
        {
            text: 'Size',
            value: 'dimensions',
            align: 'start',
            sortable: true,
            sort: (a: IDimensions, b: IDimensions) => {
                if (a.width < b.width) {
                    return -1;
                }
                if (a.width === b.width) {
                    if (a.height < b.height) {
                        return -1;
                    }
                    if (a.height === b.height) {
                        return 0;
                    }
                }
                return 1;
            },
        },
        {
            text: 'Start Date',
            value: 'runtime.startDate',
            align: 'start',
            sortable: true,
            valueConfig: {
                formatter: formatDate,
            },
        },
        {
            text: 'End Date',
            value: 'runtime.endDate',
            align: 'start',
            sortable: true,
            valueConfig: {
                formatter: formatDate,
            },
        },
        {
            text: 'Timezone',
            value: 'runtime.timezone',
            align: 'start',
            sortable: true,
        },
        {
            text: 'Actions',
            value: 'actions',
            align: 'end',
            sortable: false,
        },
    ];
}
