import { IDimensions } from 'api-contracts';
import DimensionsTypeFactory from '@/components/domain/creatives/shared/dimensions/DimensionsTypeFactory';

export default class SelectableDimensionsTransformer {
    transform(dimensionList: IDimensions[]): string[] {
        return dimensionList.map((dimensions) => DimensionsTypeFactory.toString(dimensions));
    }
}

export const selectableDimensionsTransformer = new SelectableDimensionsTransformer();
