// eslint-disable-next-line @typescript-eslint/naming-convention
import { Predicate, PredicateDefinition } from '@ads/predicate-model';

/** *
 * @deprecated Use FILTER_PREDICATE_NAMES instead
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum FilterPredicateNames {
    FILTER = 'filter',
    QUICK_SEARCH = 'quickSearch',
}

export enum FILTER_PREDICATE_NAMES {
    FILTER = 'filter',
    QUICK_SEARCH = 'quickSearch',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SearchValueTypes {
    TEXT = 'Text',
    SELECT = 'Select',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SelectSortOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum Operands {
    AND = 'AND',
    OR = 'OR',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum OperatorTypes {
    ALPHABETIC = 'Alphabetic',
    NUMERIC = 'Numeric',
    BOOLEAN = 'Boolean',
}

const OPERATOR_LABELS = {
    IS: 'Is',
    IS_NOT: 'Is not',
    LESS_THAN: 'Less than',
    LESS_THAN_OR_EQUAL: 'Less than or equal',
    GREATER_THAN: 'Greater than',
    GREATER_THAN_OR_EQUAL: 'Greater than or equal',
    STARTS_WITH: 'Starts with',
    ENDS_WITH: 'Ends with',
    CONTAINS: 'Contains',
};

export const OPERATORS = {
    [OperatorTypes.NUMERIC]: [
        { name: OPERATOR_LABELS.IS, identifier: 'IS' },
        { name: OPERATOR_LABELS.IS_NOT, identifier: 'IS_NOT' },
        { name: OPERATOR_LABELS.LESS_THAN, identifier: 'LESS_THAN' },
        { name: OPERATOR_LABELS.LESS_THAN_OR_EQUAL, identifier: 'LESS_THAN_OR_EQUAL' },
        { name: OPERATOR_LABELS.GREATER_THAN, identifier: 'GREATER_THAN' },
        { name: OPERATOR_LABELS.GREATER_THAN_OR_EQUAL, identifier: 'GREATER_THAN_OR_EQUAL' },
    ],
    [OperatorTypes.ALPHABETIC]: [
        { name: OPERATOR_LABELS.IS, identifier: 'IS' },
        { name: OPERATOR_LABELS.IS_NOT, identifier: 'IS_NOT' },
        { name: OPERATOR_LABELS.STARTS_WITH, identifier: 'STARTS_WITH' },
        { name: OPERATOR_LABELS.ENDS_WITH, identifier: 'ENDS_WITH' },
        { name: OPERATOR_LABELS.CONTAINS, identifier: 'CONTAINS' },
    ],
    [OperatorTypes.BOOLEAN]: [
        { name: OPERATOR_LABELS.IS, identifier: 'IS' },
        { name: OPERATOR_LABELS.IS_NOT, identifier: 'IS_NOT' },
    ],
};

type FilterConfigPropertyBase = {
    name: string;
    operatorType: OperatorTypes;
    filterCriteria: string;
    selectSortOrder?: SelectSortOrder;
    selectSearchable?: boolean;
    toStringConfig?: {
        onlyValues?: boolean;
        getParameterValueLabel?: (predicate: Predicate) => string;
    };
};

type FilterConfigPropertySelectSearch = FilterConfigPropertyBase & {
    searchValueType: SearchValueTypes.SELECT;
    selectValues: string[];
};

type FilterConfigPropertyTextSearch = FilterConfigPropertyBase & {
    searchValueType: SearchValueTypes.TEXT;
};

type FilterConfigProperty = FilterConfigPropertySelectSearch | FilterConfigPropertyTextSearch;

export type SrAdvancedFilterConfig = {
    properties: FilterConfigProperty[];
    operators: Record<OperatorTypes, { name: string; identifier: string }[]>;
    quickSearch?: {
        enabled: boolean;
        fields: string[];
        predicateDefinition?: PredicateDefinition;
        debounceMs?: number;
    };
    persistent?: boolean;
};
