export default {
    props: {
        modalErrorMessage: {
            type: String,
        },
    },
    data() {
        return {
            error: null,
        };
    },
    watch: {
        modalErrorMessage(value) {
            this.error = value;
        },
    },
};
