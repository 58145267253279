<template>
    <VastMimeTypeSelect
        :value="value"
        :mime-types="mimeTypes"
        :loading="loading"
        :error-message="errorMessage"
        @input="onValueChange"
    />
</template>

<script>
import VastMimeTypeSelect from '@/components/domain/vast/VastMimeTypeSelect';
import { mapGetters } from 'vuex';

export default {
    name: 'AudioVastMimeTypeSelect',
    components: {
        VastMimeTypeSelect,
    },
    props: {
        value: {
            type: Array,
            required: false,
        },
    },
    computed: {
        ...mapGetters('audioMimeTypeStore', {
            mimeTypes: 'getMimeTypes',
            loading: 'getIsLoadingMimeTypes',
            errorMessage: 'getErrorMessage',
        }),
    },
    async created() {
        await this.$store.dispatch('audioMimeTypeStore/fetchMimeTypes');
    },
    methods: {
        onValueChange(newValue) {
            this.$emit('input', newValue);
        },
    },
};
</script>
