import { VideoCreativeKeysToFilterBy } from 'api-contracts';
import formatDate from '@/components/domain/creatives/formatDate';
import { ITableHeaders } from '@/components/domain/creatives/shared/bulk/ITableHeaders';

export function headers(): ITableHeaders<VideoCreativeKeysToFilterBy>[] {
    return [
        {
            text: 'Name',
            value: 'name',
            align: 'start',
            sortable: true,
        },
        {
            text: 'Skippable',
            value: 'isSkippable',
            align: 'start',
            sortable: true,
        },
        {
            text: 'Start Date',
            value: 'runtime.startDate',
            align: 'start',
            sortable: true,
            valueConfig: {
                formatter: formatDate,
            },
        },
        {
            text: 'End Date',
            value: 'runtime.endDate',
            align: 'start',
            sortable: true,
            valueConfig: {
                formatter: formatDate,
            },
        },
        {
            text: 'Timezone',
            value: 'runtime.timezone',
            align: 'start',
            sortable: true,
        },
        {
            text: 'Actions',
            value: 'actions',
            align: 'end',
            sortable: false,
        },
    ];
}
