import imageCreativeRoutes from './imageCreativeRoutes';
import htmlCreativeRoutes from './htmlCreativeRoutes';
import html5CreativeRoutes from './html5CreativeRoutes';
import videoCreativeRoutes from './videoCreativeRoutes';
import audioCreativeRoutes from './audioCreativeRoutes';
import nativeCreativeRoutes from './nativeCreativeRoutes';

export default [
    {
        name: 'root',
        path: '/',
        redirect: '/image-creative',
    },
    ...imageCreativeRoutes,
    ...htmlCreativeRoutes,
    ...html5CreativeRoutes,
    ...audioCreativeRoutes,
    ...videoCreativeRoutes,
    ...nativeCreativeRoutes,
];
