import { IMimeType, IVastApiFramework, IVideoCreative } from 'api-contracts';
import VastVersion from 'api-contracts/vast/version/VastVersion';
import MediaAssetViewModel from '@/components/domain/creatives/shared/view-models/MediaAssetViewModel';
import { ValidationResult, ValidationRuleFn } from '@/shared/validation/types';

type VideoAssetsRules = {
    url: ValidationRuleFn[];
    duration: ValidationRuleFn[];
};

export default class VideoAssetsViewModel extends MediaAssetViewModel {
    private videoCreative: IVideoCreative;

    constructor(creative: IVideoCreative) {
        super();
        this.videoCreative = creative;
    }

    get vastApiFrameworks(): IVastApiFramework[] | { name: string }[] {
        return this.videoCreative.vastApiFrameworks;
    }

    set vastApiFrameworks(value) {
        this.videoCreative.vastApiFrameworks = value;
    }

    get mimeTypes(): IMimeType[] | { mimeType: string }[] {
        return this.videoCreative.vastMimeTypes;
    }

    set mimeTypes(value) {
        this.videoCreative.vastMimeTypes = value;
    }

    get vastVersion(): VastVersion {
        return this.videoCreative.vastVersion;
    }

    set vastVersion(value) {
        this.videoCreative.vastVersion = value;
    }

    get isVastUrlParsed(): boolean {
        return this.videoCreative.isVastUrlParsed;
    }

    set isVastUrlParsed(value) {
        this.videoCreative.isVastUrlParsed = value;
    }

    get vastUrl(): string {
        return this.videoCreative.vastUrl;
    }

    set vastUrl(value) {
        this.videoCreative.vastUrl = value;
    }

    get duration(): number {
        return this.videoCreative.vastVideoDuration;
    }

    set duration(value) {
        this.videoCreative.vastVideoDuration = Number(value);
    }

    get advertiserUrl(): string {
        return this.videoCreative.advertiserUrl;
    }

    set advertiserUrl(value) {
        this.videoCreative.advertiserUrl = value;
    }

    get rules(): VideoAssetsRules {
        return {
            url: [(v): ValidationResult => (v && !!v.trim()) || 'URL is required'],
            duration: [
                (v): ValidationResult => v != null || 'Video duration is required',
                (v): ValidationResult =>
                    (v != null && Number.isInteger(v)) || 'Video duration should be a whole number (seconds)',
                (v): ValidationResult => (v != null && v > 0) || 'Video duration should be greater than 0 (seconds)',
            ],
        };
    }

    reInitialize(value: IVideoCreative): void {
        this.videoCreative = value;
    }
}
