import { format } from 'date-fns';
import { IDimensions } from 'api-contracts';
import formatCreativeName from '@/components/domain/creatives/shared/bulk/formatCreativeName';

export default function formatBulkCreativeName(
    dimensions: IDimensions,
    filename: string,
    advertiserName: string,
    template?: string,
) {
    return formatCreativeName(
        {
            dimensions,
            filename,
            advertiserName,
            date: format(new Date(), 'yyMMdd'),
        },
        template || '${date}_${advertiserName}_${filename}_${dimensions.width}x${dimensions.height}',
    );
}
