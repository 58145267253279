/* eslint-disable @typescript-eslint/naming-convention */

export default abstract class MediaAssetViewModel {
    private _loadingFrameworksErrorMsg: string | null = null;

    handleApiFrameworksError(errorMessage: string): void {
        this._loadingFrameworksErrorMsg = errorMessage;
    }

    get loadingFrameworksErrorMsg(): string {
        return this._loadingFrameworksErrorMsg;
    }
}
