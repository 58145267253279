import { IVastApiFramework } from 'api-contracts';
import { mediaAssetService } from '@/components/shared/services/MediaAssetService';

type GlobalState = { vastApiFrameworks: IVastApiFramework[] };

const globalStore = {
    state: {
        vastApiFrameworks: [],
    },
    mutations: {
        setVastApiFrameworks(state: GlobalState, newValue: IVastApiFramework[]): void {
            state.vastApiFrameworks = newValue;
        },
    },
    actions: {
        async fetchVastApiFrameworks({ state, commit }): Promise<void> {
            if (state.vastApiFrameworks.length === 0) {
                const vastApiFrameworks = await mediaAssetService.listVastApiFrameworks();
                commit('setVastApiFrameworks', vastApiFrameworks);
            }
        },
    },
};

export { GlobalState };
export default globalStore;
