<template>
    <CreativeOverview headline-name="Video Creatives" :edit-route="editRoute" :headers="headers" :service-domain="serviceDomain">
        <div slot="header-actions">
            <SrButton v-if="Boolean(bulkCreateRoute)" class="mr-4" type="light-blue" @click="openBulkCreateForm">
                Bulk Create
            </SrButton>
            <SrButton type="secondary" @click="openCreateForm"> Create </SrButton>
        </div>
    </CreativeOverview>
</template>

<script>
import { SrButton } from '@ads/design-system';
import { CREATIVE_SERVICE_DOMAIN } from '@/components/domain/creatives/types';
import CreativeOverview from '@/components/domain/creatives/shared/CreativeOverview';
import { headers } from '@/components/domain/creatives/video/headers';

export default {
    name: 'VideoCreativeOverview',
    components: {
        CreativeOverview,
        SrButton,
    },
    data() {
        return {
            editRoute: 'video-creative-edit',
            createRoute: 'video-creative-create',
            bulkCreateRoute: 'video-creative-bulk-create',
            serviceDomain: CREATIVE_SERVICE_DOMAIN.VIDEO,
            headers: headers(),
        };
    },
    methods: {
        openCreateForm() {
            this.$router.push({ name: this.createRoute });
        },
        openBulkCreateForm() {
            this.$router.push({ name: this.bulkCreateRoute });
        },
    },
};
</script>
