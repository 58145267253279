import { AudioCreativeKeysToFilterBy } from 'api-contracts';
import formatDate from '@/components/domain/creatives/formatDate';
import { ITableHeaders } from '@/components/domain/creatives/shared/bulk/ITableHeaders';

export function audioCreativeTableHeaders(): ITableHeaders<AudioCreativeKeysToFilterBy>[] {
    return [
        {
            text: 'DSP ID',
            value: 'dspId',
            align: 'start',
            width: '100px',
            sortable: true,
        },
        {
            text: 'Name',
            value: 'name',
            align: 'start',
            sortable: true,
        },
        {
            text: 'Last Modified',
            value: 'updatedAt',
            align: 'start',
            valueConfig: {
                formatter: formatDate,
            },
            sortable: true,
        },
        {
            text: 'Advertiser',
            value: 'businessEntity.name',
            align: 'start',
            sortable: true,
        },
    ];
}
