import Vue from 'vue';

export default {
    name: 'FormBasics',
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        isLoading: {
            type: Boolean,
            required: true,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        form: {
            type: Object,
            required: false,
        },
        value: {
            type: [Object, Array],
            required: true,
        },
    },
    data() {
        return {
            loading: this.isLoading,
            formIsValid: this.isValid,
            formData: this.value,
        };
    },
    watch: {
        isLoading(value) {
            this.loading = value;
        },
        loading(value) {
            this.$emit('update:isLoading', value);
        },
        isValid(value) {
            this.formIsValid = value;
        },
        formIsValid(value) {
            this.$emit('update:isValid', value);
        },
        value(value) {
            Vue.set(this, 'formData', value);
        },
    },
};
