<template>
    <Form v-model="creative" :submit-action="actionType" :after-route="afterRoute" @close="close" />
</template>

<script>
import Form from '@/components/domain/creatives/image/Form';
import { ACTION_TYPES } from '@/components/domain/creatives/types';
import { emptyImageCreative } from '@/components/domain/creatives/image/types';

export default {
    name: 'ImageCreativeCreate',
    components: {
        Form,
    },
    data() {
        return {
            creative: { ...emptyImageCreative },
            afterRoute: 'image-creative-overview',
            actionType: ACTION_TYPES.CREATE,
        };
    },
    methods: {
        close() {
            this.creative = { ...emptyImageCreative };
        },
    },
};
</script>
