import CreativeService from '@/services/CreativeService';

export type LoadCreativeByIdMixinData = {
    errorMessage: string | null;
    isLoading: boolean;
    creative: unknown;
    creativeService: CreativeService;
};

const loadCreativeByIdMixin = {
    props: { id: { type: [Number, String], required: true, default: null } },
    data(): LoadCreativeByIdMixinData {
        return {
            errorMessage: null,
            isLoading: false,
            creative: null,
            creativeService: null,
        };
    },
    async created(): Promise<void> {
        await this.loadCreative();
    },
    methods: {
        async loadCreative(): Promise<void> {
            this.isLoading = true;
            try {
                this.creative = await this.creativeService.getById(Number(this.id));
            } catch (error) {
                this.errorMessage = error.response.message;
            }
            this.isLoading = false;
        },
    },
};

export default loadCreativeByIdMixin;
