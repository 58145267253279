<template>
    <div>
        <BulkCreativeTable
            v-model="creatives"
            :advertiser-name="advertiserName"
            :headers="headers"
            :is-loading="isLoading"
            :selected.sync="selected"
            :sort-by="['name']"
            :sort-desc="[false]"
            template="${date}_${advertiserName}_${filename}"
            v-bind="$attrs"
            @bulkEdit="bulkEdit"
            @edit="edit"
            v-on="$listeners"
        >
            <template #name="{ item }">
                <div :title="item.name" class="truncate-creative-name">
                    {{ item.name }}
                </div>
            </template>
        </BulkCreativeTable>
        <EditHtml5CreativeModal
            ref="edit"
            :advertise-name="advertiserName"
            :index="editIndex"
            :is-open="showEditModal"
            :item="editItem"
            :disable-template-naming="disableTemplateNaming"
            :modal-error-message="modalErrorMessage"
            @close="closeEditModal"
            @done="editModalChanged"
        />
        <BulkEditHtml5CreativeModal
            ref="bulkEdit"
            :is-open="showBulkEditModal"
            :selected="selected"
            :disable-template-naming="disableTemplateNaming"
            :modal-error-message="modalErrorMessage"
            @close="closeBulkEditModal"
            @done="bulkEditModalChanged"
        />
    </div>
</template>

<script>
import BulkCreativeTable from '@/components/domain/creatives/shared/bulk/BulkCreativeTable';
import EditHtml5CreativeModal from '@/components/domain/creatives/html5/bulk/EditHtml5CreativeModal';
import BulkEditHtml5CreativeModal from '@/components/domain/creatives/html5/bulk/BulkEditHtml5CreativeModal';
import BulkCreativeTableTemplate from '@/components/domain/creatives/shared/bulk/BulkCreativeTableTemplate';

export default {
    name: 'Html5BulkCreativeTable',
    components: {
        EditHtml5CreativeModal,
        BulkEditHtml5CreativeModal,
        BulkCreativeTable,
    },
    mixins: [BulkCreativeTableTemplate],
};
</script>
